import keyMirror from "keymirror";

const actions = {
  EXPENSE_TYPE_LIST: null,
  EXPENSE_TYPE_LIST_SUCCESS: null,
  EXPENSE_TYPE_LIST_ERROR: null,
  EXPENSE_TYPE_LIST_CLEAR: null,

  ADD_BOOKING_EXPENSES: null,
  ADD_BOOKING_EXPENSES_SUCCESS: null,
  ADD_BOOKING_EXPENSES_ERROR: null,
  ADD_BOOKING_EXPENSES_CLEAR: null,

  UPDATE_BOOKING_EXPENSES: null,
  UPDATE_BOOKING_EXPENSES_SUCCESS: null,
  UPDATE_BOOKING_EXPENSES_ERROR: null,
  UPDATE_BOOKING_EXPENSES_CLEAR: null,

  DELETE_BOOKING_EXPENSES: null,
  DELETE_BOOKING_EXPENSES_SUCCESS: null,
  DELETE_BOOKING_EXPENSES_ERROR: null,
  DELETE_BOOKING_EXPENSES_CLEAR: null,

  VERIFY_BOOKINGS: null,
  VERIFY_BOOKINGS_SUCCESS: null,
  VERIFY_BOOKINGS_ERROR: null,
  VERIFY_BOOKINGS_CLEAR: null,

  UNVERIFY_BOOKINGS: null,
  UNVERIFY_BOOKINGS_SUCCESS: null,
  UNVERIFY_BOOKINGS_ERROR: null,
  UNVERIFY_BOOKINGS_CLEAR: null,

  ADD_BOOKING_INFRINGEMENT: null,
  ADD_BOOKING_INFRINGEMENT_SUCCESS: null,
  ADD_BOOKING_INFRINGEMENT_ERROR: null,
  ADD_BOOKING_INFRINGEMENT_CLEAR: null,

  UPDATE_BOOKING_INFRINGEMENT: null,
  UPDATE_BOOKING_INFRINGEMENT_SUCCESS: null,
  UPDATE_BOOKING_INFRINGEMENT_ERROR: null,
  UPDATE_BOOKING_INFRINGEMENT_CLEAR: null,

  UPDATE_BOOKING_DETAILS: null,
  UPDATE_BOOKING_DETAILS_SUCCESS: null,
  UPDATE_BOOKING_DETAILS_ERROR: null,
  UPDATE_BOOKING_DETAILS_CLEAR: null,

  UPDATE_BOOKING_CLIENT_DETAILS: null,
  UPDATE_BOOKING_CLIENT_DETAILS_SUCCESS: null,
  UPDATE_BOOKING_CLIENT_DETAILS_ERROR: null,
  UPDATE_BOOKING_CLIENT_DETAILS_CLEAR: null,

  UPDATE_BOOKING_CLIENT_DETAILS: null,
  UPDATE_BOOKING_CLIENT_DETAILS_SUCCESS: null,
  UPDATE_BOOKING_CLIENT_DETAILS_ERROR: null,
  UPDATE_BOOKING_CLIENT_DETAILS_CLEAR: null,

  UPDATE_BASIC_BOOKING_DETAILS: null,
  UPDATE_BASIC_BOOKING_DETAILS_SUCCESS: null,
  UPDATE_BASIC_BOOKING_DETAILS_ERROR: null,
  UPDATE_BASIC_BOOKING_DETAILS_CLEAR: null,

  ADD_BOOKING_IMPORT: null,
  ADD_BOOKING_IMPORT_SUCCESS: null,
  ADD_BOOKING_IMPORT_ERROR: null,
  ADD_BOOKING_IMPORT_CLEAR: null,

  GET_WEEKLY_SINGLE_BOOKING: null,
  GET_WEEKLY_SINGLE_BOOKING_SUCCESS: null,
  GET_WEEKLY_SINGLE_BOOKING_ERROR: null,
  GET_WEEKLY_SINGLE_BOOKING_CLEAR: null,

  CHANGE_BOOKING_AWR: null,
  CHANGE_BOOKING_AWR_SUCCESS: null,
  CHANGE_BOOKING_AWR_ERROR: null,
  CHANGE_BOOKING_AWR_CLEAR: null,

  UPDATE_VERIFY_BOOKING_DETAILS: null,
  UPDATE_VERIFY_BOOKING_DETAILS_SUCCESS: null,
  UPDATE_VERIFY_BOOKING_DETAILS_ERROR: null,
  UPDATE_VERIFY_BOOKING_DETAILS_CLEAR: null,

  CHANGE_SITE: null,
  CHANGE_SITE_SUCCESS: null,
  CHANGE_SITE_ERROR: null,
  CHANGE_SITE_CLEAR: null
};

export default keyMirror(actions);
