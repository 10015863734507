import axios from "axios";
import URL from "../../config/envUrls";
import Auth from "../../config/auth";
import constants from "../../constants/constants";

const {
  API_TIME_OUT,
  HTTP_REQ_DEFAULT_HEADERS,
  HTTP_STATUS: { UNAUTHORISED, FORBIDDEN },
  PAGE_ROUTES: { LOGIN, NOT_AUTH }
} = constants;

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 5min. If the request takes longer than
// that then the request will be aborted.
const customAxios = axios.create({
  baseURL: URL.API.baseURL,
  timeout: API_TIME_OUT,
  headers: {
    sessionKey: Auth.getSessionKey(),
    post: {
      Accept: HTTP_REQ_DEFAULT_HEADERS.Accept,
      "Content-Type": HTTP_REQ_DEFAULT_HEADERS["Content-Type"]
    }
  }
});

// Step-2: Create request, response & error handlers
const requestHandler = request => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  request.headers.sessionKey = Auth.getSessionKey();
  return request;
};

const responseHandler = response => {
  const { status } = response;
  if (status === UNAUTHORISED || status === FORBIDDEN) {
    window.location.replace(LOGIN);
  }
  return response;
};

const errorHandler = (error = "") => {
  const { response = "" } = error || "";
  const { status } = response;
  if (
    status === UNAUTHORISED ||
    status === FORBIDDEN ||
    response?.data?.ResponseCode === "SessionExpired" ||
    response?.data?.ErrorMessage?.ResponseCode === "SessionExpired"
  ) {
    window.location.replace(NOT_AUTH);
  }
  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(error)
);

customAxios.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
