import keyMirror from "keymirror";

const actions = {
  DAILY_BOOKING_LIST: null,
  DAILY_BOOKING_LIST_SUCCESS: null,
  DAILY_BOOKING_LIST_ERROR: null,
  DAILY_BOOKING_LIST_CLEAR: null,

  MONTHLY_BOOKING_LIST: null,
  MONTHLY_BOOKING_LIST_SUCCESS: null,
  MONTHLY_BOOKING_LIST_ERROR: null,
  MONTHLY_BOOKING_LIST_CLEAR: null,

  WEEKLY_BOOKING_LIST: null,
  WEEKLY_BOOKING_LIST_SUCCESS: null,
  WEEKLY_BOOKING_LIST_ERROR: null,
  WEEKLY_BOOKING_LIST_CLEAR: null,

  MONTHLY_BOOKING_LIST_OVERVIEW: null,
  MONTHLY_BOOKING_LIST_OVERVIEW_SUCCESS: null,
  MONTHLY_BOOKING_LIST_OVERVIEW_ERROR: null,
  MONTHLY_BOOKING_LIST_OVERVIEW_CLEAR: null,

  NIGHT_OUT_UPDATE: null,
  NIGHT_OUT_UPDATE_SUCCESS: null,
  NIGHT_OUT_UPDATE_ERROR: null,
  NIGHT_OUT_UPDATE_CLEAR: null,

  HOLD_FROM_APP_SINGLE: null,
  HOLD_FROM_APP_SINGLE_SUCCESS: null,
  HOLD_FROM_APP_SINGLE_ERROR: null,
  HOLD_FROM_APP_SINGLE_CLEAR: null,

  HOLD_FROM_APP_MULTIPLE: null,
  HOLD_FROM_APP_MULTIPLE_SUCCESS: null,
  HOLD_FROM_APP_MULTIPLE_ERROR: null,
  HOLD_FROM_APP_MULTIPLE_CLEAR: null
};

export default keyMirror(actions);
