import { call } from "redux-saga/effects";
import { httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes";

export function* getReportList() {
  const {
    listReports: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.LIST_REPORTS_SUCCESS,
    errorAction: actions.LIST_REPORTS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getReportInfo({ payload }) {
  const {
    reportInfo: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${payload}`,
    apiMethod: method,
    successAction: actions.GET_REPORTS_INFO_SUCCESS,
    errorAction: actions.GET_REPORTS_INFO_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
