import { combineReducers } from "redux";

import actions from "../actionTypes";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const getUserList = createReducer(defaultState, {
  [actions.USER_LIST]: isLoadingStateReducer,
  [actions.USER_LIST_SUCCESS]: successStateReducer,
  [actions.USER_LIST_ERROR]: errorStateReducer,
  [actions.USER_LIST_CLEAR]: resetStateReducer
});

const getObjectPermissions = createReducer(defaultState, {
  [actions.GET_USER_SITES]: isLoadingStateReducer,
  [actions.GET_USER_SITES_SUCCESS]: successStateReducer,
  [actions.GET_USER_SITES_ERROR]: errorStateReducer,
  [actions.GET_USER_SITES_CLEAR]: resetStateReducer
});

const addNewUser = createReducer(defaultState, {
  [actions.ADD_NEW_USER]: isLoadingStateReducer,
  [actions.ADD_NEW_USER_SUCCESS]: successStateReducer,
  [actions.ADD_NEW_USER_ERROR]: errorStateReducer,
  [actions.ADD_NEW_USER_CLEAR]: resetStateReducer
});

const deleteUser = createReducer(defaultState, {
  [actions.DELETE_USER]: isLoadingStateReducer,
  [actions.DELETE_USER_SUCCESS]: successStateReducer,
  [actions.DELETE_USER_ERROR]: errorStateReducer,
  [actions.DELETE_USER_CLEAR]: resetStateReducer
});
const changeUserPassword = createReducer(defaultState, {
  [actions.CHANGE_PASSWORD]: isLoadingStateReducer,
  [actions.CHANGE_PASSWORD_SUCCESS]: successStateReducer,
  [actions.CHANGE_PASSWORD_ERROR]: errorStateReducer,
  [actions.CHANGE_PASSWORD_CLEAR]: resetStateReducer
});

const editUser = createReducer(defaultState, {
  [actions.EDIT_USER]: isLoadingStateReducer,
  [actions.EDIT_USER_SUCCESS]: successStateReducer,
  [actions.EDIT_USER_ERROR]: errorStateReducer,
  [actions.EDIT_USER_CLEAR]: resetStateReducer
});

export default combineReducers({
  getUserList,
  changeUserPassword,
  deleteUser,
  addNewUser,
  editUser,
  getObjectPermissions
});
