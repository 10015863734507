import React from "react";
import { withRouter } from "react-router-dom";
import ErrorHandler from "./errorHandler";
import labels from "../../constants/labels";

const {
  ERROR_PAGE: { NO_AUTH }
} = labels;

const NoAuth = () => {
  return <ErrorHandler errorCase={NO_AUTH} />;
};

export default React.memo(withRouter(NoAuth));
