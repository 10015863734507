import { combineReducers } from "redux";
import actions from "../actionTypes";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const pendingDocumentsList = createReducer(defaultState, {
  [actions.PENDING_DOCUMENT_LIST]: isLoadingStateReducer,
  [actions.PENDING_DOCUMENT_LIST_SUCCESS]: successStateReducer,
  [actions.PENDING_DOCUMENT_LIST_ERROR]: errorStateReducer,
  [actions.PENDING_DOCUMENT_LIST_CLEAR]: resetStateReducer
});

const payDocumentList = createReducer(defaultState, {
  [actions.PAY_DOCUMENT_LIST]: isLoadingStateReducer,
  [actions.PAY_DOCUMENT_LIST_SUCCESS]: successStateReducer,
  [actions.PAY_DOCUMENT_LIST_ERROR]: errorStateReducer,
  [actions.PAY_DOCUMENT_LIST_CLEAR]: resetStateReducer
});
const holdDocument = createReducer(defaultState, {
  [actions.HOLD_DOCUMENT]: isLoadingStateReducer,
  [actions.HOLD_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.HOLD_DOCUMENT_ERROR]: errorStateReducer,
  [actions.HOLD_DOCUMENT_CLEAR]: resetStateReducer
});
const unHoldDocument = createReducer(defaultState, {
  [actions.UNHOLD_DOCUMENT]: isLoadingStateReducer,
  [actions.UNHOLD_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.UNHOLD_DOCUMENT_ERROR]: errorStateReducer,
  [actions.UNHOLD_DOCUMENT_CLEAR]: resetStateReducer
});

const deleteDocument = createReducer(defaultState, {
  [actions.DELETE_PENDING_DOCUMENT]: isLoadingStateReducer,
  [actions.DELETE_PENDING_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.DELETE_PENDING_DOCUMENT_ERROR]: errorStateReducer,
  [actions.DELETE_PENDING_DOCUMENT_CLEAR]: resetStateReducer
});

const modifyPayDocument = createReducer(defaultState, {
  [actions.MODIFY_PAY_DOCUMENT]: isLoadingStateReducer,
  [actions.MODIFY_PAY_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.MODIFY_PAY_DOCUMENT_ERROR]: errorStateReducer,
  [actions.MODIFY_PAY_DOCUMENT_CLEAR]: resetStateReducer
});

const sendToXero = createReducer(defaultState, {
  [actions.SEND_TO_XERO]: isLoadingStateReducer,
  [actions.SEND_TO_XERO_SUCCESS]: successStateReducer,
  [actions.SEND_TO_XERO_ERROR]: errorStateReducer,
  [actions.SEND_TO_XERO_CLEAR]: resetStateReducer
});

const viewDocument = createReducer(defaultState, {
  [actions.VIEW_DOCUMENT]: isLoadingStateReducer,
  [actions.VIEW_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.VIEW_DOCUMENT_ERROR]: errorStateReducer,
  [actions.VIEW_DOCUMENT_CLEAR]: resetStateReducer
});

const payRates = createReducer(defaultState, {
  [actions.PAY_RATE_LISTING]: isLoadingStateReducer,
  [actions.PAY_RATE_SUCCESS]: successStateReducer,
  [actions.PAY_RATE_LISTING_ERROR]: errorStateReducer,
  [actions.PAY_RATE_CLEAR]: resetStateReducer
});

const getHolidayListing = createReducer(defaultState, {
  [actions.HOLIDAY_LISTING]: isLoadingStateReducer,
  [actions.HOLIDAY_LISTING_SUCCESS]: successStateReducer,
  [actions.HOLIDAY_LISTING_ERROR]: errorStateReducer,
  [actions.HOLIDAY_LISTING_CLEAR]: resetStateReducer
});

const deleteMultipleHolidays = createReducer(defaultState, {
  [actions.DELETE_MULTIPLE_HOLIDAYS]: isLoadingStateReducer,
  [actions.DELETE_MULTIPLE_HOLIDAYS_SUCCESS]: successStateReducer,
  [actions.DELETE_MULTIPLE_HOLIDAYS_ERROR]: errorStateReducer,
  [actions.DELETE_MULTIPLE_HOLIDAYS_CLEAR]: resetStateReducer
});

const addAdjustment = createReducer(defaultState, {
  [actions.ADD_ADJUSTMENT]: isLoadingStateReducer,
  [actions.ADD_ADJUSTMENT_SUCCESS]: successStateReducer,
  [actions.ADD_ADJUSTMENT_ERROR]: errorStateReducer,
  [actions.ADD_ADJUSTMENT_CLEAR]: resetStateReducer
});

const updatePayDocument = createReducer(defaultState, {
  [actions.UPDATE_PAY_DOCUMENT]: isLoadingStateReducer,
  [actions.UPDATE_PAY_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.UPDATE_PAY_DOCUMENT_ERROR]: errorStateReducer,
  [actions.UPDATE_PAY_DOCUMENT_CLEAR]: resetStateReducer
});

const updateAdjustment = createReducer(defaultState, {
  [actions.UPDATE_ADJUSTMENT]: isLoadingStateReducer,
  [actions.UPDATE_ADJUSTMENT_SUCCESS]: successStateReducer,
  [actions.UPDATE_ADJUSTMENT_ERROR]: errorStateReducer,
  [actions.UPDATE_ADJUSTMENT_CLEAR]: resetStateReducer
});

const deleteAdjustment = createReducer(defaultState, {
  [actions.DELETE_ADJUSTMENT]: isLoadingStateReducer,
  [actions.DELETE_ADJUSTMENT_SUCCESS]: successStateReducer,
  [actions.DELETE_ADJUSTMENT_ERROR]: errorStateReducer,
  [actions.DELETE_ADJUSTMENT_CLEAR]: resetStateReducer
});

const createPayDocument = createReducer(defaultState, {
  [actions.CREATE_PAY_DOCUMENT]: isLoadingStateReducer,
  [actions.CREATE_PAY_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.CREATE_PAY_DOCUMENT_ERROR]: errorStateReducer,
  [actions.CREATE_PAY_DOCUMENT_CLEAR]: resetStateReducer
});
const deletePayRate = createReducer(defaultState, {
  [actions.DELETE_PAY_RATE]: isLoadingStateReducer,
  [actions.DELETE_PAY_RATE_SUCCESS]: successStateReducer,
  [actions.DELETE_PAY_RATE_ERROR]: errorStateReducer,
  [actions.DELETE_PAY_RATE_CLEAR]: resetStateReducer
});

const buildPayDocumentsInfo = createReducer(defaultState, {
  [actions.BUILD_PAY_DOCUMENTS]: isLoadingStateReducer,
  [actions.BUILD_PAY_DOCUMENTS_SUCCESS]: successStateReducer,
  [actions.BUILD_PAY_DOCUMENTS_ERROR]: errorStateReducer,
  [actions.BUILD_PAY_DOCUMENTS_CLEAR]: resetStateReducer
});

const addPayRateRules = createReducer(defaultState, {
  [actions.ADD_PAY_RATE_RULES]: isLoadingStateReducer,
  [actions.ADD_PAY_RATE_RULES_SUCCESS]: successStateReducer,
  [actions.ADD_PAY_RATE_RULES_ERROR]: errorStateReducer,
  [actions.ADD_PAY_RATE_RULES_CLEAR]: resetStateReducer
});

const addPayRateCard = createReducer(defaultState, {
  [actions.ADD_RATE_CARD]: isLoadingStateReducer,
  [actions.ADD_RATE_CARD_SUCCESS]: successStateReducer,
  [actions.ADD_RATE_CARD_ERROR]: errorStateReducer,
  [actions.ADD_RATE_CARD_CLEAR]: resetStateReducer
});

const updatePayRateCard = createReducer(defaultState, {
  [actions.UPDATE_RATE_CARD]: isLoadingStateReducer,
  [actions.UPDATE_RATE_CARD_SUCCESS]: successStateReducer,
  [actions.UPDATE_RATE_CARD_ERROR]: errorStateReducer,
  [actions.UPDATE_RATE_CARD_CLEAR]: resetStateReducer
});

const deletePayRateCard = createReducer(defaultState, {
  [actions.DELETE_RATE_CARD]: isLoadingStateReducer,
  [actions.DELETE_RATE_CARD_SUCCESS]: successStateReducer,
  [actions.DELETE_RATE_CARD_ERROR]: errorStateReducer,
  [actions.DELETE_RATE_CARD_CLEAR]: resetStateReducer
});

const updatePayRateRules = createReducer(defaultState, {
  [actions.UPDATE_PAY_RATE_RULES]: isLoadingStateReducer,
  [actions.UPDATE_PAY_RATE_RULES_SUCCESS]: successStateReducer,
  [actions.UPDATE_PAY_RATE_RULES_ERROR]: errorStateReducer,
  [actions.UPDATE_PAY_RATE_RULES_CLEAR]: resetStateReducer
});
const viewPayRate = createReducer(defaultState, {
  [actions.VIEW_PAY_RATE]: isLoadingStateReducer,
  [actions.VIEW_PAY_RATE_SUCCESS]: successStateReducer,
  [actions.VIEW_PAY_RATE_ERROR]: errorStateReducer,
  [actions.VIEW_PAY_RATE_CLEAR]: resetStateReducer
});
const getBuildProgressInfo = createReducer(defaultState, {
  [actions.BUILD_PAY_DOCUMENTS_PROGRESS]: isLoadingStateReducer,
  [actions.BUILD_PAY_DOCUMENTS_PROGRESS_SUCCESS]: successStateReducer,
  [actions.BUILD_PAY_DOCUMENTS_PROGRESS_ERROR]: errorStateReducer,
  [actions.BUILD_PAY_DOCUMENTS_PROGRESS_CLEAR]: resetStateReducer
});

const viewPayRateHistoryInfo = createReducer(defaultState, {
  [actions.VIEW_PAY_RATE_HISTORY]: isLoadingStateReducer,
  [actions.VIEW_PAY_RATE_HISTORY_SUCCESS]: successStateReducer,
  [actions.VIEW_PAY_RATE_HISTORY_ERROR]: errorStateReducer,
  [actions.VIEW_PAY_RATE_HISTORY_CLEAR]: resetStateReducer
});

const copyPayRate = createReducer(defaultState, {
  [actions.COPY_PAY_RATE]: isLoadingStateReducer,
  [actions.COPY_PAY_RATE_SUCCESS]: successStateReducer,
  [actions.COPY_PAY_RATE_ERROR]: errorStateReducer,
  [actions.COPY_PAY_RATE_CLEAR]: resetStateReducer
});

const getUserClientList = createReducer(defaultState, {
  [actions.GET_USER_CLIENT_LIST]: isLoadingStateReducer,
  [actions.GET_USER_CLIENT_LIST_SUCCESS]: successStateReducer,
  [actions.GET_USER_CLIENT_LIST_ERROR]: errorStateReducer,
  [actions.GET_USER_CLIENT_LIST_CLEAR]: resetStateReducer
});

const getBookingDetails = createReducer(defaultState, {
  [actions.GET_VERIFY_BOOKING_LIST]: isLoadingStateReducer,
  [actions.GET_VERIFY_BOOKING_LIST_SUCCESS]: successStateReducer,
  [actions.GET_VERIFY_BOOKING_LIST_ERROR]: errorStateReducer,
  [actions.GET_VERIFY_BOOKING_LIST_CLEAR]: resetStateReducer
});

const printPayDocument = createReducer(defaultState, {
  [actions.PRINT_PAY_DOCUMENT]: isLoadingStateReducer,
  [actions.PRINT_PAY_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.PRINT_PAY_DOCUMENT_ERROR]: errorStateReducer,
  [actions.PRINT_PAY_DOCUMENT_CLEAR]: resetStateReducer
});

const mergeDocument = createReducer(defaultState, {
  [actions.MERGE_DOCUMENT]: isLoadingStateReducer,
  [actions.MERGE_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.MERGE_DOCUMENT_ERROR]: errorStateReducer,
  [actions.MERGE_DOCUMENT_CLEAR]: resetStateReducer
});

export default combineReducers({
  printPayDocument,
  sendToXero,
  deleteMultipleHolidays,
  pendingDocumentsList,
  getHolidayListing,
  payDocumentList,
  unHoldDocument,
  holdDocument,
  deleteDocument,
  modifyPayDocument,
  viewDocument,
  payRates,
  addAdjustment,
  updateAdjustment,
  deleteAdjustment,
  createPayDocument,
  deletePayRate,
  buildPayDocumentsInfo,
  addPayRateRules,
  addPayRateCard,
  updatePayRateRules,
  updatePayRateCard,
  viewPayRate,
  getBuildProgressInfo,
  viewPayRateHistoryInfo,
  copyPayRate,
  getUserClientList,
  getBookingDetails,
  deletePayRateCard,
  mergeDocument,
  updatePayDocument
});
