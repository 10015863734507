/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-function */

import { put, call } from "redux-saga/effects";
import { httpRequestForData, axiosApiRequestForMultiPartData } from "../api/api";
// whenever u need to send multipart axios request || just pass "axiosApiRequestForMultiPartData" in const requestParameters = {axiosRequest:axiosApiRequestForMultiPartData}
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes/booking";

export function* getBookingExpenseTypeList() {
  const {
    getBookingExpenseTypeList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.EXPENSE_TYPE_LIST_SUCCESS,
    errorAction: actions.EXPENSE_TYPE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addBookingExpenses({ data }) {
  const {
    addBookingExpenses: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?requestObject=${JSON.stringify(data.params)}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.ADD_BOOKING_EXPENSES_SUCCESS,
    errorAction: actions.ADD_BOOKING_EXPENSES_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* updateBookingExpenses({ data }) {
  const {
    updateBookingExpenses: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?requestObject=${JSON.stringify(data.params)}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_BOOKING_EXPENSES_SUCCESS,
    errorAction: actions.UPDATE_BOOKING_EXPENSES_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* deleteBookingExpenses({ data }) {
  const {
    deleteBookingExpenses: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_BOOKING_EXPENSES_SUCCESS,
    errorAction: actions.DELETE_BOOKING_EXPENSES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* verifiedBookings({ data }) {
  const {
    verifiedBookings: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.VERIFY_BOOKINGS_SUCCESS,
    errorAction: actions.VERIFY_BOOKINGS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* unverifiedBookings({ data }) {
  const {
    unverifiedBookings: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UNVERIFY_BOOKINGS_SUCCESS,
    errorAction: actions.UNVERIFY_BOOKINGS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addBookingInfringements({ data }) {
  const {
    addBookingInfringements: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.ADD_BOOKING_INFRINGEMENT_SUCCESS,
    errorAction: actions.ADD_BOOKING_INFRINGEMENT_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* updateBookingInfringements({ data }) {
  const {
    updateBookingInfringements: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_BOOKING_INFRINGEMENT_SUCCESS,
    errorAction: actions.UPDATE_BOOKING_INFRINGEMENT_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* updateBookingDetails({ data }) {
  const {
    updateBookingDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_BOOKING_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_BOOKING_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateBookingClientDetails({ data }) {
  const {
    updateBookingClientDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_BOOKING_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_BOOKING_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateBasicBookingDetails({ data }) {
  const {
    updateBasicBookingDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_BASIC_BOOKING_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_BASIC_BOOKING_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* changeBookingAWRScope({ data }) {
  const {
    changeBookingAWRScope: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.bookingObjectId}&awrScope=${data.awrScope}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CHANGE_BOOKING_AWR_SUCCESS,
    errorAction: actions.CHANGE_BOOKING_AWR_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateVerifyBookingDetails({ data }) {
  const {
    updateVerifyBookingDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data?.payload,
    successAction: actions.UPDATE_VERIFY_BOOKING_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_VERIFY_BOOKING_DETAILS_ERROR,
    successMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* changeSiteDetails({ data }) {
  const {
    changeSite: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CHANGE_SITE_SUCCESS,
    errorAction: actions.CHANGE_SITE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addBookingImport({ data }) {
  const {
    addBookingImport: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.ADD_BOOKING_IMPORT_SUCCESS,
    errorAction: actions.ADD_BOOKING_IMPORT_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getWeeklySingleBooking({ data }) {
  const {
    getWeeklySingleBooking: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_WEEKLY_SINGLE_BOOKING_SUCCESS,
    errorAction: actions.GET_WEEKLY_SINGLE_BOOKING_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
