import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes";
import {
  getDaysOfWeeks,
  addSiteGeneralDetails,
  getDefaultShiftType,
  addSiteContactDetails,
  getSiteDocumentType,
  addSiteDocument,
  getSupplierList,
  saveAwrDetails,
  requirementList,
  saveBookingRule,
  defaultAWRRule,
  updateSiteContact,
  getSiteDetails,
  updateGeneralDetails,
  addClientShiftTypeData,
  updateClientShiftTypeData,
  deleteClientShiftTypeData,
  getSiteContactDetails,
  deleteAWRDetails,
  copyAWRDetails,
  getBookingRule,
  saveResourceRequirements,
  getResourceRequirements,
  updateResourceRequirements,
  deleteResourceRequirements,
  getSitePipelineCandidates,
  getSiteCandidatePerformance,
  getSiteInfringement,
  getSiteDocument,
  deleteSiteDocument,
  updateSiteInfringementData,
  getClientCandidateHistory,
  clientCandidateEmail
} from "../workers/siteWorker";

export function* watchDaysOfWeeks() {
  yield takeLatest(actions.SITE_DAYS_OF_WEEK, getDaysOfWeeks);
}

export function* watchAddSiteDetails() {
  yield takeLatest(actions.ADD_SITE_GENERAL_DETAILS, addSiteGeneralDetails);
}

export function* watchSiteShiftDetails() {
  yield takeLatest(actions.SITE_DEFAULT_SHIFT_TYPE, getDefaultShiftType);
}

export function* watchAddContactDetails() {
  yield takeLatest(actions.ADD_SITE_CONTACT_FORM, addSiteContactDetails);
}

export function* watchSiteDocumentType() {
  yield takeLatest(actions.SITE_DOCUMENT, getSiteDocumentType);
}

export function* watchAddSiteDocument() {
  yield takeLatest(actions.ADD_SITE_DOCUMENT, addSiteDocument);
}

export function* watchSupplierList() {
  yield takeLatest(actions.SITE_SUPPLIER_LIST, getSupplierList);
}

export function* watchSaveAwrDetails() {
  yield takeLatest(actions.SAVE_AWR_DETAILS, saveAwrDetails);
}

export function* watchRequirementList() {
  yield takeLatest(actions.BOOKING_RULE_REQUIREMENTS_TYPE, requirementList);
}

export function* watchSaveBookingRule() {
  yield takeLatest(actions.SAVE_BOOKING_RULE, saveBookingRule);
}

export function* watchDefaultAwrRule() {
  yield takeLatest(actions.DEFAULT_AWR_RULE, defaultAWRRule);
}

export function* watchUpdateSiteContact() {
  yield takeLatest(actions.UPDATE_SITE_CONTACT, updateSiteContact);
}

export function* watchGetSiteDetails() {
  yield takeLatest(actions.GET_SITE_DETAILS, getSiteDetails);
}

export function* watchUpdateGeneralDetails() {
  yield takeLatest(actions.UPDATE_GENERAL_DETAILS, updateGeneralDetails);
}

export function* watchAddClientShiftTypeData() {
  yield takeLatest(actions.ADD_CLIENT_SHIFT_TYPE_DATA, addClientShiftTypeData);
}
export function* watchUpdateClientShiftTypeData() {
  yield takeLatest(actions.UPDATE_CLIENT_SHIFT_TYPE_DATA, updateClientShiftTypeData);
}
export function* watchDeleteClientShiftTypeData() {
  yield takeLatest(actions.DELETE_CLIENT_SHIFT_TYPE_DATA, deleteClientShiftTypeData);
}

export function* watchGetContactDetails() {
  yield takeLatest(actions.GET_SITE_CONTACT_DETAILS, getSiteContactDetails);
}

export function* watchDeleteAWR() {
  yield takeLatest(actions.DELETE_AWR_RULE, deleteAWRDetails);
}

export function* watchCopyAWR() {
  yield takeLatest(actions.COPY_AWR_RULE, copyAWRDetails);
}

export function* watchGetBookingRule() {
  yield takeLatest(actions.GET_BOOKING_RULE, getBookingRule);
}

export function* watchSaveResourceRequirement() {
  yield takeLatest(actions.SAVE_RESOURCE_REQUIREMENT, saveResourceRequirements);
}

export function* watchGetResourceRequirement() {
  yield takeLatest(actions.GET_RESOURCE_REQUIREMENT, getResourceRequirements);
}

export function* watchUpdateResourceRequirement() {
  yield takeLatest(actions.UPDATE_RESOURCE_REQUIREMENT, updateResourceRequirements);
}

export function* watchDeleteResourceRequirement() {
  yield takeLatest(actions.DELETE_RESOURCE_REQUIREMENT, deleteResourceRequirements);
}

export function* watchGetSitePipelineCandidates() {
  yield takeLatest(actions.GET_PIPELINE_CANDIDATE, getSitePipelineCandidates);
}

export function* watchGetSiteCandidatePerformance() {
  yield takeLatest(actions.GET_SITE_CANDIDATE_PERFORMANCE, getSiteCandidatePerformance);
}

export function* watchGetSiteInfringement() {
  yield takeLatest(actions.GET_SITE_INFRINGEMENT, getSiteInfringement);
}

export function* watchGetSiteDocument() {
  yield takeLatest(actions.GET_SITE_DOCUMENT, getSiteDocument);
}

export function* watchDeleteSiteDocument() {
  yield takeLatest(actions.DELETE_SITE_DOCUMENT, deleteSiteDocument);
}

export function* watchUpdateSiteInfringement() {
  yield takeLatest(actions.UPDATE_SITE_INFRINGEMENT, updateSiteInfringementData);
}

export function* watchGetClientCandidateHistory() {
  yield takeLatest(actions.CANDIDATE_HISTORY, getClientCandidateHistory);
}

export function* watchClientCandidateEmail() {
  yield takeLatest(actions.CANDIDATE_EMAIL_ALL, clientCandidateEmail);
}
