import { call } from "redux-saga/effects";
import { httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes/supplierTypes";

export function* supplierList() {
  const {
    supplierList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_SUPPLIER_LIST_SUCCESS,
    errorAction: actions.GET_SUPPLIER_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addSupplier({ data }) {
  const {
    addSupplier: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_SUPPLIER_SUCCESS,
    errorAction: actions.ADD_SUPPLIER_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateSupplier({ data }) {
  const {
    updateSupplier: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_SUPPLIER_SUCCESS,
    errorAction: actions.UPDATE_SUPPLIER_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteSupplier({ data }) {
  const {
    deleteSupplier: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_SUPPLIER_SUCCESS,
    errorAction: actions.DELETE_SUPPLIER_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getSupplierEmailTemplate({ data }) {
  const {
    getSupplierEmailTemplate: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_SUPPLIER_EMAIL_TEMPLATE_SUCCESS,
    errorAction: actions.GET_SUPPLIER_EMAIL_TEMPLATE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addStsCandidate({ data }) {
  const {
    addStsCandidate: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_STS_CANDIDATE_SUCCESS,
    errorAction: actions.ADD_STS_CANDIDATE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getListSupplierCandidatesPaged({ data }) {
  const {
    getListSupplierCandidatesPaged: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_LIST_SUPPLIER_CANDIDATES_PAGED_SUCCESS,
    errorAction: actions.GET_LIST_SUPPLIER_CANDIDATES_PAGED_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendSupplierEmail({ data }) {
  const {
    sendSupplierEmail: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SEND_SUPPLIER_EMAIL_SUCCESS,
    errorAction: actions.SEND_SUPPLIER_EMAIL_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* saveSupplierClientApproval({ data }) {
  const {
    saveSupplierClientApproval: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SUPPLIER_CLIENT_APPROVAL_SUCCESS,
    errorAction: actions.SUPPLIER_CLIENT_APPROVAL_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
