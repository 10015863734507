import action from "../actionTypes/filterTypes";
import labels from "../../constants/labels";

const {
  SITE_FILTER: { ACTIVE }
} = labels;

const initialState = {
  selectedClient: [],
  selectedStatus: [ACTIVE],
  selectedWorkHistoryStatus: [],
  selectedSiteCandidateType: [],
  selectedSitePerformance: [],
  selectedSiteInfringementType: [],
  selectedSiteInfringementStatus: [],
  selectedPayment: [],
  searchByKeyword: null,
  selectedSite: [],
  selectedBranches: [],
  selectedTags: [],
  currentUserRoleData: null
};

const siteListFilterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case action.SITE_SELECTED_CLIENT:
      return { ...state, selectedClient: [...payload] };
    case action.SITE_SELECTED_STATUS:
      return { ...state, selectedStatus: [...payload] };
    case action.WORK_HISTORY_SELECTED_STATUS:
      return { ...state, selectedWorkHistoryStatus: [...payload] };
    case action.SELECTED_SITE_CANDIDATE_TYPE:
      return { ...state, selectedSiteCandidateType: [...payload] };
    case action.SITE_SELECTED_PERFORMANCE:
      return { ...state, selectedSitePerformance: [...payload] };
    case action.SITE_SELECTED_INFRINGEMENT_TYPE:
      return { ...state, selectedSiteInfringementType: [...payload] };
    case action.SITE_SELECTED_INFRINGEMENT_STATUS:
      return { ...state, selectedSiteInfringementStatus: [...payload] };
    case action.SITE_SELECTED_PAYMENT:
      return { ...state, selectedPayment: [...payload] };
    case action.SITE_SEARCH_KEYWORD:
      return { ...state, searchByKeyword: payload };
    case action.SITE_SEARCH_KEYWORD_CLEAR:
      return { ...state, searchByKeyword: "" };
    case action.SITE_SELECTED_BRANCHES:
      return { ...state, selectedBranches: [...payload] };
    case action.SITE_SELECTED_TAGS:
      return { ...state, selectedTags: [...payload] };
    case action.SITE_SELECTED_CLEAR:
      return { ...state, selectedCandidates: [] };
    case action.SITE_FILTER_CLEAR:
      return { ...initialState };
    case action.SITE_SELECTED:
      return { ...state, selectedSite: [...payload] };
    case action.CURRENT_USER_ROLE:
      return { ...state, currentUserRoleData: payload };
    default:
      return state;
  }
};

export default siteListFilterReducer;
