import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes/booking";
import {
  getBookingExpenseTypeList,
  addBookingExpenses,
  updateBookingExpenses,
  deleteBookingExpenses,
  verifiedBookings,
  unverifiedBookings,
  addBookingInfringements,
  updateBookingInfringements,
  updateBookingDetails,
  updateBasicBookingDetails,
  changeBookingAWRScope,
  updateVerifyBookingDetails,
  changeSiteDetails,
  updateBookingClientDetails,
  addBookingImport,
  getWeeklySingleBooking
} from "../workers/bookingWorker";

export function* watchGetBookingExpenseTypeList() {
  yield takeLatest(actions.EXPENSE_TYPE_LIST, getBookingExpenseTypeList);
}

export function* watchAddBookingExpenses() {
  yield takeLatest(actions.ADD_BOOKING_EXPENSES, addBookingExpenses);
}

export function* watchUpdateBookingExpenses() {
  yield takeLatest(actions.UPDATE_BOOKING_EXPENSES, updateBookingExpenses);
}

export function* watchDeleteBookingExpenses() {
  yield takeLatest(actions.DELETE_BOOKING_EXPENSES, deleteBookingExpenses);
}

export function* watchVerifiedBookings() {
  yield takeLatest(actions.VERIFY_BOOKINGS, verifiedBookings);
}

export function* watchUnverifiedBookings() {
  yield takeLatest(actions.UNVERIFY_BOOKINGS, unverifiedBookings);
}

export function* watchAddBookingInfringements() {
  yield takeLatest(actions.ADD_BOOKING_INFRINGEMENT, addBookingInfringements);
}

export function* watchUpdateBookingInfringements() {
  yield takeLatest(actions.UPDATE_BOOKING_INFRINGEMENT, updateBookingInfringements);
}

export function* watchUpdateBookingDetails() {
  yield takeLatest(actions.UPDATE_BOOKING_DETAILS, updateBookingDetails);
}

export function* watchUpdateBookingClientDetails() {
  yield takeLatest(actions.UPDATE_BOOKING_CLIENT_DETAILS, updateBookingClientDetails);
}

export function* watchUpdateBasicBookingDetails() {
  yield takeLatest(actions.UPDATE_BASIC_BOOKING_DETAILS, updateBasicBookingDetails);
}

export function* watchChangeBookingAWRScope() {
  yield takeLatest(actions.CHANGE_BOOKING_AWR, changeBookingAWRScope);
}

export function* watchUpdateVerifyBookingDetails() {
  yield takeLatest(actions.UPDATE_VERIFY_BOOKING_DETAILS, updateVerifyBookingDetails);
}

export function* watchChangeSiteDetails() {
  yield takeLatest(actions.CHANGE_SITE, changeSiteDetails);
}

export function* watchAddBookingImport() {
  yield takeLatest(actions.ADD_BOOKING_IMPORT, addBookingImport);
}

export function* watchGetWeeklySingleBooking() {
  yield takeLatest(actions.GET_WEEKLY_SINGLE_BOOKING, getWeeklySingleBooking);
}
