/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { Layout } from "antd";
import { useSelector, shallowEqual } from "react-redux";
import Auth from "../config/auth";
import ErrorBoundaries from "../modules/errors/errorBoundaries";
import Loader from "../components/pageLoader";
import NoAccess from "../modules/errors/403";
import { Header, SubHeader, Footer, ApiMessage } from "./modulesPath";

import "../assets/styles/layout.scss";

const { Content } = Layout;

const { isAuthenticated, getSessionDetails } = Auth;

const { currentUserRole } = getSessionDetails() || "";

const Redirection = ({ loggedIn = false, ...rest }) =>
  loggedIn ? <NoAccess {...rest} /> : <Redirect to="/login" />;

const SecuredRoute = ({ component: Component, path, roles, ...rest }) => {
  const isLoggedIn = useMemo(isAuthenticated, []);
  const [userRole, setUserRole] = useState(roles[0] || currentUserRole);
  const reducerData = useSelector(state => state.siteFilter, shallowEqual);
  const { currentUserRoleData } = reducerData;
  const checkAccess = (rolesArray = "") => rolesArray.includes(userRole);
  const access = useMemo(() => checkAccess(roles), [roles, userRole]);
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn && access ? (
          <ErrorBoundaries>
            <Suspense fallback={<Loader />}>
              <Layout className="drs-layout">
                <Header />
                <Content className="drs-layout-content">
                  <ApiMessage />
                  <SubHeader {...props} />
                  <div className="drs-layout-component">
                    <Component {...props} />
                  </div>
                </Content>
                <Footer />
              </Layout>
            </Suspense>
          </ErrorBoundaries>
        ) : (
          <Redirection {...props} loggedIn={isLoggedIn} />
        )
      }
    />
  );
};

export default React.memo(withRouter(SecuredRoute));
