const ALL_MESSAGES = {
  LOGIN_ERROR: "Incorrect username or password.",
  REQUEST_RESET_PASSWORD_ERROR: "Incorrect username or email.",
  API_ERROR: {
    API_FAIL_500: "Internal Server error"
  }
};

const MESSAGES = Object.freeze(ALL_MESSAGES);

export default MESSAGES;
