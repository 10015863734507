/* eslint-disable no-unused-vars */
import constants from "../constants/constants";
import roleConstants from "../constants/roles";

const {
  PAGE_ROUTES: { HOME, TASKS, DAILY_BOOKING, VERIFY_BOOKING, USER_MANAGEMENT }
} = constants;

const { OPERATOR, AGENCY_USER, FINANCE_USER, AGENCY_ADMIN, SYSTEM_ADMIN, CLIENT_USER } =
  roleConstants;

const getDefaultRoute = role => {
  switch (role) {
    case AGENCY_USER:
    case AGENCY_ADMIN:
      return DAILY_BOOKING;
    case FINANCE_USER:
      return VERIFY_BOOKING;
    case OPERATOR:
      return TASKS;
    case SYSTEM_ADMIN:
      return USER_MANAGEMENT;
    case CLIENT_USER:
      return DAILY_BOOKING;
    default:
      return HOME;
  }
};

export default getDefaultRoute;
