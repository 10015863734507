import React from "react";
import { withRouter } from "react-router-dom";
import ErrorHandler from "./errorHandler";

import labels from "../../constants/labels";

const {
  ERROR_PAGE: { NO_ACCESS }
} = labels;

const NoAccess = () => {
  return <ErrorHandler errorCase={NO_ACCESS} />;
};

export default React.memo(withRouter(NoAccess));
