import { combineReducers } from "redux";

import actions from "../actionTypes/calendar";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const getDailyBookingList = createReducer(defaultState, {
  [actions.DAILY_BOOKING_LIST]: isLoadingStateReducer,
  [actions.DAILY_BOOKING_LIST_SUCCESS]: successStateReducer,
  [actions.DAILY_BOOKING_LIST_ERROR]: errorStateReducer,
  [actions.DAILY_BOOKING_LIST_CLEAR]: resetStateReducer
});

const getMonthlyBookingList = createReducer(defaultState, {
  [actions.MONTHLY_BOOKING_LIST]: isLoadingStateReducer,
  [actions.MONTHLY_BOOKING_LIST_SUCCESS]: successStateReducer,
  [actions.MONTHLY_BOOKING_LIST_ERROR]: errorStateReducer,
  [actions.MONTHLY_BOOKING_LIST_CLEAR]: resetStateReducer
});

const getWeeeklyBookingList = createReducer(defaultState, {
  [actions.WEEKLY_BOOKING_LIST]: isLoadingStateReducer,
  [actions.WEEKLY_BOOKING_LIST_SUCCESS]: successStateReducer,
  [actions.WEEKLY_BOOKING_LIST_ERROR]: errorStateReducer,
  [actions.WEEKLY_BOOKING_LIST_CLEAR]: resetStateReducer
});

const getMonthlyBookingsOverviewList = createReducer(defaultState, {
  [actions.MONTHLY_BOOKING_LIST_OVERVIEW]: isLoadingStateReducer,
  [actions.MONTHLY_BOOKING_LIST_OVERVIEW_SUCCESS]: successStateReducer,
  [actions.MONTHLY_BOOKING_LIST_OVERVIEW_ERROR]: errorStateReducer,
  [actions.MONTHLY_BOOKING_LIST_OVERVIEW_CLEAR]: resetStateReducer
});

const updateNightOut = createReducer(defaultState, {
  [actions.NIGHT_OUT_UPDATE]: isLoadingStateReducer,
  [actions.NIGHT_OUT_UPDATE_SUCCESS]: successStateReducer,
  [actions.NIGHT_OUT_UPDATE_ERROR]: errorStateReducer,
  [actions.NIGHT_OUT_UPDATE_CLEAR]: resetStateReducer
});

const holdFromAppSingle = createReducer(defaultState, {
  [actions.HOLD_FROM_APP_SINGLE]: isLoadingStateReducer,
  [actions.HOLD_FROM_APP_SINGLE_SUCCESS]: successStateReducer,
  [actions.HOLD_FROM_APP_SINGLE_ERROR]: errorStateReducer,
  [actions.HOLD_FROM_APP_SINGLE_CLEAR]: resetStateReducer
});

const holdFromAppMultiple = createReducer(defaultState, {
  [actions.HOLD_FROM_APP_MULTIPLE]: isLoadingStateReducer,
  [actions.HOLD_FROM_APP_MULTIPLE_SUCCESS]: successStateReducer,
  [actions.HOLD_FROM_APP_MULTIPLE_ERROR]: errorStateReducer,
  [actions.HOLD_FROM_APP_MULTIPLE_CLEAR]: resetStateReducer
});

export default combineReducers({
  getDailyBookingList,
  getMonthlyBookingList,
  getWeeeklyBookingList,
  getMonthlyBookingsOverviewList,
  updateNightOut,
  holdFromAppSingle,
  holdFromAppMultiple
});
