import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes";
import {
  getCostAnalysisReport,
  getKPIReport,
  getClientInfrigement,
  getClientPerformance,
  getClientInfrigementSign,
  getClientPerformanceClose,
  addClientInfo,
  updateClientInfo,
  deleteClientInfo
} from "../workers/clientWorker";

export function* watchCostReport() {
  yield takeLatest(actions.COST_ANALYSIS_REPORT, getCostAnalysisReport);
}

export function* watchKPIReport() {
  yield takeLatest(actions.KPI_REPORT, getKPIReport);
}

export function* watchClientInfrigement() {
  yield takeLatest(actions.CLIENT_INFRIGEMENT, getClientInfrigement);
}

export function* watchClientPerformance() {
  yield takeLatest(actions.CLIENT_PERFORMANCE, getClientPerformance);
}

export function* watchClientInfrigementSign() {
  yield takeLatest(actions.CLIENT_INFRIGEMENT_SIGN, getClientInfrigementSign);
}

export function* watchClientPerformanceclose() {
  yield takeLatest(actions.CLIENT_PERFORMANCE_CLOSE, getClientPerformanceClose);
}

export function* watchAddClientInfo() {
  yield takeLatest(actions.ADD_CLIENT_INFO, addClientInfo);
}

export function* watchUpdateClientInfo() {
  yield takeLatest(actions.UPDATE_CLIENT_INFO, updateClientInfo);
}

export function* watchDeleteClientInfo() {
  yield takeLatest(actions.DELETE_CLIENT_INFO, deleteClientInfo);
}
