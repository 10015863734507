import { call } from "redux-saga/effects";
import { httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes";

export function* sendEmail({ payload }) {
  const {
    siteSendEMail: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.SITE_LIST_EMAIL_SUCCESS,
    errorAction: actions.SITE_LIST_EMAIL_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* listEmail({ payload }) {
  const {
    siteAllEMailList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint + payload,
    apiMethod: method,
    successAction: actions.SITE_LIST_EMAIL_LIST_SUCCESS,
    errorAction: actions.SITE_LIST_EMAIL_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* listPhoneNumer({ payload }) {
  const {
    sitePhoneNumberList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint + payload,
    apiMethod: method,
    successAction: actions.SITE_LIST_SMS_LIST_SUCCESS,
    errorAction: actions.SITE_LIST_SMS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* sendSMS({ payload }) {
  const {
    siteSendSMS: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?sitePhoneNumber=${payload.sitePhoneNumber}&smsContent=${payload.smsContent}`,
    apiMethod: method,
    successAction: actions.SITE_LIST_SMS_SUCCESS,
    errorAction: actions.SITE_LIST_SMS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getLocation({ payload }) {
  const {
    getContact: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?objectId=${payload}`,
    apiMethod: method,
    successAction: actions.VIEW_SITE_LOCATION_SUCCESS,
    errorAction: actions.VIEW_SITE_LOCATION_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addBookingRule() {
  const {
    addBookingRule: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.ADD_BOOKING_RULE_SUCCESS,
    errorAction: actions.ADD_BOOKING_RULE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
