import React from "react";
import PropTypes from "prop-types";
import LoaderIcon from "../../assets/images/drsLoader.gif";

const MainPageLoader = ({ styleProp = "" }) => {
  return <img src={LoaderIcon} alt="LoaderIconForDrs" style={styleProp} />;
};

MainPageLoader.propTypes = {
  styleProp: PropTypes.object
};

MainPageLoader.defaultProps = {
  styleProp: { width: 60, height: 60 }
};

export default React.memo(MainPageLoader);
