import action from "../actionTypes";

const initialState = { successMessage: null, errorMessage: null };

const apiMessageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case action.API_SUCCESS_MSG:
      return { ...state, successMessage: payload };
    case action.API_FAILURE_MSG:
      return { ...state, errorMessage: payload };
    case action.API_MSG_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};

export default apiMessageReducer;
