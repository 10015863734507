import { call } from "redux-saga/effects";
import { httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes";

export function* getPendingDocumentList({ data }) {
  const {
    pendingDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?objectId=${data.ObjectId}&date=${data.Date}`,
    apiMethod: method,
    successAction: actions.PENDING_DOCUMENT_LIST_SUCCESS,
    errorAction: actions.PENDING_DOCUMENT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getPayDocumentList({ data }) {
  const {
    payDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.PAY_DOCUMENT_LIST_SUCCESS,
    errorAction: actions.PAY_DOCUMENT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* holdPendingDocument({ data }) {
  const {
    holdPendingDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.HOLD_DOCUMENT_SUCCESS,
    errorAction: actions.HOLD_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* unHoldDocument({ data }) {
  const {
    unHoldPendingDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.UNHOLD_DOCUMENT_SUCCESS,
    errorAction: actions.UNHOLD_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deletePendingDocument({ data }) {
  const {
    deletePendingDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.DELETE_PENDING_DOCUMENT_SUCCESS,
    errorAction: actions.DELETE_PENDING_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* modifyPayDocument({ data }) {
  const {
    modifyPayDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.MODIFY_PAY_DOCUMENT_SUCCESS,
    errorAction: actions.MODIFY_PAY_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendToXero({ data }) {
  const {
    sendToXero: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SEND_TO_XERO_SUCCESS,
    errorAction: actions.SEND_TO_XERO_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* viewPayDocument({ data }) {
  const {
    viewPayDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.VIEW_DOCUMENT_SUCCESS,
    errorAction: actions.VIEW_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* payRateListing({ data }) {
  const {
    payRateListing: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.PAY_RATE_SUCCESS,
    errorAction: actions.PAY_RATE_LISTING_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getHolidayListing({ data }) {
  const {
    getHolidayListing: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.HOLIDAY_LISTING_SUCCESS,
    errorAction: actions.HOLIDAY_LISTING_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* deleteMultipleHolidays({ data }) {
  const {
    deleteMultipleHolidays: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_MULTIPLE_HOLIDAYS_SUCCESS,
    errorAction: actions.DELETE_MULTIPLE_HOLIDAYS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addAdjustment({ data }) {
  const {
    addAdjustment: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_ADJUSTMENT_SUCCESS,
    errorAction: actions.ADD_ADJUSTMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updatePayDocument({ data }) {
  const {
    updatePayDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_PAY_DOCUMENT_SUCCESS,
    errorAction: actions.UPDATE_PAY_DOCUMENT_ERROR,
    successMessage,
    popUp: true,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateAdjustment({ data }) {
  const {
    updateAdjustment: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_ADJUSTMENT_SUCCESS,
    errorAction: actions.ADD_ADJUSTMENT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* deleteAdjustment({ data }) {
  const {
    deleteAdjustment: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_ADJUSTMENT_SUCCESS,
    errorAction: actions.ADD_ADJUSTMENT_ERROR,
    successMessage,
    errorMessage
  };

  yield call(httpRequestForData, requestParameters);
}

export function* createPayDocument({ data }) {
  const {
    createPayDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CREATE_PAY_DOCUMENT_SUCCESS,
    errorAction: actions.CREATE_PAY_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* deletePayRate({ data }) {
  const {
    deletePayRate: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.DELETE_PAY_RATE_SUCCESS,
    errorAction: actions.DELETE_PAY_RATE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* buildPayDocuments({ data }) {
  const {
    buildPayDocuments: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.BUILD_PAY_DOCUMENTS_SUCCESS,
    errorAction: actions.BUILD_PAY_DOCUMENTS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addpayRateRule({ data }) {
  const {
    addpayRateRules: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_PAY_RATE_RULES_SUCCESS,
    errorAction: actions.ADD_PAY_RATE_RULES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addPayRateCards({ data }) {
  const {
    addpayRateCard: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_RATE_CARD_SUCCESS,
    errorAction: actions.ADD_RATE_CARD_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* updateRateCard({ data }) {
  const {
    UpdatepayRateCard: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_RATE_CARD_SUCCESS,
    errorAction: actions.UPDATE_RATE_CARD_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteRateCard({ data }) {
  const {
    deletePayRateCard: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_RATE_CARD_SUCCESS,
    errorAction: actions.DELETE_RATE_CARD_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateRateRules({ data }) {
  const {
    updatePayRateRules: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_PAY_RATE_RULES_SUCCESS,
    errorAction: actions.UPDATE_PAY_RATE_RULES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* viewPayRate({ data }) {
  const {
    ViewpayRateCard: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.VIEW_PAY_RATE_SUCCESS,
    errorAction: actions.VIEW_PAY_RATE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getBuildProgress({ data }) {
  const {
    getBuildProgress: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.BUILD_PAY_DOCUMENTS_PROGRESS_SUCCESS,
    errorAction: actions.BUILD_PAY_DOCUMENTS_PROGRESS_ERROR,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* viewPayRateHistory({ data }) {
  const {
    viewPayRateHistory: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.VIEW_PAY_RATE_HISTORY_SUCCESS,
    errorAction: actions.VIEW_PAY_RATE_HISTORY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* copyPayRate({ data }) {
  const {
    copyPayRate: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.payRateObjectID}&name=${data.name}`,
    apiMethod: method,
    successAction: actions.COPY_PAY_RATE_SUCCESS,
    errorAction: actions.COPY_PAY_RATE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getUserClientList() {
  const {
    getUserClientList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_USER_CLIENT_LIST_SUCCESS,
    errorAction: actions.GET_USER_CLIENT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getVerfiyBooking({ data }) {
  const {
    getVerfiyBooking: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_VERIFY_BOOKING_LIST_SUCCESS,
    errorAction: actions.GET_VERIFY_BOOKING_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* mergeDocuments({ payload }) {
  const {
    mergeDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.MERGE_DOCUMENT_SUCCESS,
    errorAction: actions.MERGE_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
