const DATE_CONSTANTS = {
  TIME_FORMAT: "HH:mm:ss",
  ONLY_MINUTE: "HH:mm",
  DATE_FORMAT_STRING: "YYYY-MM-DD",
  DRS_DATE_FORMAT: "DD/MM/YYYY",
  DRS_ISO_DATE: "YYYY-MM-DDTHH:mm:ss",
  ISO_T: "T",
  ZERO_TIME: "00:00",
  DAYS: "days",
  TIME_COLON: ":",
  FORMAT_DEFAULT: "DD-MM-YYYY",
  FORMAT_2: "DD/MM/YYYY",
  FORMAT_3: "MMM/YYYY",
  weekDays: {
    SUNDAY: "SUNDAY",
    MONDAY: "MONDAY",
    TUESDAY: "TUESDAY",
    WEDNESDAY: "WEDNESDAY",
    THURSDAY: "THURSDAY",
    FRIDAY: "FRIDAY",
    SATURDAY: "SATURDAY"
  }
};

const DATES = Object.freeze(DATE_CONSTANTS);

export default DATES;
