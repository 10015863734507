import { combineReducers } from "redux";
import actions from "../actionTypes";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const sendSiteEmail = createReducer(defaultState, {
  [actions.SITE_LIST_EMAIL]: isLoadingStateReducer,
  [actions.SITE_LIST_EMAIL_SUCCESS]: successStateReducer,
  [actions.SITE_LIST_EMAIL_ERROR]: errorStateReducer,
  [actions.SITE_LIST_EMAIL_CLEAR]: resetStateReducer
});

const listSiteEmail = createReducer(defaultState, {
  [actions.SITE_LIST_EMAIL_LIST]: isLoadingStateReducer,
  [actions.SITE_LIST_EMAIL_LIST_SUCCESS]: successStateReducer,
  [actions.SITE_LIST_EMAIL_LIST_ERROR]: errorStateReducer,
  [actions.SITE_LIST_EMAIL_LIST_CLEAR]: resetStateReducer
});

const listSitePhoneNumber = createReducer(defaultState, {
  [actions.SITE_LIST_SMS_LIST]: isLoadingStateReducer,
  [actions.SITE_LIST_SMS_LIST_SUCCESS]: successStateReducer,
  [actions.SITE_LIST_SMS_LIST_ERROR]: errorStateReducer,
  [actions.SITE_LIST_SMS_LIST_CLEAR]: resetStateReducer
});

const siteSendSMS = createReducer(defaultState, {
  [actions.SITE_LIST_SMS]: isLoadingStateReducer,
  [actions.SITE_LIST_SMS_SUCCESS]: successStateReducer,
  [actions.SITE_LIST_SMS_ERROR]: errorStateReducer,
  [actions.SITE_LIST_SMS_CLEAR]: resetStateReducer
});

const viewSiteLocation = createReducer(defaultState, {
  [actions.VIEW_SITE_LOCATION]: isLoadingStateReducer,
  [actions.VIEW_SITE_LOCATION_SUCCESS]: successStateReducer,
  [actions.VIEW_SITE_LOCATION_ERROR]: errorStateReducer,
  [actions.VIEW_SITE_LOCATION_CLEAR]: resetStateReducer
});

const SiteDaysOfWeek = createReducer(defaultState, {
  [actions.SITE_DAYS_OF_WEEK]: isLoadingStateReducer,
  [actions.SITE_DAYS_OF_WEEK_SUCCESS]: successStateReducer,
  [actions.SITE_LIST_EMAIL_ERROR]: errorStateReducer,
  [actions.SITE_LIST_EMAIL_LIST_CLEAR]: resetStateReducer
});

const addSiteGeneralDetails = createReducer(defaultState, {
  [actions.ADD_SITE_GENERAL_DETAILS]: isLoadingStateReducer,
  [actions.ADD_SITE_GENERAL_DETAILS_SUCCESS]: successStateReducer,
  [actions.ADD_SITE_GENERAL_DETAILS_ERROR]: errorStateReducer,
  [actions.ADD_SITE_GENERAL_DETAILS_CLEAR]: resetStateReducer
});

const siteDefaultShiftType = createReducer(defaultState, {
  [actions.SITE_DEFAULT_SHIFT_TYPE]: isLoadingStateReducer,
  [actions.SITE_DEFAULT_SHIFT_TYPE_SUCCESS]: successStateReducer,
  [actions.SITE_DEFAULT_SHIFT_TYPE_ERROR]: errorStateReducer,
  [actions.SITE_DEFAULT_SHIFT_TYPE_CLEAR]: resetStateReducer
});

const addContactDetails = createReducer(defaultState, {
  [actions.ADD_SITE_CONTACT_FORM]: isLoadingStateReducer,
  [actions.ADD_SITE_CONTACT_FORM_SUCCESS]: successStateReducer,
  [actions.ADD_SITE_CONTACT_FORM_ERROR]: errorStateReducer,
  [actions.ADD_SITE_CONTACT_FORM_CLEAR]: resetStateReducer
});

const getDocumentType = createReducer(defaultState, {
  [actions.SITE_DOCUMENT]: isLoadingStateReducer,
  [actions.SITE_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.SITE_DOCUMENT_ERROR]: errorStateReducer,
  [actions.SITE_DOCUMENT_CLEAR]: resetStateReducer
});

const addSiteDocument = createReducer(defaultState, {
  [actions.ADD_SITE_DOCUMENT]: isLoadingStateReducer,
  [actions.ADD_SITE_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.ADD_SITE_DOCUMENT_ERROR]: errorStateReducer,
  [actions.ADD_SITE_DOCUMENT_CLEAR]: resetStateReducer
});

const getSupplierList = createReducer(defaultState, {
  [actions.SITE_SUPPLIER_LIST]: isLoadingStateReducer,
  [actions.SITE_SUPPLIER_LIST_SUCCESS]: successStateReducer,
  [actions.SITE_SUPPLIER_LIST_ERROR]: errorStateReducer,
  [actions.SITE_SUPPLIER_LIST_CLEAR]: resetStateReducer
});

const addBookingRule = createReducer(defaultState, {
  [actions.ADD_BOOKING_RULE]: isLoadingStateReducer,
  [actions.ADD_BOOKING_RULE_SUCCESS]: successStateReducer,
  [actions.ADD_BOOKING_RULE_ERROR]: errorStateReducer,
  [actions.ADD_BOOKING_RULE_CLEAR]: resetStateReducer
});

const saveAwrDetails = createReducer(defaultState, {
  [actions.SAVE_AWR_DETAILS]: isLoadingStateReducer,
  [actions.SAVE_AWR_DETAILS_SUCCESS]: successStateReducer,
  [actions.SAVE_AWR_DETAILS_ERROR]: errorStateReducer,
  [actions.SAVE_AWR_DETAILS_CLEAR]: resetStateReducer
});

const bookRuleRequirementType = createReducer(defaultState, {
  [actions.BOOKING_RULE_REQUIREMENTS_TYPE]: isLoadingStateReducer,
  [actions.BOOKING_RULE_REQUIREMENTS_TYPE_SUCCESS]: successStateReducer,
  [actions.BOOKING_RULE_REQUIREMENTS_TYPE_ERROR]: errorStateReducer,
  [actions.BOOKING_RULE_REQUIREMENTS_TYPE_CLEAR]: resetStateReducer
});

const saveBookingRule = createReducer(defaultState, {
  [actions.SAVE_BOOKING_RULE]: isLoadingStateReducer,
  [actions.SAVE_BOOKING_RULE_SUCCESS]: successStateReducer,
  [actions.SAVE_BOOKING_RULE_ERROR]: errorStateReducer,
  [actions.SAVE_BOOKING_RULE_CLEAR]: resetStateReducer
});

const defaultAWRRule = createReducer(defaultState, {
  [actions.DEFAULT_AWR_RULE]: isLoadingStateReducer,
  [actions.DEFAULT_AWR_RULE_SUCCESS]: successStateReducer,
  [actions.DEFAULT_AWR_RULE_ERROR]: errorStateReducer,
  [actions.DEFAULT_AWR_RULE_CLEAR]: resetStateReducer
});

const updateSiteContact = createReducer(defaultState, {
  [actions.UPDATE_SITE_CONTACT]: isLoadingStateReducer,
  [actions.UPDATE_SITE_CONTACT_SUCCESS]: successStateReducer,
  [actions.UPDATE_SITE_CONTACT_ERROR]: errorStateReducer,
  [actions.UPDATE_SITE_CONTACT_CLEAR]: resetStateReducer
});

const getGeneralDetails = createReducer(defaultState, {
  [actions.GET_SITE_DETAILS]: isLoadingStateReducer,
  [actions.GET_SITE_DETAILS_SUCCESS]: successStateReducer,
  [actions.GET_SITE_DETAILS_ERROR]: errorStateReducer,
  [actions.GET_SITE_DETAILS_CLEAR]: resetStateReducer
});

const updateGeneralDetails = createReducer(defaultState, {
  [actions.UPDATE_GENERAL_DETAILS]: isLoadingStateReducer,
  [actions.UPDATE_GENERAL_DETAILS_SUCCESS]: successStateReducer,
  [actions.UPDATE_GENERAL_DETAILS_ERROR]: errorStateReducer,
  [actions.UPDATE_GENERAL_DETAILS_CLEAR]: resetStateReducer
});

const addClientShiftTypeDAta = createReducer(defaultState, {
  [actions.ADD_CLIENT_SHIFT_TYPE_DATA]: isLoadingStateReducer,
  [actions.ADD_CLIENT_SHIFT_TYPE_DATA_SUCCESS]: successStateReducer,
  [actions.ADD_CLIENT_SHIFT_TYPE_DATA_ERROR]: errorStateReducer,
  [actions.ADD_CLIENT_SHIFT_TYPE_DATA_CLEAR]: resetStateReducer
});

const updateClientShiftTypeDAta = createReducer(defaultState, {
  [actions.UPDATE_CLIENT_SHIFT_TYPE_DATA]: isLoadingStateReducer,
  [actions.UPDATE_CLIENT_SHIFT_TYPE_DATA_SUCCESS]: successStateReducer,
  [actions.UPDATE_CLIENT_SHIFT_TYPE_DATA_ERROR]: errorStateReducer,
  [actions.UPDATE_CLIENT_SHIFT_TYPE_DATA_CLEAR]: resetStateReducer
});

const deleteClientShiftTypeData = createReducer(defaultState, {
  [actions.DELETE_CLIENT_SHIFT_TYPE_DATA]: isLoadingStateReducer,
  [actions.DELETE_CLIENT_SHIFT_TYPE_DATA_SUCCESS]: successStateReducer,
  [actions.DELETE_CLIENT_SHIFT_TYPE_DATA_ERROR]: errorStateReducer,
  [actions.DELETE_CLIENT_SHIFT_TYPE_DATA_CLEAR]: resetStateReducer
});

const getSiteContactDetails = createReducer(defaultState, {
  [actions.GET_SITE_CONTACT_DETAILS]: isLoadingStateReducer,
  [actions.GET_SITE_CONTACT_DETAILS_SUCCESS]: successStateReducer,
  [actions.GET_SITE_CONTACT_DETAILS_ERROR]: errorStateReducer,
  [actions.GET_SITE_CONTACT_DETAILS_CLEAR]: resetStateReducer
});

const deleteAWRRule = createReducer(defaultState, {
  [actions.DELETE_AWR_RULE]: isLoadingStateReducer,
  [actions.DELETE_AWR_RULE_SUCCESS]: successStateReducer,
  [actions.DELETE_AWR_RULE_ERROR]: errorStateReducer,
  [actions.DELETE_AWR_RULE_CLEAR]: resetStateReducer
});

const copyAWRRule = createReducer(defaultState, {
  [actions.COPY_AWR_RULE]: isLoadingStateReducer,
  [actions.COPY_AWR_RULE_SUCCESS]: successStateReducer,
  [actions.COPY_AWR_RULE_ERROR]: errorStateReducer,
  [actions.COPY_AWR_RULE_CLEAR]: resetStateReducer
});

const getBookingRule = createReducer(defaultState, {
  [actions.GET_BOOKING_RULE]: isLoadingStateReducer,
  [actions.GET_BOOKING_RULE_SUCCESS]: successStateReducer,
  [actions.GET_BOOKING_RULE_ERROR]: errorStateReducer,
  [actions.GET_BOOKING_RULE_CLEAR]: resetStateReducer
});

const getResourceRequirements = createReducer(defaultState, {
  [actions.GET_RESOURCE_REQUIREMENT]: isLoadingStateReducer,
  [actions.GET_RESOURCE_REQUIREMENT_SUCCESS]: successStateReducer,
  [actions.GET_RESOURCE_REQUIREMENT_ERROR]: errorStateReducer,
  [actions.GET_RESOURCE_REQUIREMENT_CLEAR]: resetStateReducer
});

const saveResourceRequirements = createReducer(defaultState, {
  [actions.SAVE_RESOURCE_REQUIREMENT]: isLoadingStateReducer,
  [actions.SAVE_RESOURCE_REQUIREMENT_SUCCESS]: successStateReducer,
  [actions.SAVE_RESOURCE_REQUIREMENT_CLEAR]: errorStateReducer,
  [actions.SAVE_RESOURCE_REQUIREMENT_ERROR]: resetStateReducer
});

const deleteResourceRequirements = createReducer(defaultState, {
  [actions.DELETE_RESOURCE_REQUIREMENT]: isLoadingStateReducer,
  [actions.DELETE_RESOURCE_REQUIREMENT_SUCCESS]: successStateReducer,
  [actions.DELETE_RESOURCE_REQUIREMENT_ERROR]: errorStateReducer,
  [actions.DELETE_RESOURCE_REQUIREMENT_CLEAR]: resetStateReducer
});

const updateResourceRequirements = createReducer(defaultState, {
  [actions.UPDATE_RESOURCE_REQUIREMENT]: isLoadingStateReducer,
  [actions.UPDATE_RESOURCE_REQUIREMENT_SUCCESS]: successStateReducer,
  [actions.UPDATE_RESOURCE_REQUIREMENT_ERROR]: errorStateReducer,
  [actions.UPDATE_RESOURCE_REQUIREMENT_CLEAR]: resetStateReducer
});

const getSitePipelineCandidates = createReducer(defaultState, {
  [actions.GET_PIPELINE_CANDIDATE]: isLoadingStateReducer,
  [actions.GET_PIPELINE_CANDIDATE_SUCCESS]: successStateReducer,
  [actions.GET_PIPELINE_CANDIDATE_ERROR]: errorStateReducer,
  [actions.GET_PIPELINE_CANDIDATE_CLEAR]: resetStateReducer
});

const getSiteCandidatePerformance = createReducer(defaultState, {
  [actions.GET_SITE_CANDIDATE_PERFORMANCE]: isLoadingStateReducer,
  [actions.GET_SITE_CANDIDATE_PERFORMANCE_SUCCESS]: successStateReducer,
  [actions.GET_SITE_CANDIDATE_PERFORMANCE_ERROR]: errorStateReducer,
  [actions.GET_SITE_CANDIDATE_PERFORMANCE_CLEAR]: resetStateReducer
});

const getSiteInfringement = createReducer(defaultState, {
  [actions.GET_SITE_INFRINGEMENT]: isLoadingStateReducer,
  [actions.GET_SITE_INFRINGEMENT_SUCCESS]: successStateReducer,
  [actions.GET_SITE_INFRINGEMENT_ERROR]: errorStateReducer,
  [actions.GET_SITE_INFRINGEMENT_CLEAR]: resetStateReducer
});

const getSiteDocument = createReducer(defaultState, {
  [actions.GET_SITE_DOCUMENT]: isLoadingStateReducer,
  [actions.GET_SITE_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.GET_SITE_DOCUMENT_ERROR]: errorStateReducer,
  [actions.GET_SITE_DOCUMENT_CLEAR]: resetStateReducer
});

const deleteSiteDocument = createReducer(defaultState, {
  [actions.DELETE_SITE_DOCUMENT]: isLoadingStateReducer,
  [actions.DELETE_SITE_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.DELETE_SITE_DOCUMENT_ERROR]: errorStateReducer,
  [actions.DELETE_SITE_DOCUMENT_CLEAR]: resetStateReducer
});

const updateSiteInfringementInfo = createReducer(defaultState, {
  [actions.UPDATE_SITE_INFRINGEMENT]: isLoadingStateReducer,
  [actions.UPDATE_SITE_INFRINGEMENT_SUCCESS]: successStateReducer,
  [actions.UPDATE_SITE_INFRINGEMENT_ERROR]: errorStateReducer,
  [actions.UPDATE_SITE_INFRINGEMENT_CLEAR]: resetStateReducer
});
const listReports = createReducer(defaultState, {
  [actions.LIST_REPORTS]: isLoadingStateReducer,
  [actions.LIST_REPORTS_SUCCESS]: successStateReducer,
  [actions.LIST_REPORTS_ERROR]: errorStateReducer,
  [actions.LIST_REPORTS_CLEAR]: resetStateReducer
});

const getReportInfo = createReducer(defaultState, {
  [actions.GET_REPORTS_INFO]: isLoadingStateReducer,
  [actions.GET_REPORTS_INFO_SUCCESS]: successStateReducer,
  [actions.GET_REPORTS_INFO_ERROR]: errorStateReducer,
  [actions.GET_REPORTS_INFO_CLEAR]: resetStateReducer
});

const getClientCandidateHistory = createReducer(defaultState, {
  [actions.CANDIDATE_HISTORY]: isLoadingStateReducer,
  [actions.CANDIDATE_HISTORY_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_HISTORY_ERROR]: errorStateReducer,
  [actions.CANDIDATE_HISTORY_CLEAR]: resetStateReducer
});

const clientCandidateEmail = createReducer(defaultState, {
  [actions.CANDIDATE_EMAIL]: isLoadingStateReducer,
  [actions.CANDIDATE_EMAIL_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_EMAIL_ERROR]: errorStateReducer,
  [actions.CANDIDATE_EMAIL_CLEAR]: resetStateReducer
});
export default combineReducers({
  addClientShiftTypeDAta,
  updateClientShiftTypeDAta,
  deleteClientShiftTypeData,
  sendSiteEmail,
  listSiteEmail,
  copyAWRRule,
  siteSendSMS,
  listSitePhoneNumber,
  viewSiteLocation,
  SiteDaysOfWeek,
  addSiteGeneralDetails,
  siteDefaultShiftType,
  addContactDetails,
  getDocumentType,
  addSiteDocument,
  getSupplierList,
  addBookingRule,
  saveAwrDetails,
  bookRuleRequirementType,
  saveBookingRule,
  defaultAWRRule,
  updateSiteContact,
  getGeneralDetails,
  updateGeneralDetails,
  getSiteContactDetails,
  deleteAWRRule,
  getBookingRule,
  saveResourceRequirements,
  getResourceRequirements,
  updateResourceRequirements,
  deleteResourceRequirements,
  getSitePipelineCandidates,
  getSiteCandidatePerformance,
  getSiteInfringement,
  getSiteDocument,
  deleteSiteDocument,
  updateSiteInfringementInfo,
  listReports,
  getReportInfo,
  getClientCandidateHistory,
  clientCandidateEmail
});
