import { combineReducers } from "redux";

import actions from "../actionTypes/supplierTypes";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const supplierListInfo = createReducer(defaultState, {
  [actions.GET_SUPPLIER_LIST]: isLoadingStateReducer,
  [actions.GET_SUPPLIER_LIST_SUCCESS]: successStateReducer,
  [actions.GET_SUPPLIER_LIST_ERROR]: errorStateReducer,
  [actions.GET_SUPPLIER_LIST_CLEAR]: resetStateReducer
});

const addSupplierInfo = createReducer(defaultState, {
  [actions.ADD_SUPPLIER]: isLoadingStateReducer,
  [actions.ADD_SUPPLIER_SUCCESS]: successStateReducer,
  [actions.ADD_SUPPLIER_ERROR]: errorStateReducer,
  [actions.ADD_SUPPLIER_CLEAR]: resetStateReducer
});

const updateSupplierInfo = createReducer(defaultState, {
  [actions.UPDATE_SUPPLIER]: isLoadingStateReducer,
  [actions.UPDATE_SUPPLIER_SUCCESS]: successStateReducer,
  [actions.UPDATE_SUPPLIER_ERROR]: errorStateReducer,
  [actions.UPDATE_SUPPLIER_CLEAR]: resetStateReducer
});

const getSupplierEmailTemplate = createReducer(defaultState, {
  [actions.GET_SUPPLIER_EMAIL_TEMPLATE]: isLoadingStateReducer,
  [actions.GET_SUPPLIER_EMAIL_TEMPLATE_SUCCESS]: successStateReducer,
  [actions.GET_SUPPLIER_EMAIL_TEMPLATE_ERROR]: errorStateReducer,
  [actions.GET_SUPPLIER_EMAIL_TEMPLATE_CLEAR]: resetStateReducer
});

const deleteSupplierInfo = createReducer(defaultState, {
  [actions.DELETE_SUPPLIER]: isLoadingStateReducer,
  [actions.DELETE_SUPPLIER_SUCCESS]: successStateReducer,
  [actions.DELETE_SUPPLIER_ERROR]: errorStateReducer,
  [actions.DELETE_SUPPLIER_CLEAR]: resetStateReducer
});

const addStsCandidateInfo = createReducer(defaultState, {
  [actions.ADD_STS_CANDIDATE]: isLoadingStateReducer,
  [actions.ADD_STS_CANDIDATE_SUCCESS]: successStateReducer,
  [actions.ADD_STS_CANDIDATE_ERROR]: errorStateReducer,
  [actions.ADD_STS_CANDIDATE_CLEAR]: resetStateReducer
});

const getListSupplierCandidatesPaged = createReducer(defaultState, {
  [actions.GET_LIST_SUPPLIER_CANDIDATES_PAGED]: isLoadingStateReducer,
  [actions.GET_LIST_SUPPLIER_CANDIDATES_PAGED_SUCCESS]: successStateReducer,
  [actions.GET_LIST_SUPPLIER_CANDIDATES_PAGED_ERROR]: errorStateReducer,
  [actions.GET_LIST_SUPPLIER_CANDIDATES_PAGED_CLEAR]: resetStateReducer
});

const sendSupplierEmail = createReducer(defaultState, {
  [actions.SEND_SUPPLIER_EMAIL]: isLoadingStateReducer,
  [actions.SEND_SUPPLIER_EMAIL_SUCCESS]: successStateReducer,
  [actions.SEND_SUPPLIER_EMAIL_ERROR]: errorStateReducer,
  [actions.SEND_SUPPLIER_EMAIL_CLEAR]: resetStateReducer
});

const supplierClientApproval = createReducer(defaultState, {
  [actions.SUPPLIER_CLIENT_APPROVAL]: isLoadingStateReducer,
  [actions.SUPPLIER_CLIENT_APPROVAL_SUCCESS]: successStateReducer,
  [actions.SUPPLIER_CLIENT_APPROVAL_ERROR]: errorStateReducer,
  [actions.SUPPLIER_CLIENT_APPROVAL_CLEAR]: resetStateReducer
});

export default combineReducers({
  getListSupplierCandidatesPaged,
  supplierClientApproval,
  sendSupplierEmail,
  getSupplierEmailTemplate,
  supplierListInfo,
  addSupplierInfo,
  updateSupplierInfo,
  deleteSupplierInfo,
  addStsCandidateInfo
});
