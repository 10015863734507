import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes";
import {
  getCandidatesList,
  changeCandidateStatus,
  getCandidatesListPaged,
  getCandidatesInviteApp,
  getAssignCandidateList,
  getSingleAssignCandidateList,
  getWorkingTimeSummary,
  getAllCandidatesTypeList,
  getClientList,
  getSiteList,
  getDriverCalendarEvents,
  getSupplierCandidateList,
  getAddBooking,
  getRibbonData,
  inviteESignCandidates,
  addCandidateImport,
  addHoursWorkedImport,
  updatePersonNote
} from "../workers/candidateWorker";

export function* watchChangeCandidateStatus() {
  yield takeLatest(actions.CHANGE_CANDIDATE_STATUS, changeCandidateStatus);
}

export function* watchGetCandidateList() {
  yield takeLatest(actions.CANDIDATE_LISTING, getCandidatesList);
}

export function* watchGetCandidateListPaged() {
  yield takeLatest(actions.CANDIDATE_LISTING_PAGED, getCandidatesListPaged);
}

export function* watchGetCandidateInviteApp() {
  yield takeLatest(actions.CANDIDATE_INVITE_APP, getCandidatesInviteApp);
}

export function* watchInviteESignCandidates() {
  yield takeLatest(actions.INVITE_E_SIGN_CANDIDATES, inviteESignCandidates);
}

export function* watchGetCandidateTypeList() {
  yield takeLatest(actions.CANDIDATE_TYPE, getAllCandidatesTypeList);
}

export function* watchGetClientList() {
  yield takeLatest(actions.CLIENT_LIST, getClientList);
}

export function* watchGetAssignCandidateList() {
  yield takeLatest(actions.CANDIDATE_ASSIGN_LIST, getAssignCandidateList);
}

export function* watchGetSingleAssignCandidateList() {
  yield takeLatest(actions.SINGLE_CANDIDATE_ASSIGN_LIST, getSingleAssignCandidateList);
}

export function* watchAddCandidateImport() {
  yield takeLatest(actions.ADD_CANDIDATE_IMPORT, addCandidateImport);
}

export function* watchAddHoursWorkedImport() {
  yield takeLatest(actions.HOURS_WORKED_IMPORT, addHoursWorkedImport);
}

export function* watchUpdatePersonNote() {
  yield takeLatest(actions.UPDATE_PERSON_NOTE, updatePersonNote);
}

export function* watchGetSupplierCandidateList() {
  yield takeLatest(actions.SUPPLIER_CANDIDATE_LIST, getSupplierCandidateList);
}
export function* watchGetWorkingTimeSummary() {
  yield takeLatest(actions.CANDIDATE_WORKING_TIME, getWorkingTimeSummary);
}

export function* watchGetSiteList() {
  yield takeLatest(actions.SITE_LIST, getSiteList);
}

export function* watchAddBooking() {
  yield takeLatest(actions.ADD_BOOKING, getAddBooking);
}

export function* watchDriverCalendarEvents() {
  yield takeLatest(actions.DRIVER_CALENDAR, getDriverCalendarEvents);
}

export function* watchRibbonData() {
  yield takeLatest(actions.RIBBON_DATA, getRibbonData);
}
