import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes/supplierTypes";
import {
  supplierList,
  addSupplier,
  updateSupplier,
  deleteSupplier,
  addStsCandidate,
  getListSupplierCandidatesPaged,
  getSupplierEmailTemplate,
  sendSupplierEmail,
  saveSupplierClientApproval
} from "../workers/supplierWorker";

export function* watchSupplierList() {
  yield takeLatest(actions.GET_SUPPLIER_LIST, supplierList);
}

export function* watchAddSupplier() {
  yield takeLatest(actions.ADD_SUPPLIER, addSupplier);
}

export function* watchUpdateSupplier() {
  yield takeLatest(actions.UPDATE_SUPPLIER, updateSupplier);
}

export function* watchGetSupplierEmailTemplate() {
  yield takeLatest(actions.GET_SUPPLIER_EMAIL_TEMPLATE, getSupplierEmailTemplate);
}

export function* watchDeleteSupplier() {
  yield takeLatest(actions.DELETE_SUPPLIER, deleteSupplier);
}

export function* watchAddStsCandidate() {
  yield takeLatest(actions.ADD_STS_CANDIDATE, addStsCandidate);
}

export function* watchGetListSupplierCandidatesPaged() {
  yield takeLatest(actions.GET_LIST_SUPPLIER_CANDIDATES_PAGED, getListSupplierCandidatesPaged);
}

export function* watchSendSupplierEmail() {
  yield takeLatest(actions.SEND_SUPPLIER_EMAIL, sendSupplierEmail);
}

export function* watchSaveSupplierClientApproval() {
  yield takeLatest(actions.SUPPLIER_CLIENT_APPROVAL, saveSupplierClientApproval);
}
