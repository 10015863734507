import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes";
import {
  getAllLeadSourceList,
  getAllInsuranceList,
  getAllNationalityList,
  addNewCandidate,
  addNewContactAddress,
  addNewAdditionalAddress,
  addMultipleDocuments,
  addLicenseInformation,
  getDrivingLicenseCapabilityList,
  getDrivingLicenseEndorsementList,
  getDrivingLicenseCapabilityTypesList,
  getDrivingLicenseEndorsementTypesList,
  addInductionsBansInformation,
  getAllQualificationTitleList,
  getAllIdentificationNameList,
  addQualificationInformation,
  addLicenceInformation,
  getAllPaymentTierList,
  getAllSupplierList,
  getAllServiceCompanyList,
  getAllContactTypeList,
  getAllExpenseTypeList,
  addPayrollInformation,
  addPreferencesInformation,
  addWorkHistoryDetails,
  getSatisfactionLevelLists,
  getReasonsLists,
  getNonConversionLists,
  addHumanResourceInformation,
  getCandidateRatingTypeLists,
  addCandidateRatingInfo,
  getCandidateInfringementsTypeLists,
  addCandidateInfringementsInfo,
  getHolidaysLists,
  getNotesLists,
  addNotesActivityInfo,
  getStatementLists,
  getCandidatePerformanceTypesLists,
  addPerformanceActivityInfo,
  getTasksLists,
  addTasksActivityInfo,
  getSmsHistoryLists,
  SendSMSInfo,
  getEmailHistoryLists,
  AddSmartBoxActivityInfo,
  getRatingsDetails,
  getInfringementsDetails,
  getPerformanceDetails,
  updateCandidateRatingInfo,
  updateCandidateInfringementInfo,
  updateCandidatePerformanceInfo,
  updateCandidateNotesInfo,
  getSmartBoxDetailsInfo,
  updateCandidateSmartBoxInfo,
  getSearchUserListInfo,
  deleteCandidateRatingInfo,
  deleteCandidateInfringementsInfo,
  deleteCandidateNotesInfo,
  deleteCandidateTicketsInfo,
  deleteCandidatePerformanceInfo,
  updateCandidateTicketsInfo,
  getTagsLists,
  addTagsListDetailsInfo,
  candidateDetailsInfo,
  updateCandidateDetails,
  getContactDetailsInfo,
  updateContactDetails,
  getAdditionalContacts,
  updateAdditionalContacts,
  getWorkHistoryList,
  getStartEndDates,
  deleteAddressInfo,
  updateWorkHistory,
  getInductionAndBanDetails,
  updateInductionAndBan,
  deleteInductionDetails,
  deleteBanDetails,
  getPersonTimePreference,
  getPayrollInformation,
  updatePayrollInformation,
  addDrivingLicense,
  getDrivingLicense,
  getHumanResource,
  updateHumanResource,
  addLicenceCapabilities,
  updateLicenceCapabilities,
  addDrivingLicenseEndorsement,
  updateDrivingLicenseEndorsement,
  deleteDrivingLicenseEndorsement,
  deleteWorkHistory,
  deleteStartEndDate,
  deleteDrivingLicenseCapabilities,
  addDigiCard,
  deleteDigiCard,
  updateDigiCard,
  getDigiCard,
  getQualificationIdentification,
  deleteQualificationIdentification,
  updateQualificationIdentification,
  getRemindersLists,
  deleteReminder,
  getAssignedTickets,
  getPayeStatementList,
  deleteUserTags,
  listAttachments,
  downloadDocument,
  deleteMultiDocument,
  deleteSmartBoxMessage,
  refreshMetaCache,
  sendAppMessage,
  getAppHistory,
  appPersonRegister,
  detailPersonSearch,
  sendBulkSMS,
  sendSMSToPhoneNumber,
  sendAvailabilityRequest,
  sendCalendarUpdated,
  sendNoWorkTomorrow,
  sendCalendarSummary,
  sendBookingDetails,
  sendBookingHoursQuery,
  getCandidateLinks,
  getAccruedHolidayAdjustmentsList,
  getHolidayShiftsAdjustmentsList,
  addAccruedHolidayAdjustments,
  addHolidayShiftsAdjustments,
  deleteAccruedHolidayAdjustments,
  deleteHolidayShiftsAdjustments,
  getRtwCheck,
  getHolidayPeriod,
  createDetailedBookings,
  getCandidateDefaultExpense,
  candidateClientDefaultExpense,
  setPassword,
  getAppDetails,
  appClaimID,
  clearAppProfile,
  addPersonToLBapi,
  getTagsListsV2,
  isCandidateSts,
  updateStartEndDate
} from "../workers/driverWorker";

export function* watchGetLeadSourceList() {
  yield takeLatest(actions.LEAD_SOURCE_LIST, getAllLeadSourceList);
}

export function* watchGetInsuranceList() {
  yield takeLatest(actions.INSURANCE_LIST, getAllInsuranceList);
}

export function* watchGetNationalityList() {
  yield takeLatest(actions.NATIONALITY_LIST, getAllNationalityList);
}

export function* watchAddNewCandidate() {
  yield takeLatest(actions.ADD_NEW_CANDIDATE, addNewCandidate);
}

export function* watchAddNewAddress() {
  yield takeLatest(actions.ADD_NEW_ADDRESS, addNewContactAddress);
}

export function* watchAddNewAdditionalAddress() {
  yield takeLatest(actions.ADD_NEW_ADDITIONAL_ADDRESS, addNewAdditionalAddress);
}

export function* watchAddMultipleDocuments() {
  yield takeLatest(actions.ADD_MULTIPLE_DOCUMENTS, addMultipleDocuments);
}

export function* watchAddLicenseInformation() {
  yield takeLatest(actions.ADD_LICENSE_INFORMATION, addLicenseInformation);
}

export function* watchGetDrivingLicenseCapabilityList() {
  yield takeLatest(actions.DRIVING_LICENSE_CAPABILITY_LIST, getDrivingLicenseCapabilityList);
}

export function* watchGetDrivingLicenseEndorsementList() {
  yield takeLatest(actions.DRIVING_LICENSE_ENDORSEMENT_LIST, getDrivingLicenseEndorsementList);
}

export function* watchGetDrivingLicenseCapabilityTypesList() {
  yield takeLatest(
    actions.DRIVING_LICENSE_CAPABILITY_TYPES_LIST,
    getDrivingLicenseCapabilityTypesList
  );
}

export function* watchGetDrivingLicenseEndorsementTypesList() {
  yield takeLatest(
    actions.DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST,
    getDrivingLicenseEndorsementTypesList
  );
}

export function* watchAddInductionsBansInformation() {
  yield takeLatest(actions.ADD_INDUCTIONS_BANS_DETAILS, addInductionsBansInformation);
}

export function* watchGetAllQualificationTitleList() {
  yield takeLatest(actions.QUALIFICATION_TITLE_LIST, getAllQualificationTitleList);
}

export function* watchGetAllIdentificationNameList() {
  yield takeLatest(actions.QUALIFICATION_NAME_LIST, getAllIdentificationNameList);
}

export function* watchAddQualificationInformation() {
  yield takeLatest(actions.ADD_QUALIFICATION_DETAILS, addQualificationInformation);
}

export function* watchAddLicenceInformation() {
  yield takeLatest(actions.ADD_LICENCE_ADDITIONAL_DETAILS, addLicenceInformation);
}

export function* watchGetAllPaymentTierList() {
  yield takeLatest(actions.PAYMENT_TIER_LIST, getAllPaymentTierList);
}

export function* watchGetAllSupplierList() {
  yield takeLatest(actions.SUPPLIER_LIST, getAllSupplierList);
}

export function* watchGetAllServiceCompanyList() {
  yield takeLatest(actions.SERVICE_COMPANY_LIST, getAllServiceCompanyList);
}

export function* watchGetAllContactTypeList() {
  yield takeLatest(actions.CONTACT_TYPE_LIST, getAllContactTypeList);
}

export function* watchGetAllExpenseTypeList() {
  yield takeLatest(actions.EXPENSE_TYPE_LIST, getAllExpenseTypeList);
}

export function* watchAddPayrollInformation() {
  yield takeLatest(actions.ADD_PAYROLL_DETAILS, addPayrollInformation);
}

export function* watchAddPreferencesInformation() {
  yield takeLatest(actions.ADD_PREFERENCE_DETAILS, addPreferencesInformation);
}

export function* watchAddWorkHistoryDetails() {
  yield takeLatest(actions.ADD_WORK_HISTORY, addWorkHistoryDetails);
}

export function* watchGetSatisfactionLevelList() {
  yield takeLatest(actions.SATISFACTION_LEVEL_LIST, getSatisfactionLevelLists);
}

export function* watchGetReasonsLists() {
  yield takeLatest(actions.REASONS_LIST, getReasonsLists);
}

export function* watchGetNonConversionLists() {
  yield takeLatest(actions.NON_CONVERSION_LIST, getNonConversionLists);
}

export function* watchAddHumanResourceInformation() {
  yield takeLatest(actions.ADD_HUMAN_RESOURCE, addHumanResourceInformation);
}

export function* watchGetCandidateRatingTypeLists() {
  yield takeLatest(actions.RATING_TYPE_LIST, getCandidateRatingTypeLists);
}

export function* watchAddCandidateRatingInfo() {
  yield takeLatest(actions.ADD_CANDIDATE_RATINGS, addCandidateRatingInfo);
}

export function* watchGetCandidateInfringementsTypeLists() {
  yield takeLatest(actions.INFRINGEMENTS_TYPE_LIST, getCandidateInfringementsTypeLists);
}

export function* watchAddCandidateInfringementsInfo() {
  yield takeLatest(actions.ADD_CANDIDATE_INFRINGEMENTS, addCandidateInfringementsInfo);
}

export function* watchGetHolidaysLists() {
  yield takeLatest(actions.HOLIDAYS_LIST, getHolidaysLists);
}

export function* watchGetNotesLists() {
  yield takeLatest(actions.NOTES_LIST, getNotesLists);
}

export function* watchAddNotesActivityInfo() {
  yield takeLatest(actions.ADD_NOTES_ACTIVITY, addNotesActivityInfo);
}

export function* watchGetStatementLists() {
  yield takeLatest(actions.STATEMENT_OPTIONS_LIST, getStatementLists);
}

export function* watchCandidatePerformanceTypesLists() {
  yield takeLatest(actions.PERFORMANCE_TYPE_LIST, getCandidatePerformanceTypesLists);
}

export function* watchAddPerformanceActivityInfo() {
  yield takeLatest(actions.ADD_PERFORMANCE_ACTIVITY, addPerformanceActivityInfo);
}

export function* watchGetTasksLists() {
  yield takeLatest(actions.TASKS_LIST, getTasksLists);
}

export function* watchAddTasksActivityInfo() {
  yield takeLatest(actions.ADD_TASKS_ACTIVITY, addTasksActivityInfo);
}

export function* watchGetSmsHistoryLists() {
  yield takeLatest(actions.SMS_HISTORY_LIST, getSmsHistoryLists);
}

export function* watchSendSMSInfo() {
  yield takeLatest(actions.SEND_SMS, SendSMSInfo);
}

export function* watchGetEmailHistoryLists() {
  yield takeLatest(actions.EMAIL_HISTORY_LIST, getEmailHistoryLists);
}

export function* watchAddSmartBoxActivityInfo() {
  yield takeLatest(actions.ADD_SMART_BOX_ACTIVITY, AddSmartBoxActivityInfo);
}

export function* watchGetRatingsDetails() {
  yield takeLatest(actions.RATING_DETAILS_LIST, getRatingsDetails);
}

export function* watchGetInfringementsDetails() {
  yield takeLatest(actions.INFRINGEMENTS_DETAILS_LIST, getInfringementsDetails);
}

export function* watchGetPerformanceDetails() {
  yield takeLatest(actions.PERFORMANCE_DETAILS_LIST, getPerformanceDetails);
}

export function* watchGetSmartBoxDetailsInfo() {
  yield takeLatest(actions.SMART_BOX_DETAILS_LIST, getSmartBoxDetailsInfo);
}

export function* watchUpdateCandidateRatingInfo() {
  yield takeLatest(actions.UPDATE_CANDIDATE_RATING, updateCandidateRatingInfo);
}

export function* watchUpdateCandidateInfringementInfo() {
  yield takeLatest(actions.UPDATE_CANDIDATE_INFRINGEMENT, updateCandidateInfringementInfo);
}

export function* watchUpdateCandidatePerformanceInfo() {
  yield takeLatest(actions.UPDATE_CANDIDATE_PERFORMANCE, updateCandidatePerformanceInfo);
}

export function* watchUpdateCandidateNotesInfo() {
  yield takeLatest(actions.UPDATE_CANDIDATE_NOTES, updateCandidateNotesInfo);
}

export function* watchUpdateCandidateSmartBoxInfo() {
  yield takeLatest(actions.UPDATE_CANDIDATE_SMART_BOX, updateCandidateSmartBoxInfo);
}

export function* watchGetSearchUserListInfo() {
  yield takeLatest(actions.SEARCH_USER_LIST, getSearchUserListInfo);
}

export function* watchDeleteCandidateRatingInfo() {
  yield takeLatest(actions.DELETE_CANDIDATE_RATING, deleteCandidateRatingInfo);
}

export function* watchDeleteCandidateInfringementsInfo() {
  yield takeLatest(actions.DELETE_CANDIDATE_INFRINGEMENTS, deleteCandidateInfringementsInfo);
}

export function* watchDeleteCandidateNotesInfo() {
  yield takeLatest(actions.DELETE_CANDIDATE_NOTES, deleteCandidateNotesInfo);
}

export function* watchDeleteCandidateTicketsInfo() {
  yield takeLatest(actions.DELETE_CANDIDATE_TICKETS, deleteCandidateTicketsInfo);
}

export function* watchDeleteCandidatePerformanceInfo() {
  yield takeLatest(actions.DELETE_CANDIDATE_PERFORMANCE, deleteCandidatePerformanceInfo);
}

export function* watchUpdateCandidateTicketsInfo() {
  yield takeLatest(actions.UPDATE_CANDIDATE_TICKETS, updateCandidateTicketsInfo);
}

export function* watchGetTagsListsInfo() {
  yield takeLatest(actions.TAGS_LIST, getTagsLists);
}

export function* watchGetTagsListsV2Info() {
  yield takeLatest(actions.TAGS_LIST_V2, getTagsListsV2);
}

export function* watchAddTagsListDetailsInfo() {
  yield takeLatest(actions.ADD_TAGS_LIST, addTagsListDetailsInfo);
}

export function* watchCandidateDetailsInfo() {
  yield takeLatest(actions.CANDIDATE_DETAILS, candidateDetailsInfo);
}

export function* watchUpdateCandidateDetails() {
  yield takeLatest(actions.UPDATE_CANDIDATE_DETAILS, updateCandidateDetails);
}

export function* watchGetContactDetailsInfo() {
  yield takeLatest(actions.CONTACT_DETAILS, getContactDetailsInfo);
}

export function* watchUpdateContactDetails() {
  yield takeLatest(actions.UPDATE_CONTACT_DETAILS, updateContactDetails);
}

export function* watchGetAdditionalContacts() {
  yield takeLatest(actions.ADDITIONAL_CONTACT_DETAILS, getAdditionalContacts);
}

export function* watchUpdateAdditionalContacts() {
  yield takeLatest(actions.UPDATE_ADDITIONAL_CONTACT, updateAdditionalContacts);
}

export function* watchGetWorkHistoryList() {
  yield takeLatest(actions.GET_WORK_HISTORY_DETAILS, getWorkHistoryList);
}

export function* watchGetStartEndDates() {
  yield takeLatest(actions.GET_START_END_DATES, getStartEndDates);
}

export function* watchDeleteAddressInfo() {
  yield takeLatest(actions.DELETE_ADDRESS_DETAILS, deleteAddressInfo);
}

export function* watchUpdateWorkHistory() {
  yield takeLatest(actions.UPDATE_WORK_HISTORY, updateWorkHistory);
}

export function* watchGetInductionAndBanDetails() {
  yield takeLatest(actions.GET_INDUCTIONS_BANS_LIST, getInductionAndBanDetails);
}

export function* watchUpdateInductionAndBan() {
  yield takeLatest(actions.UPDATE_INDUCTIONS_BANS, updateInductionAndBan);
}

export function* watchDeleteInductionDetails() {
  yield takeLatest(actions.DELETE_INDUCTIONS_DETAILS, deleteInductionDetails);
}

export function* watchDeleteBanDetails() {
  yield takeLatest(actions.DELETE_BANS_DETAILS, deleteBanDetails);
}

export function* watchGetPersonTimePreference() {
  yield takeLatest(actions.GET_TIME_PREFERENCE, getPersonTimePreference);
}

export function* watchGetPayrollInformation() {
  yield takeLatest(actions.GET_PAYROLL_DETAILS, getPayrollInformation);
}

export function* watchGetIsCandidateSts() {
  yield takeLatest(actions.GET_IS_CANDIDATE_STS, isCandidateSts);
}

export function* watchUpdatePayrollInformation() {
  yield takeLatest(actions.UPDATE_PAYROLL_DETAILS, updatePayrollInformation);
}

export function* watchAddDrivingLicense() {
  yield takeLatest(actions.ADD_DRIVING_LICENSE, addDrivingLicense);
}

export function* watchGetDrivingLicense() {
  yield takeLatest(actions.GET_DRIVING_LICENSE, getDrivingLicense);
}

export function* watchGetHumanResource() {
  yield takeLatest(actions.GET_HUMAN_RESOURCE, getHumanResource);
}

export function* watchUpdateHumanResource() {
  yield takeLatest(actions.UPDATE_HUMAN_RESOURCE, updateHumanResource);
}

export function* watchAddLicenceCapabilities() {
  yield takeLatest(actions.ADD_LICENCE_CAPABILITIES, addLicenceCapabilities);
}

export function* watchUpdateLicenceCapabilities() {
  yield takeLatest(actions.UPDATE_LICENCE_CAPABILITIES, updateLicenceCapabilities);
}

export function* watchAddDrivingLicenseEndorsement() {
  yield takeLatest(actions.ADD_LICENCE_ENDORSEMENTS, addDrivingLicenseEndorsement);
}

export function* watchUpdateDrivingLicenseEndorsement() {
  yield takeLatest(actions.UPDATE_LICENCE_ENDORSEMENTS, updateDrivingLicenseEndorsement);
}

export function* watchDeleteDrivingLicenseEndorsement() {
  yield takeLatest(actions.DELETE_LICENCE_ENDORSEMENTS, deleteDrivingLicenseEndorsement);
}

export function* watchDeleteWorkHistory() {
  yield takeLatest(actions.DELETE_WORK_HISTORY, deleteWorkHistory);
}

export function* watchDeleteStartEndDate() {
  yield takeLatest(actions.DELETE_START_END_DATE, deleteStartEndDate);
}

export function* watchUpdateStartEndDate() {
  yield takeLatest(actions.UPDATE_START_END_DATE, updateStartEndDate);
}

export function* watchDeleteDrivingLicenseCapabilities() {
  yield takeLatest(actions.DELETE_LICENCE_CAPABILITIES, deleteDrivingLicenseCapabilities);
}

export function* watchAddDigiCard() {
  yield takeLatest(actions.ADD_DIGI_CARD, addDigiCard);
}

export function* watchDeleteDigiCard() {
  yield takeLatest(actions.DELETE_DIGI_CARD, deleteDigiCard);
}

export function* watchUpdateDigiCard() {
  yield takeLatest(actions.UPDATE_DIGI_CARD, updateDigiCard);
}

export function* watchGetDigiCard() {
  yield takeLatest(actions.GET_DIGI_CARD, getDigiCard);
}

export function* watchGetQualificationIdentification() {
  yield takeLatest(actions.GET_QUALIFICATION_DETAILS, getQualificationIdentification);
}

export function* watchDeleteQualificationIdentification() {
  yield takeLatest(actions.DELETE_QUALIFICATION_DETAILS, deleteQualificationIdentification);
}

export function* watchUpdateQualificationIdentification() {
  yield takeLatest(actions.UPDATE_QUALIFICATION_DETAILS, updateQualificationIdentification);
}

export function* watchGetRemindersLists() {
  yield takeLatest(actions.REMINDERS_LIST, getRemindersLists);
}

export function* watchDeleteReminder() {
  yield takeLatest(actions.DELETE_REMINDER, deleteReminder);
}

export function* watchGetAssignedTickets() {
  yield takeLatest(actions.GET_ASSIGNED_TICKETS, getAssignedTickets);
}

export function* watchGetPayeStatementList() {
  yield takeLatest(actions.GET_PAYE_LIST, getPayeStatementList);
}

export function* watchDeleteUserTags() {
  yield takeLatest(actions.DELETE_USER_TAGS, deleteUserTags);
}

export function* watchListAttachments() {
  yield takeLatest(actions.LIST_ATTACHMENTS, listAttachments);
}

export function* watchDownloadDocument() {
  yield takeLatest(actions.DOWNLOAD_DOCUMENT, downloadDocument);
}

export function* watchDeleteMultiDocument() {
  yield takeLatest(actions.DELETE_DOCUMENT, deleteMultiDocument);
}

export function* watchDeleteSmartBoxMessage() {
  yield takeLatest(actions.DELETE_SMART_BOX, deleteSmartBoxMessage);
}

export function* watchRefreshMetaCache() {
  yield takeLatest(actions.REFRESH_META_CACHE, refreshMetaCache);
}

export function* watchSendAppMessage() {
  yield takeLatest(actions.SEND_APP_SMS, sendAppMessage);
}

export function* watchGetAppHistory() {
  yield takeLatest(actions.APP_HISTORY_LIST, getAppHistory);
}

export function* watchAppPersonRegister() {
  yield takeLatest(actions.REGISTER_APP_PERSON, appPersonRegister);
}

export function* watchDetailPersonSearch() {
  yield takeLatest(actions.DETAIL_PERSON_SEARCH, detailPersonSearch);
}

export function* watchSendBulkSMS() {
  yield takeLatest(actions.SEND_BULK_SMS, sendBulkSMS);
}

export function* watchSendSMSToPhoneNumber() {
  yield takeLatest(actions.SEND_SMS_TO_PHONE_NUMBER, sendSMSToPhoneNumber);
}

export function* watchSendAvailabilityRequest() {
  yield takeLatest(actions.SEND_AVAILABILITY_REQUEST, sendAvailabilityRequest);
}

export function* watchSendCalendarUpdated() {
  yield takeLatest(actions.SEND_CALENDAR_UPDATED, sendCalendarUpdated);
}

export function* watchSendNoWorkTomorrow() {
  yield takeLatest(actions.SEND_NO_WORK_TOMORROW, sendNoWorkTomorrow);
}

export function* watchSendCalendarSummary() {
  yield takeLatest(actions.SEND_CALENDAR_SUMMARY, sendCalendarSummary);
}

export function* watchSendBookingDetails() {
  yield takeLatest(actions.SEND_BOOKING_DETAILS, sendBookingDetails);
}

export function* watchSendBookingHoursQuery() {
  yield takeLatest(actions.SEND_BOOKING_HRS, sendBookingHoursQuery);
}

export function* watchGetCandidateLinks() {
  yield takeLatest(actions.GET_CANDIDATE_LINKS, getCandidateLinks);
}

export function* watchGetAccruedHolidayAdjustmentsList() {
  yield takeLatest(actions.HOLIDAYS_ADJUSTMENT_LIST, getAccruedHolidayAdjustmentsList);
}

export function* watchGetHolidayShiftsAdjustmentsList() {
  yield takeLatest(actions.HOLIDAYS_SHIFT_LIST, getHolidayShiftsAdjustmentsList);
}

export function* watchAddAccruedHolidayAdjustments() {
  yield takeLatest(actions.ADD_HOLIDAYS_ADJUSTMENT_LIST, addAccruedHolidayAdjustments);
}

export function* watchAddHolidayShiftsAdjustments() {
  yield takeLatest(actions.ADD_HOLIDAYS_SHIFT_LIST, addHolidayShiftsAdjustments);
}

export function* watchDeleteAccruedHolidayAdjustments() {
  yield takeLatest(actions.DELETE_HOLIDAYS_ADJUSTMENT_LIST, deleteAccruedHolidayAdjustments);
}

export function* watchDeleteHolidayShiftsAdjustments() {
  yield takeLatest(actions.DELETE_HOLIDAYS_SHIFT_LIST, deleteHolidayShiftsAdjustments);
}

export function* watchGetRtwChecks() {
  yield takeLatest(actions.REQUEST_RTW_CHECK, getRtwCheck);
}

export function* watchGetHolidayPeriod() {
  yield takeLatest(actions.HOLIDAY_PERIOD, getHolidayPeriod);
}

export function* watchCreateDetailedBookings() {
  yield takeLatest(actions.DETAILED_BOOKINGS, createDetailedBookings);
}

export function* watchGetCandidateDefaultExpense() {
  yield takeLatest(actions.CANDIDATE_DEFAULT_EXPENSE, getCandidateDefaultExpense);
}

export function* watchCandidateClientDefaultExpense() {
  yield takeLatest(actions.ACTION_CANDIDATE_DEFAULT_EXPENSE, candidateClientDefaultExpense);
}

export function* watchSetPassword() {
  yield takeLatest(actions.PASSWORD_SETUP, setPassword);
}

export function* watchGetAppDetails() {
  yield takeLatest(actions.GET_APP_DETAILS, getAppDetails);
}

export function* watchClaimProfileID() {
  yield takeLatest(actions.CLAIM_APP_ID, appClaimID);
}

export function* watchClearAppProfile() {
  yield takeLatest(actions.CLEAR_APP_PROFILE, clearAppProfile);
}

export function* watchAddPersonToLBAPI() {
  yield takeLatest(actions.ADD_PERSON_TO_LBAPI, addPersonToLBapi);
}
