import React from "react";
import { Alert } from "antd";

import "./authBg.scss";

const AlertMessage = ({ msg, type = "error" }) => {
  return (
    <div className="alert-main-div">
      {msg && <Alert message={msg} type={type} showIcon className="alert-div" />}
    </div>
  );
};

export default AlertMessage;
