import { combineReducers } from "redux";

import actions from "../actionTypes";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const getLeadSourceList = createReducer(defaultState, {
  [actions.LEAD_SOURCE_LIST]: isLoadingStateReducer,
  [actions.LEAD_SOURCE_LIST_SUCCESS]: successStateReducer,
  [actions.LEAD_SOURCE_LIST_ERROR]: errorStateReducer,
  [actions.LEAD_SOURCE_LIST_CLEAR]: resetStateReducer
});

const addPersonToLBAPI = createReducer(defaultState, {
  [actions.ADD_PERSON_TO_LBAPI]: isLoadingStateReducer,
  [actions.ADD_PERSON_TO_LBAPI_SUCCESS]: successStateReducer,
  [actions.ADD_PERSON_TO_LBAPI_ERROR]: errorStateReducer,
  [actions.ADD_PERSON_TO_LBAPI_CLEAR]: resetStateReducer
});

const getInsuranceList = createReducer(defaultState, {
  [actions.INSURANCE_LIST]: isLoadingStateReducer,
  [actions.INSURANCE_LIST_SUCCESS]: successStateReducer,
  [actions.INSURANCE_LIST_ERROR]: errorStateReducer,
  [actions.INSURANCE_LIST_CLEAR]: resetStateReducer
});

const getNationalityList = createReducer(defaultState, {
  [actions.NATIONALITY_LIST]: isLoadingStateReducer,
  [actions.NATIONALITY_LIST_SUCCESS]: successStateReducer,
  [actions.NATIONALITY_LIST_ERROR]: errorStateReducer,
  [actions.NATIONALITY_LIST_CLEAR]: resetStateReducer
});

const addNewCandidateList = createReducer(defaultState, {
  [actions.ADD_NEW_CANDIDATE]: isLoadingStateReducer,
  [actions.ADD_NEW_CANDIDATE_SUCCESS]: successStateReducer,
  [actions.ADD_NEW_CANDIDATE_ERROR]: errorStateReducer,
  [actions.ADD_NEW_CANDIDATE_CLEAR]: resetStateReducer
});

const addAddressList = createReducer(defaultState, {
  [actions.ADD_NEW_ADDRESS]: isLoadingStateReducer,
  [actions.ADD_NEW_ADDRESS_SUCCESS]: successStateReducer,
  [actions.ADD_NEW_ADDRESS_ERROR]: errorStateReducer,
  [actions.ADD_NEW_ADDRESS_CLEAR]: resetStateReducer
});

const addAdditionalAddressList = createReducer(defaultState, {
  [actions.ADD_NEW_ADDITIONAL_ADDRESS]: isLoadingStateReducer,
  [actions.ADD_NEW_ADDITIONAL_ADDRESS_SUCCESS]: successStateReducer,
  [actions.ADD_NEW_ADDITIONAL_ADDRESS_ERROR]: errorStateReducer,
  [actions.ADD_NEW_ADDITIONAL_ADDRESS_CLEAR]: resetStateReducer
});

const addMultipleDocumentsList = createReducer(defaultState, {
  [actions.ADD_MULTIPLE_DOCUMENTS]: isLoadingStateReducer,
  [actions.ADD_MULTIPLE_DOCUMENTS_SUCCESS]: successStateReducer,
  [actions.ADD_MULTIPLE_DOCUMENTS_ERROR]: errorStateReducer,
  [actions.ADD_MULTIPLE_DOCUMENTS_CLEAR]: resetStateReducer
});

const addLicenseInformationList = createReducer(defaultState, {
  [actions.ADD_LICENSE_INFORMATION]: isLoadingStateReducer,
  [actions.ADD_LICENSE_INFORMATION_SUCCESS]: successStateReducer,
  [actions.ADD_LICENSE_INFORMATION_ERROR]: errorStateReducer,
  [actions.ADD_LICENSE_INFORMATION_CLEAR]: resetStateReducer
});

const drivingLicenseCapabilityList = createReducer(defaultState, {
  [actions.DRIVING_LICENSE_CAPABILITY_LIST]: isLoadingStateReducer,
  [actions.DRIVING_LICENSE_CAPABILITY_LIST_SUCCESS]: successStateReducer,
  [actions.DRIVING_LICENSE_CAPABILITY_LIST_ERROR]: errorStateReducer,
  [actions.DRIVING_LICENSE_CAPABILITY_LIST_CLEAR]: resetStateReducer
});

const drivingLicenseEndorsementList = createReducer(defaultState, {
  [actions.DRIVING_LICENSE_ENDORSEMENT_LIST]: isLoadingStateReducer,
  [actions.DRIVING_LICENSE_ENDORSEMENT_LIST_SUCCESS]: successStateReducer,
  [actions.DRIVING_LICENSE_ENDORSEMENT_LIST_ERROR]: errorStateReducer,
  [actions.DRIVING_LICENSE_ENDORSEMENT_LIST_CLEAR]: resetStateReducer
});
const drivingLicenseCapabilityTypesList = createReducer(defaultState, {
  [actions.DRIVING_LICENSE_CAPABILITY_TYPES_LIST]: isLoadingStateReducer,
  [actions.DRIVING_LICENSE_CAPABILITY_TYPES_LIST_SUCCESS]: successStateReducer,
  [actions.DRIVING_LICENSE_CAPABILITY_TYPES_LIST_ERROR]: errorStateReducer,
  [actions.DRIVING_LICENSE_CAPABILITY_TYPES_LIST_CLEAR]: resetStateReducer
});

const drivingLicenseEndorsementTypesList = createReducer(defaultState, {
  [actions.DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST]: isLoadingStateReducer,
  [actions.DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST_SUCCESS]: successStateReducer,
  [actions.DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST_ERROR]: errorStateReducer,
  [actions.DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST_CLEAR]: resetStateReducer
});

const addInductionsBansList = createReducer(defaultState, {
  [actions.ADD_INDUCTIONS_BANS_DETAILS]: isLoadingStateReducer,
  [actions.ADD_INDUCTIONS_BANS_DETAILS_SUCCESS]: successStateReducer,
  [actions.ADD_INDUCTIONS_BANS_DETAILS_ERROR]: errorStateReducer,
  [actions.ADD_INDUCTIONS_BANS_DETAILS_CLEAR]: resetStateReducer
});

const getQualificationTitleList = createReducer(defaultState, {
  [actions.QUALIFICATION_TITLE_LIST]: isLoadingStateReducer,
  [actions.QUALIFICATION_TITLE_LIST_SUCCESS]: successStateReducer,
  [actions.QUALIFICATION_TITLE_LIST_ERROR]: errorStateReducer,
  [actions.QUALIFICATION_TITLE_LIST_CLEAR]: resetStateReducer
});

const getIdentificationNameList = createReducer(defaultState, {
  [actions.QUALIFICATION_NAME_LIST]: isLoadingStateReducer,
  [actions.QUALIFICATION_NAME_LIST_SUCCESS]: successStateReducer,
  [actions.QUALIFICATION_NAME_LIST_ERROR]: errorStateReducer,
  [actions.QUALIFICATION_NAME_LIST_CLEAR]: resetStateReducer
});

const addQualificationList = createReducer(defaultState, {
  [actions.ADD_QUALIFICATION_DETAILS]: isLoadingStateReducer,
  [actions.ADD_QUALIFICATION_DETAILS_SUCCESS]: successStateReducer,
  [actions.ADD_QUALIFICATION_DETAILS_ERROR]: errorStateReducer,
  [actions.ADD_QUALIFICATION_DETAILS_CLEAR]: resetStateReducer
});

const addLicenceDetailsList = createReducer(defaultState, {
  [actions.ADD_LICENCE_ADDITIONAL_DETAILS]: isLoadingStateReducer,
  [actions.ADD_LICENCE_ADDITIONAL_DETAILS_SUCCESS]: successStateReducer,
  [actions.ADD_LICENCE_ADDITIONAL_DETAILS_ERROR]: errorStateReducer,
  [actions.ADD_LICENCE_ADDITIONAL_DETAILS_CLEAR]: resetStateReducer
});

const getPaymentTierList = createReducer(defaultState, {
  [actions.PAYMENT_TIER_LIST]: isLoadingStateReducer,
  [actions.PAYMENT_TIER_LIST_SUCCESS]: successStateReducer,
  [actions.PAYMENT_TIER_LIST_ERROR]: errorStateReducer,
  [actions.PAYMENT_TIER_LIST_CLEAR]: resetStateReducer
});

const getSupplierList = createReducer(defaultState, {
  [actions.SUPPLIER_LIST]: isLoadingStateReducer,
  [actions.SUPPLIER_LIST_SUCCESS]: successStateReducer,
  [actions.SUPPLIER_LIST_ERROR]: errorStateReducer,
  [actions.SUPPLIER_LIST_CLEAR]: resetStateReducer
});

const getServiceCompanyList = createReducer(defaultState, {
  [actions.SERVICE_COMPANY_LIST]: isLoadingStateReducer,
  [actions.SERVICE_COMPANY_LIST_SUCCESS]: successStateReducer,
  [actions.SERVICE_COMPANY_LIST_ERROR]: errorStateReducer,
  [actions.SERVICE_COMPANY_LIST_CLEAR]: resetStateReducer
});

const getContactTypeList = createReducer(defaultState, {
  [actions.CONTACT_TYPE_LIST]: isLoadingStateReducer,
  [actions.CONTACT_TYPE_LIST_SUCCESS]: successStateReducer,
  [actions.CONTACT_TYPE_LIST_ERROR]: errorStateReducer,
  [actions.CONTACT_TYPE_LIST_CLEAR]: resetStateReducer
});

const getExpenseTypeList = createReducer(defaultState, {
  [actions.EXPENSE_TYPE_LIST]: isLoadingStateReducer,
  [actions.EXPENSE_TYPE_LIST_SUCCESS]: successStateReducer,
  [actions.EXPENSE_TYPE_LIST_ERROR]: errorStateReducer,
  [actions.EXPENSE_TYPE_LIST_CLEAR]: resetStateReducer
});

const addPayrollInformation = createReducer(defaultState, {
  [actions.ADD_PAYROLL_DETAILS]: isLoadingStateReducer,
  [actions.ADD_PAYROLL_DETAILS_SUCCESS]: successStateReducer,
  [actions.ADD_PAYROLL_DETAILS_ERROR]: errorStateReducer,
  [actions.ADD_PAYROLL_DETAILS_CLEAR]: resetStateReducer
});

const addPreferencesInformation = createReducer(defaultState, {
  [actions.ADD_PREFERENCE_DETAILS]: isLoadingStateReducer,
  [actions.ADD_PREFERENCE_DETAILS_SUCCESS]: successStateReducer,
  [actions.ADD_PREFERENCE_DETAILS_ERROR]: errorStateReducer,
  [actions.ADD_PREFERENCE_DETAILS_CLEAR]: resetStateReducer
});

const addWorkHistoryInformation = createReducer(defaultState, {
  [actions.ADD_WORK_HISTORY]: isLoadingStateReducer,
  [actions.ADD_WORK_HISTORY_SUCCESS]: successStateReducer,
  [actions.ADD_WORK_HISTORY_ERROR]: errorStateReducer,
  [actions.ADD_WORK_HISTORY_CLEAR]: resetStateReducer
});

const getSatisfactionLevelList = createReducer(defaultState, {
  [actions.SATISFACTION_LEVEL_LIST]: isLoadingStateReducer,
  [actions.SATISFACTION_LEVEL_LIST_SUCCESS]: successStateReducer,
  [actions.SATISFACTION_LEVEL_LIST_ERROR]: errorStateReducer,
  [actions.SATISFACTION_LEVEL_LIST_CLEAR]: resetStateReducer
});

const getReasonsList = createReducer(defaultState, {
  [actions.REASONS_LIST]: isLoadingStateReducer,
  [actions.REASONS_LIST_SUCCESS]: successStateReducer,
  [actions.REASONS_LIST_ERROR]: errorStateReducer,
  [actions.REASONS_LIST_CLEAR]: resetStateReducer
});

const getNonConversionList = createReducer(defaultState, {
  [actions.NON_CONVERSION_LIST]: isLoadingStateReducer,
  [actions.NON_CONVERSION_LIST_SUCCESS]: successStateReducer,
  [actions.NON_CONVERSION_LIST_ERROR]: errorStateReducer,
  [actions.NON_CONVERSION_LIST_CLEAR]: resetStateReducer
});
const addHumanResourceInformation = createReducer(defaultState, {
  [actions.ADD_HUMAN_RESOURCE]: isLoadingStateReducer,
  [actions.ADD_HUMAN_RESOURCE_SUCCESS]: successStateReducer,
  [actions.ADD_HUMAN_RESOURCE_ERROR]: errorStateReducer,
  [actions.ADD_HUMAN_RESOURCE_CLEAR]: resetStateReducer
});

const getRatingTypeList = createReducer(defaultState, {
  [actions.RATING_TYPE_LIST]: isLoadingStateReducer,
  [actions.RATING_TYPE_LIST_SUCCESS]: successStateReducer,
  [actions.RATING_TYPE_LIST_ERROR]: errorStateReducer,
  [actions.RATING_TYPE_LIST_CLEAR]: resetStateReducer
});

const addCandidateRatingsInfo = createReducer(defaultState, {
  [actions.ADD_CANDIDATE_RATINGS]: isLoadingStateReducer,
  [actions.ADD_CANDIDATE_RATINGS_SUCCESS]: successStateReducer,
  [actions.ADD_CANDIDATE_RATINGS_ERROR]: errorStateReducer,
  [actions.ADD_CANDIDATE_RATINGS_CLEAR]: resetStateReducer
});

const getInfringementsTypeList = createReducer(defaultState, {
  [actions.INFRINGEMENTS_TYPE_LIST]: isLoadingStateReducer,
  [actions.INFRINGEMENTS_TYPE_LIST_SUCCESS]: successStateReducer,
  [actions.INFRINGEMENTS_TYPE_LIST_ERROR]: errorStateReducer,
  [actions.INFRINGEMENTS_TYPE_LIST_CLEAR]: resetStateReducer
});

const addCandidateInfringementsInfo = createReducer(defaultState, {
  [actions.ADD_CANDIDATE_INFRINGEMENTS]: isLoadingStateReducer,
  [actions.ADD_CANDIDATE_INFRINGEMENTS_SUCCESS]: successStateReducer,
  [actions.ADD_CANDIDATE_INFRINGEMENTS_ERROR]: errorStateReducer,
  [actions.ADD_CANDIDATE_INFRINGEMENTS_CLEAR]: resetStateReducer
});

const holidaysList = createReducer(defaultState, {
  [actions.HOLIDAYS_LIST]: isLoadingStateReducer,
  [actions.HOLIDAYS_LIST_SUCCESS]: successStateReducer,
  [actions.HOLIDAYS_LIST_ERROR]: errorStateReducer,
  [actions.HOLIDAYS_LIST_CLEAR]: resetStateReducer
});

const notesList = createReducer(defaultState, {
  [actions.NOTES_LIST]: isLoadingStateReducer,
  [actions.NOTES_LIST_SUCCESS]: successStateReducer,
  [actions.NOTES_LIST_ERROR]: errorStateReducer,
  [actions.NOTES_LIST_CLEAR]: resetStateReducer
});

const addNotesActivityInfo = createReducer(defaultState, {
  [actions.ADD_NOTES_ACTIVITY]: isLoadingStateReducer,
  [actions.ADD_NOTES_ACTIVITY_SUCCESS]: successStateReducer,
  [actions.ADD_NOTES_ACTIVITY_ERROR]: errorStateReducer,
  [actions.ADD_NOTES_ACTIVITY_CLEAR]: resetStateReducer
});

const statementsList = createReducer(defaultState, {
  [actions.STATEMENT_OPTIONS_LIST]: isLoadingStateReducer,
  [actions.STATEMENT_OPTIONS_LIST_SUCCESS]: successStateReducer,
  [actions.STATEMENT_OPTIONS_LIST_ERROR]: errorStateReducer,
  [actions.STATEMENT_OPTIONS_LIST_CLEAR]: resetStateReducer
});

const performanceTypeList = createReducer(defaultState, {
  [actions.PERFORMANCE_TYPE_LIST]: isLoadingStateReducer,
  [actions.PERFORMANCE_TYPE_LIST_SUCCESS]: successStateReducer,
  [actions.PERFORMANCE_TYPE_LIST_ERROR]: errorStateReducer,
  [actions.PERFORMANCE_TYPE_LIST_CLEAR]: resetStateReducer
});

const addPerformanceActivityInfo = createReducer(defaultState, {
  [actions.ADD_PERFORMANCE_ACTIVITY]: isLoadingStateReducer,
  [actions.ADD_PERFORMANCE_ACTIVITY_SUCCESS]: successStateReducer,
  [actions.ADD_PERFORMANCE_ACTIVITY_ERROR]: errorStateReducer,
  [actions.ADD_PERFORMANCE_ACTIVITY_CLEAR]: resetStateReducer
});

const tasksList = createReducer(defaultState, {
  [actions.TASKS_LIST]: isLoadingStateReducer,
  [actions.TASKS_LIST_SUCCESS]: successStateReducer,
  [actions.TASKS_LIST_ERROR]: errorStateReducer,
  [actions.TASKS_LIST_CLEAR]: resetStateReducer
});

const addTasksActivityInfo = createReducer(defaultState, {
  [actions.ADD_TASKS_ACTIVITY]: isLoadingStateReducer,
  [actions.ADD_TASKS_ACTIVITY_SUCCESS]: successStateReducer,
  [actions.ADD_TASKS_ACTIVITY_ERROR]: errorStateReducer,
  [actions.ADD_TASKS_ACTIVITY_CLEAR]: resetStateReducer
});

const smsHistoryList = createReducer(defaultState, {
  [actions.SMS_HISTORY_LIST]: isLoadingStateReducer,
  [actions.SMS_HISTORY_LIST_SUCCESS]: successStateReducer,
  [actions.SMS_HISTORY_LIST_ERROR]: errorStateReducer,
  [actions.SMS_HISTORY_LIST_CLEAR]: resetStateReducer
});

const sendSMSInfo = createReducer(defaultState, {
  [actions.SEND_SMS]: isLoadingStateReducer,
  [actions.SEND_SMS_SUCCESS]: successStateReducer,
  [actions.SEND_SMS_ERROR]: errorStateReducer,
  [actions.SEND_SMS_CLEAR]: resetStateReducer
});

const emailHistoryList = createReducer(defaultState, {
  [actions.EMAIL_HISTORY_LIST]: isLoadingStateReducer,
  [actions.EMAIL_HISTORY_LIST_SUCCESS]: successStateReducer,
  [actions.EMAIL_HISTORY_LIST_ERROR]: errorStateReducer,
  [actions.EMAIL_HISTORY_LIST_CLEAR]: resetStateReducer
});

const addSmartBoxInfo = createReducer(defaultState, {
  [actions.ADD_SMART_BOX_ACTIVITY]: isLoadingStateReducer,
  [actions.ADD_SMART_BOX_ACTIVITY_SUCCESS]: successStateReducer,
  [actions.ADD_SMART_BOX_ACTIVITY_ERROR]: errorStateReducer,
  [actions.ADD_SMART_BOX_ACTIVITY_CLEAR]: resetStateReducer
});

const candidateRatingList = createReducer(defaultState, {
  [actions.RATING_DETAILS_LIST]: isLoadingStateReducer,
  [actions.RATING_DETAILS_LIST_SUCCESS]: successStateReducer,
  [actions.RATING_DETAILS_LIST_ERROR]: errorStateReducer,
  [actions.RATING_DETAILS_LIST_CLEAR]: resetStateReducer
});

const candidateInfringementList = createReducer(defaultState, {
  [actions.INFRINGEMENTS_DETAILS_LIST]: isLoadingStateReducer,
  [actions.INFRINGEMENTS_DETAILS_LIST_SUCCESS]: successStateReducer,
  [actions.INFRINGEMENTS_DETAILS_LIST_ERROR]: errorStateReducer,
  [actions.INFRINGEMENTS_DETAILS_LIST_CLEAR]: resetStateReducer
});

const candidatePerformanceList = createReducer(defaultState, {
  [actions.PERFORMANCE_DETAILS_LIST]: isLoadingStateReducer,
  [actions.PERFORMANCE_DETAILS_LIST_SUCCESS]: successStateReducer,
  [actions.PERFORMANCE_DETAILS_LIST_ERROR]: errorStateReducer,
  [actions.PERFORMANCE_DETAILS_LIST_CLEAR]: resetStateReducer
});

const candidateSmartBoxList = createReducer(defaultState, {
  [actions.SMART_BOX_DETAILS_LIST]: isLoadingStateReducer,
  [actions.SMART_BOX_DETAILS_LIST_SUCCESS]: successStateReducer,
  [actions.SMART_BOX_DETAILS_LIST_ERROR]: errorStateReducer,
  [actions.SMART_BOX_DETAILS_LIST_CLEAR]: resetStateReducer
});

const updateCandidateRatings = createReducer(defaultState, {
  [actions.UPDATE_CANDIDATE_RATING]: isLoadingStateReducer,
  [actions.UPDATE_CANDIDATE_RATING_SUCCESS]: successStateReducer,
  [actions.UPDATE_CANDIDATE_RATING_ERROR]: errorStateReducer,
  [actions.UPDATE_CANDIDATE_RATING_CLEAR]: resetStateReducer
});

const updateCandidateInfringements = createReducer(defaultState, {
  [actions.UPDATE_CANDIDATE_INFRINGEMENT]: isLoadingStateReducer,
  [actions.UPDATE_CANDIDATE_INFRINGEMENT_SUCCESS]: successStateReducer,
  [actions.UPDATE_CANDIDATE_INFRINGEMENT_ERROR]: errorStateReducer,
  [actions.UPDATE_CANDIDATE_INFRINGEMENT_CLEAR]: resetStateReducer
});

const updateCandidatePerformances = createReducer(defaultState, {
  [actions.UPDATE_CANDIDATE_PERFORMANCE]: isLoadingStateReducer,
  [actions.UPDATE_CANDIDATE_PERFORMANCE_SUCCESS]: successStateReducer,
  [actions.UPDATE_CANDIDATE_PERFORMANCE_ERROR]: errorStateReducer,
  [actions.UPDATE_CANDIDATE_PERFORMANCE_CLEAR]: resetStateReducer
});

const updateCandidateNotes = createReducer(defaultState, {
  [actions.UPDATE_CANDIDATE_NOTES]: isLoadingStateReducer,
  [actions.UPDATE_CANDIDATE_NOTES_SUCCESS]: successStateReducer,
  [actions.UPDATE_CANDIDATE_NOTES_ERROR]: errorStateReducer,
  [actions.UPDATE_CANDIDATE_NOTES_CLEAR]: resetStateReducer
});

const updateCandidateSmartBox = createReducer(defaultState, {
  [actions.UPDATE_CANDIDATE_SMART_BOX]: isLoadingStateReducer,
  [actions.UPDATE_CANDIDATE_SMART_BOX_SUCCESS]: successStateReducer,
  [actions.UPDATE_CANDIDATE_SMART_BOX_ERROR]: errorStateReducer,
  [actions.UPDATE_CANDIDATE_SMART_BOX_CLEAR]: resetStateReducer
});

const searchUserList = createReducer(defaultState, {
  [actions.SEARCH_USER_LIST]: isLoadingStateReducer,
  [actions.SEARCH_USER_LIST_SUCCESS]: successStateReducer,
  [actions.SEARCH_USER_LIST_ERROR]: errorStateReducer,
  [actions.SEARCH_USER_LIST_CLEAR]: resetStateReducer
});

const candidateRatingDelete = createReducer(defaultState, {
  [actions.DELETE_CANDIDATE_RATING]: isLoadingStateReducer,
  [actions.DELETE_CANDIDATE_RATING_SUCCESS]: successStateReducer,
  [actions.DELETE_CANDIDATE_RATING_ERROR]: errorStateReducer,
  [actions.DELETE_CANDIDATE_RATING_CLEAR]: resetStateReducer
});

const candidateInfringementDelete = createReducer(defaultState, {
  [actions.DELETE_CANDIDATE_INFRINGEMENTS]: isLoadingStateReducer,
  [actions.DELETE_CANDIDATE_INFRINGEMENTS_SUCCESS]: successStateReducer,
  [actions.DELETE_CANDIDATE_INFRINGEMENTS_ERROR]: errorStateReducer,
  [actions.DELETE_CANDIDATE_INFRINGEMENTS_CLEAR]: resetStateReducer
});

const candidateNoteDelete = createReducer(defaultState, {
  [actions.DELETE_CANDIDATE_NOTES]: isLoadingStateReducer,
  [actions.DELETE_CANDIDATE_NOTES_SUCCESS]: successStateReducer,
  [actions.DELETE_CANDIDATE_NOTES_ERROR]: errorStateReducer,
  [actions.DELETE_CANDIDATE_NOTES_CLEAR]: resetStateReducer
});

const candidateTicketDelete = createReducer(defaultState, {
  [actions.DELETE_CANDIDATE_TICKETS]: isLoadingStateReducer,
  [actions.DELETE_CANDIDATE_TICKETS_SUCCESS]: successStateReducer,
  [actions.DELETE_CANDIDATE_TICKETS_ERROR]: errorStateReducer,
  [actions.DELETE_CANDIDATE_TICKETS_CLEAR]: resetStateReducer
});

const candidatePerformanceDelete = createReducer(defaultState, {
  [actions.DELETE_CANDIDATE_PERFORMANCE]: isLoadingStateReducer,
  [actions.DELETE_CANDIDATE_PERFORMANCE_SUCCESS]: successStateReducer,
  [actions.DELETE_CANDIDATE_PERFORMANCE_ERROR]: errorStateReducer,
  [actions.DELETE_CANDIDATE_PERFORMANCE_CLEAR]: resetStateReducer
});

const updateCandidateTicketsInfo = createReducer(defaultState, {
  [actions.UPDATE_CANDIDATE_TICKETS]: isLoadingStateReducer,
  [actions.UPDATE_CANDIDATE_TICKETS_SUCCESS]: successStateReducer,
  [actions.UPDATE_CANDIDATE_TICKETS_ERROR]: errorStateReducer,
  [actions.UPDATE_CANDIDATE_TICKETS_CLEAR]: resetStateReducer
});

const tagsListInfo = createReducer(defaultState, {
  [actions.TAGS_LIST]: isLoadingStateReducer,
  [actions.TAGS_LIST_SUCCESS]: successStateReducer,
  [actions.TAGS_LIST_ERROR]: errorStateReducer,
  [actions.TAGS_LIST_CLEAR]: resetStateReducer
});

const tagsListV2Info = createReducer(defaultState, {
  [actions.TAGS_LIST_V2]: isLoadingStateReducer,
  [actions.TAGS_LIST_V2_SUCCESS]: successStateReducer,
  [actions.TAGS_LIST_V2_ERROR]: errorStateReducer,
  [actions.TAGS_LIST_V2_CLEAR]: resetStateReducer
});

const addTagsListInfo = createReducer(defaultState, {
  [actions.ADD_TAGS_LIST]: isLoadingStateReducer,
  [actions.ADD_TAGS_LIST_SUCCESS]: successStateReducer,
  [actions.ADD_TAGS_LIST_ERROR]: errorStateReducer,
  [actions.ADD_TAGS_LIST_CLEAR]: resetStateReducer
});

const candidateDetailsInfo = createReducer(defaultState, {
  [actions.CANDIDATE_DETAILS]: isLoadingStateReducer,
  [actions.CANDIDATE_DETAILS_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_DETAILS_ERROR]: errorStateReducer,
  [actions.CANDIDATE_DETAILS_CLEAR]: resetStateReducer
});

const updateCandidateDetailsInfo = createReducer(defaultState, {
  [actions.UPDATE_CANDIDATE_DETAILS]: isLoadingStateReducer,
  [actions.UPDATE_CANDIDATE_DETAILS_SUCCESS]: successStateReducer,
  [actions.UPDATE_CANDIDATE_DETAILS_ERROR]: errorStateReducer,
  [actions.UPDATE_CANDIDATE_DETAILS_CLEAR]: resetStateReducer
});

const contactDetailsInfo = createReducer(defaultState, {
  [actions.CONTACT_DETAILS]: isLoadingStateReducer,
  [actions.CONTACT_DETAILS_SUCCESS]: successStateReducer,
  [actions.CONTACT_DETAILS_ERROR]: errorStateReducer,
  [actions.CONTACT_DETAILS_CLEAR]: resetStateReducer
});

const updateContactDetailsInfo = createReducer(defaultState, {
  [actions.UPDATE_CONTACT_DETAILS]: isLoadingStateReducer,
  [actions.UPDATE_CONTACT_DETAILS_SUCCESS]: successStateReducer,
  [actions.UPDATE_CONTACT_DETAILS_ERROR]: errorStateReducer,
  [actions.UPDATE_CONTACT_DETAILS_CLEAR]: resetStateReducer
});

const additionalContactsInfo = createReducer(defaultState, {
  [actions.ADDITIONAL_CONTACT_DETAILS]: isLoadingStateReducer,
  [actions.ADDITIONAL_CONTACT_DETAILS_SUCCESS]: successStateReducer,
  [actions.ADDITIONAL_CONTACT_DETAILS_ERROR]: errorStateReducer,
  [actions.ADDITIONAL_CONTACT_DETAILS_CLEAR]: resetStateReducer
});

const updateAdditionalContactsInfo = createReducer(defaultState, {
  [actions.UPDATE_ADDITIONAL_CONTACT]: isLoadingStateReducer,
  [actions.UPDATE_ADDITIONAL_CONTACT_SUCCESS]: successStateReducer,
  [actions.UPDATE_ADDITIONAL_CONTACT_ERROR]: errorStateReducer,
  [actions.UPDATE_ADDITIONAL_CONTACT_CLEAR]: resetStateReducer
});

const workHistoryList = createReducer(defaultState, {
  [actions.GET_WORK_HISTORY_DETAILS]: isLoadingStateReducer,
  [actions.GET_WORK_HISTORY_DETAILS_SUCCESS]: successStateReducer,
  [actions.GET_WORK_HISTORY_DETAILS_ERROR]: errorStateReducer,
  [actions.GET_WORK_HISTORY_DETAILS_CLEAR]: resetStateReducer
});

const getStartEndDates = createReducer(defaultState, {
  [actions.GET_START_END_DATES]: isLoadingStateReducer,
  [actions.GET_START_END_DATES_SUCCESS]: successStateReducer,
  [actions.GET_START_END_DATES_ERROR]: errorStateReducer,
  [actions.GET_START_END_DATES_CLEAR]: resetStateReducer
});

const deletedAddressList = createReducer(defaultState, {
  [actions.DELETE_ADDRESS_DETAILS]: isLoadingStateReducer,
  [actions.DELETE_ADDRESS_DETAILS_SUCCESS]: successStateReducer,
  [actions.DELETE_ADDRESS_DETAILS_ERROR]: errorStateReducer,
  [actions.DELETE_ADDRESS_DETAILS_CLEAR]: resetStateReducer
});

const updateWorkHistoryList = createReducer(defaultState, {
  [actions.UPDATE_WORK_HISTORY]: isLoadingStateReducer,
  [actions.UPDATE_WORK_HISTORY_SUCCESS]: successStateReducer,
  [actions.UPDATE_WORK_HISTORY_ERROR]: errorStateReducer,
  [actions.UPDATE_WORK_HISTORY_CLEAR]: resetStateReducer
});

const inductionsBansList = createReducer(defaultState, {
  [actions.GET_INDUCTIONS_BANS_LIST]: isLoadingStateReducer,
  [actions.GET_INDUCTIONS_BANS_LIST_SUCCESS]: successStateReducer,
  [actions.GET_INDUCTIONS_BANS_LIST_ERROR]: errorStateReducer,
  [actions.GET_INDUCTIONS_BANS_LIST_CLEAR]: resetStateReducer
});

const updateInductionsBans = createReducer(defaultState, {
  [actions.UPDATE_INDUCTIONS_BANS]: isLoadingStateReducer,
  [actions.UPDATE_INDUCTIONS_BANS_SUCCESS]: successStateReducer,
  [actions.UPDATE_INDUCTIONS_BANS_ERROR]: errorStateReducer,
  [actions.UPDATE_INDUCTIONS_BANS_CLEAR]: resetStateReducer
});

const deleteInductionList = createReducer(defaultState, {
  [actions.DELETE_INDUCTIONS_DETAILS]: isLoadingStateReducer,
  [actions.DELETE_INDUCTIONS_DETAILS_SUCCESS]: successStateReducer,
  [actions.DELETE_INDUCTIONS_DETAILS_ERROR]: errorStateReducer,
  [actions.DELETE_INDUCTIONS_DETAILS_CLEAR]: resetStateReducer
});

const deletedBansList = createReducer(defaultState, {
  [actions.DELETE_BANS_DETAILS]: isLoadingStateReducer,
  [actions.DELETE_BANS_DETAILS_SUCCESS]: successStateReducer,
  [actions.DELETE_BANS_DETAILS_ERROR]: errorStateReducer,
  [actions.DELETE_BANS_DETAILS_CLEAR]: resetStateReducer
});

const timePreferenceList = createReducer(defaultState, {
  [actions.GET_TIME_PREFERENCE]: isLoadingStateReducer,
  [actions.GET_TIME_PREFERENCE_SUCCESS]: successStateReducer,
  [actions.GET_TIME_PREFERENCE_ERROR]: errorStateReducer,
  [actions.GET_TIME_PREFERENCE_CLEAR]: resetStateReducer
});

const payrollDetails = createReducer(defaultState, {
  [actions.GET_PAYROLL_DETAILS]: isLoadingStateReducer,
  [actions.GET_PAYROLL_DETAILS_SUCCESS]: successStateReducer,
  [actions.GET_PAYROLL_DETAILS_ERROR]: errorStateReducer,
  [actions.GET_PAYROLL_DETAILS_CLEAR]: resetStateReducer
});

const isCandidateSts = createReducer(defaultState, {
  [actions.GET_IS_CANDIDATE_STS]: isLoadingStateReducer,
  [actions.GET_IS_CANDIDATE_STS_SUCCESS]: successStateReducer,
  [actions.GET_IS_CANDIDATE_STS_ERROR]: errorStateReducer,
  [actions.GET_IS_CANDIDATE_STS_CLEAR]: resetStateReducer
});

const updatePayrollDetails = createReducer(defaultState, {
  [actions.UPDATE_PAYROLL_DETAILS]: isLoadingStateReducer,
  [actions.UPDATE_PAYROLL_DETAILS_SUCCESS]: successStateReducer,
  [actions.UPDATE_PAYROLL_DETAILS_ERROR]: errorStateReducer,
  [actions.UPDATE_PAYROLL_DETAILS_CLEAR]: resetStateReducer
});

const addDrivingLicenseInfo = createReducer(defaultState, {
  [actions.ADD_DRIVING_LICENSE]: isLoadingStateReducer,
  [actions.ADD_DRIVING_LICENSE_SUCCESS]: successStateReducer,
  [actions.ADD_DRIVING_LICENSE_ERROR]: errorStateReducer,
  [actions.ADD_DRIVING_LICENSE_CLEAR]: resetStateReducer
});

const drivingLicenseInfo = createReducer(defaultState, {
  [actions.GET_DRIVING_LICENSE]: isLoadingStateReducer,
  [actions.GET_DRIVING_LICENSE_SUCCESS]: successStateReducer,
  [actions.GET_DRIVING_LICENSE_ERROR]: errorStateReducer,
  [actions.GET_DRIVING_LICENSE_CLEAR]: resetStateReducer
});

const humanResourceInfo = createReducer(defaultState, {
  [actions.GET_HUMAN_RESOURCE]: isLoadingStateReducer,
  [actions.GET_HUMAN_RESOURCE_SUCCESS]: successStateReducer,
  [actions.GET_HUMAN_RESOURCE_ERROR]: errorStateReducer,
  [actions.GET_HUMAN_RESOURCE_CLEAR]: resetStateReducer
});

const updateHumanResourceInfo = createReducer(defaultState, {
  [actions.UPDATE_HUMAN_RESOURCE]: isLoadingStateReducer,
  [actions.UPDATE_HUMAN_RESOURCE_SUCCESS]: successStateReducer,
  [actions.UPDATE_HUMAN_RESOURCE_ERROR]: errorStateReducer,
  [actions.UPDATE_HUMAN_RESOURCE_CLEAR]: resetStateReducer
});

const addLicenceCapabilitiesInfo = createReducer(defaultState, {
  [actions.ADD_LICENCE_CAPABILITIES]: isLoadingStateReducer,
  [actions.ADD_LICENCE_CAPABILITIES_SUCCESS]: successStateReducer,
  [actions.ADD_LICENCE_CAPABILITIES_ERROR]: errorStateReducer,
  [actions.ADD_LICENCE_CAPABILITIES_CLEAR]: resetStateReducer
});

const updateLicenceCapabilitiesInfo = createReducer(defaultState, {
  [actions.UPDATE_LICENCE_CAPABILITIES]: isLoadingStateReducer,
  [actions.UPDATE_LICENCE_CAPABILITIES_SUCCESS]: successStateReducer,
  [actions.UPDATE_LICENCE_CAPABILITIES_ERROR]: errorStateReducer,
  [actions.UPDATE_LICENCE_CAPABILITIES_CLEAR]: resetStateReducer
});

const addLicenseEndorsementInfo = createReducer(defaultState, {
  [actions.ADD_LICENCE_ENDORSEMENTS]: isLoadingStateReducer,
  [actions.ADD_LICENCE_ENDORSEMENTS_SUCCESS]: successStateReducer,
  [actions.ADD_LICENCE_ENDORSEMENTS_ERROR]: errorStateReducer,
  [actions.ADD_LICENCE_ENDORSEMENTS_CLEAR]: resetStateReducer
});

const updateLicenseEndorsementInfo = createReducer(defaultState, {
  [actions.UPDATE_LICENCE_ENDORSEMENTS]: isLoadingStateReducer,
  [actions.UPDATE_LICENCE_ENDORSEMENTS_SUCCESS]: successStateReducer,
  [actions.UPDATE_LICENCE_ENDORSEMENTS_ERROR]: errorStateReducer,
  [actions.UPDATE_LICENCE_ENDORSEMENTS_CLEAR]: resetStateReducer
});

const deleteLicenseEndorsementInfo = createReducer(defaultState, {
  [actions.DELETE_LICENCE_ENDORSEMENTS]: isLoadingStateReducer,
  [actions.DELETE_LICENCE_ENDORSEMENTS_SUCCESS]: successStateReducer,
  [actions.DELETE_LICENCE_ENDORSEMENTS_ERROR]: errorStateReducer,
  [actions.DELETE_LICENCE_ENDORSEMENTS_CLEAR]: resetStateReducer
});

const deleteWorkHistoryInfo = createReducer(defaultState, {
  [actions.DELETE_WORK_HISTORY]: isLoadingStateReducer,
  [actions.DELETE_WORK_HISTORY_SUCCESS]: successStateReducer,
  [actions.DELETE_WORK_HISTORY_ERROR]: errorStateReducer,
  [actions.DELETE_WORK_HISTORY_CLEAR]: resetStateReducer
});

const deleteStartEndDate = createReducer(defaultState, {
  [actions.DELETE_START_END_DATE]: isLoadingStateReducer,
  [actions.DELETE_START_END_DATE_SUCCESS]: successStateReducer,
  [actions.DELETE_START_END_DATE_ERROR]: errorStateReducer,
  [actions.DELETE_START_END_DATE_CLEAR]: resetStateReducer
});

const updateStartEndDate = createReducer(defaultState, {
  [actions.UPDATE_START_END_DATE]: isLoadingStateReducer,
  [actions.UPDATE_START_END_DATE_SUCCESS]: successStateReducer,
  [actions.UPDATE_START_END_DATE_ERROR]: errorStateReducer,
  [actions.UPDATE_START_END_DATE_CLEAR]: resetStateReducer
});

const deleteLicenseCapabilities = createReducer(defaultState, {
  [actions.DELETE_LICENCE_CAPABILITIES]: isLoadingStateReducer,
  [actions.DELETE_LICENCE_CAPABILITIES_SUCCESS]: successStateReducer,
  [actions.DELETE_LICENCE_CAPABILITIES_ERROR]: errorStateReducer,
  [actions.DELETE_LICENCE_CAPABILITIES_CLEAR]: resetStateReducer
});

const addDigiCardInfo = createReducer(defaultState, {
  [actions.ADD_DIGI_CARD]: isLoadingStateReducer,
  [actions.ADD_DIGI_CARD_SUCCESS]: successStateReducer,
  [actions.ADD_DIGI_CARD_ERROR]: errorStateReducer,
  [actions.ADD_DIGI_CARD_CLEAR]: resetStateReducer
});

const deleteDigiCardInfo = createReducer(defaultState, {
  [actions.DELETE_DIGI_CARD]: isLoadingStateReducer,
  [actions.DELETE_DIGI_CARD_SUCCESS]: successStateReducer,
  [actions.DELETE_DIGI_CARD_ERROR]: errorStateReducer,
  [actions.DELETE_DIGI_CARD_CLEAR]: resetStateReducer
});

const updateDigiCardInfo = createReducer(defaultState, {
  [actions.UPDATE_DIGI_CARD]: isLoadingStateReducer,
  [actions.UPDATE_DIGI_CARD_SUCCESS]: successStateReducer,
  [actions.UPDATE_DIGI_CARD_ERROR]: errorStateReducer,
  [actions.UPDATE_DIGI_CARD_CLEAR]: resetStateReducer
});

const getDigiCardInfo = createReducer(defaultState, {
  [actions.GET_DIGI_CARD]: isLoadingStateReducer,
  [actions.GET_DIGI_CARD_SUCCESS]: successStateReducer,
  [actions.GET_DIGI_CARD_ERROR]: errorStateReducer,
  [actions.GET_DIGI_CARD_CLEAR]: resetStateReducer
});

const getQualificationInfo = createReducer(defaultState, {
  [actions.GET_QUALIFICATION_DETAILS]: isLoadingStateReducer,
  [actions.GET_QUALIFICATION_DETAILS_SUCCESS]: successStateReducer,
  [actions.GET_QUALIFICATION_DETAILS_ERROR]: errorStateReducer,
  [actions.GET_QUALIFICATION_DETAILS_CLEAR]: resetStateReducer
});

const deleteQualificationInfo = createReducer(defaultState, {
  [actions.DELETE_QUALIFICATION_DETAILS]: isLoadingStateReducer,
  [actions.DELETE_QUALIFICATION_DETAILS_SUCCESS]: successStateReducer,
  [actions.DELETE_QUALIFICATION_DETAILS_ERROR]: errorStateReducer,
  [actions.DELETE_QUALIFICATION_DETAILS_CLEAR]: resetStateReducer
});

const updateQualificationInfo = createReducer(defaultState, {
  [actions.UPDATE_QUALIFICATION_DETAILS]: isLoadingStateReducer,
  [actions.UPDATE_QUALIFICATION_DETAILS_SUCCESS]: successStateReducer,
  [actions.UPDATE_QUALIFICATION_DETAILS_ERROR]: errorStateReducer,
  [actions.UPDATE_QUALIFICATION_DETAILS_CLEAR]: resetStateReducer
});

const remindersList = createReducer(defaultState, {
  [actions.REMINDERS_LIST]: isLoadingStateReducer,
  [actions.REMINDERS_LIST_SUCCESS]: successStateReducer,
  [actions.REMINDERS_LIST_ERROR]: errorStateReducer,
  [actions.REMINDERS_LIST_CLEAR]: resetStateReducer
});

const deleteReminderInfo = createReducer(defaultState, {
  [actions.DELETE_REMINDER]: isLoadingStateReducer,
  [actions.DELETE_REMINDER_SUCCESS]: successStateReducer,
  [actions.DELETE_REMINDER_ERROR]: errorStateReducer,
  [actions.DELETE_REMINDER_CLEAR]: resetStateReducer
});

const getAssignedTicketsInfo = createReducer(defaultState, {
  [actions.GET_ASSIGNED_TICKETS]: isLoadingStateReducer,
  [actions.GET_ASSIGNED_TICKETS_SUCCESS]: successStateReducer,
  [actions.GET_ASSIGNED_TICKETS_ERROR]: errorStateReducer,
  [actions.GET_ASSIGNED_TICKETS_CLEAR]: resetStateReducer
});

const payeStatementsList = createReducer(defaultState, {
  [actions.GET_PAYE_LIST]: isLoadingStateReducer,
  [actions.GET_PAYE_LIST_SUCCESS]: successStateReducer,
  [actions.GET_PAYE_LIST_ERROR]: errorStateReducer,
  [actions.GET_PAYE_LIST_CLEAR]: resetStateReducer
});

const deleteUserTagsInfo = createReducer(defaultState, {
  [actions.DELETE_USER_TAGS]: isLoadingStateReducer,
  [actions.DELETE_USER_TAGS_SUCCESS]: successStateReducer,
  [actions.DELETE_USER_TAGS_ERROR]: errorStateReducer,
  [actions.DELETE_USER_TAGS_CLEAR]: resetStateReducer
});

const listAttachmentsInfo = createReducer(defaultState, {
  [actions.LIST_ATTACHMENTS]: isLoadingStateReducer,
  [actions.LIST_ATTACHMENTS_SUCCESS]: successStateReducer,
  [actions.LIST_ATTACHMENTS_ERROR]: errorStateReducer,
  [actions.LIST_ATTACHMENTS_CLEAR]: resetStateReducer
});

const downloadDocumentInfo = createReducer(defaultState, {
  [actions.DOWNLOAD_DOCUMENT]: isLoadingStateReducer,
  [actions.DOWNLOAD_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.DOWNLOAD_DOCUMENT_ERROR]: errorStateReducer,
  [actions.DOWNLOAD_DOCUMENT_CLEAR]: resetStateReducer
});

const deleteMultiDocumentInfo = createReducer(defaultState, {
  [actions.DELETE_DOCUMENT]: isLoadingStateReducer,
  [actions.DELETE_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.DELETE_DOCUMENT_ERROR]: errorStateReducer,
  [actions.DELETE_DOCUMENT_CLEAR]: resetStateReducer
});

const deleteSmartBoxMessageInfo = createReducer(defaultState, {
  [actions.DELETE_SMART_BOX]: isLoadingStateReducer,
  [actions.DELETE_SMART_BOX_SUCCESS]: successStateReducer,
  [actions.DELETE_SMART_BOX_ERROR]: errorStateReducer,
  [actions.DELETE_SMART_BOX_CLEAR]: resetStateReducer
});

const refreshMetaCacheInfo = createReducer(defaultState, {
  [actions.REFRESH_META_CACHE]: isLoadingStateReducer,
  [actions.REFRESH_META_CACHE_SUCCESS]: successStateReducer,
  [actions.REFRESH_META_CACHE_ERROR]: errorStateReducer,
  [actions.REFRESH_META_CACHE_CLEAR]: resetStateReducer
});

const sendAppSMSInfo = createReducer(defaultState, {
  [actions.SEND_APP_SMS]: isLoadingStateReducer,
  [actions.SEND_APP_SMS_SUCCESS]: successStateReducer,
  [actions.SEND_APP_SMS_ERROR]: errorStateReducer,
  [actions.SEND_APP_SMS_CLEAR]: resetStateReducer
});

const getAppHistoryInfo = createReducer(defaultState, {
  [actions.APP_HISTORY_LIST]: isLoadingStateReducer,
  [actions.APP_HISTORY_LIST_SUCCESS]: successStateReducer,
  [actions.APP_HISTORY_LIST_ERROR]: errorStateReducer,
  [actions.APP_HISTORY_LIST_CLEAR]: resetStateReducer
});

const registerAppPersonInfo = createReducer(defaultState, {
  [actions.REGISTER_APP_PERSON]: isLoadingStateReducer,
  [actions.REGISTER_APP_PERSON_SUCCESS]: successStateReducer,
  [actions.REGISTER_APP_PERSON_ERROR]: errorStateReducer,
  [actions.REGISTER_APP_PERSON_CLEAR]: resetStateReducer
});

const detailPersonSearchInfo = createReducer(defaultState, {
  [actions.DETAIL_PERSON_SEARCH]: isLoadingStateReducer,
  [actions.DETAIL_PERSON_SEARCH_SUCCESS]: successStateReducer,
  [actions.DETAIL_PERSON_SEARCH_ERROR]: errorStateReducer,
  [actions.DETAIL_PERSON_SEARCH_CLEAR]: resetStateReducer
});

const sendBulkSMSInfo = createReducer(defaultState, {
  [actions.SEND_BULK_SMS]: isLoadingStateReducer,
  [actions.SEND_BULK_SMS_SUCCESS]: successStateReducer,
  [actions.SEND_BULK_SMS_ERROR]: errorStateReducer,
  [actions.SEND_BULK_SMS_CLEAR]: resetStateReducer
});

const sendSMSToPhoneNumberInfo = createReducer(defaultState, {
  [actions.SEND_SMS_TO_PHONE_NUMBER]: isLoadingStateReducer,
  [actions.SEND_SMS_TO_PHONE_NUMBER_SUCCESS]: successStateReducer,
  [actions.SEND_SMS_TO_PHONE_NUMBER_ERROR]: errorStateReducer,
  [actions.SEND_SMS_TO_PHONE_NUMBER_CLEAR]: resetStateReducer
});
const sendAvailabilityRequestInfo = createReducer(defaultState, {
  [actions.SEND_AVAILABILITY_REQUEST]: isLoadingStateReducer,
  [actions.SEND_AVAILABILITY_REQUEST_SUCCESS]: successStateReducer,
  [actions.SEND_AVAILABILITY_REQUEST_ERROR]: errorStateReducer,
  [actions.SEND_AVAILABILITY_REQUEST_CLEAR]: resetStateReducer
});

const sendCalendarUpdatedInfo = createReducer(defaultState, {
  [actions.SEND_CALENDAR_UPDATED]: isLoadingStateReducer,
  [actions.SEND_CALENDAR_UPDATED_SUCCESS]: successStateReducer,
  [actions.SEND_CALENDAR_UPDATED_ERROR]: errorStateReducer,
  [actions.SEND_CALENDAR_UPDATED_CLEAR]: resetStateReducer
});

const sendNoWorkTomorrowInfo = createReducer(defaultState, {
  [actions.SEND_NO_WORK_TOMORROW]: isLoadingStateReducer,
  [actions.SEND_NO_WORK_TOMORROW_SUCCESS]: successStateReducer,
  [actions.SEND_NO_WORK_TOMORROW_ERROR]: errorStateReducer,
  [actions.SEND_NO_WORK_TOMORROW_CLEAR]: resetStateReducer
});
const sendCalendarSummaryInfo = createReducer(defaultState, {
  [actions.SEND_CALENDAR_SUMMARY]: isLoadingStateReducer,
  [actions.SEND_CALENDAR_SUMMARY_SUCCESS]: successStateReducer,
  [actions.SEND_CALENDAR_SUMMARY_ERROR]: errorStateReducer,
  [actions.SEND_CALENDAR_SUMMARY_CLEAR]: resetStateReducer
});
const sendBookingDetailsInfo = createReducer(defaultState, {
  [actions.SEND_BOOKING_DETAILS]: isLoadingStateReducer,
  [actions.SEND_BOOKING_DETAILS_SUCCESS]: successStateReducer,
  [actions.SEND_BOOKING_DETAILS_ERROR]: errorStateReducer,
  [actions.SEND_BOOKING_DETAILS_CLEAR]: resetStateReducer
});
const sendBookingHoursQueryInfo = createReducer(defaultState, {
  [actions.SEND_BOOKING_HRS]: isLoadingStateReducer,
  [actions.SEND_BOOKING_HRS_SUCCESS]: successStateReducer,
  [actions.SEND_BOOKING_HRS_ERROR]: errorStateReducer,
  [actions.SEND_BOOKING_HRS_CLEAR]: resetStateReducer
});

const getCandidateLinksInfo = createReducer(defaultState, {
  [actions.GET_CANDIDATE_LINKS]: isLoadingStateReducer,
  [actions.GET_CANDIDATE_LINKS_SUCCESS]: successStateReducer,
  [actions.GET_CANDIDATE_LINKS_ERROR]: errorStateReducer,
  [actions.GET_CANDIDATE_LINKS_CLEAR]: resetStateReducer
});

const holidayShiftsAdjustmentsList = createReducer(defaultState, {
  [actions.HOLIDAYS_SHIFT_LIST]: isLoadingStateReducer,
  [actions.HOLIDAYS_SHIFT_LIST_SUCCESS]: successStateReducer,
  [actions.HOLIDAYS_SHIFT_LIST_ERROR]: errorStateReducer,
  [actions.HOLIDAYS_SHIFT_LIST_CLEAR]: resetStateReducer
});

const accruedHolidayAdjustmentsList = createReducer(defaultState, {
  [actions.HOLIDAYS_ADJUSTMENT_LIST]: isLoadingStateReducer,
  [actions.HOLIDAYS_ADJUSTMENT_LIST_SUCCESS]: successStateReducer,
  [actions.HOLIDAYS_ADJUSTMENT_LIST_ERROR]: errorStateReducer,
  [actions.HOLIDAYS_ADJUSTMENT_LIST_CLEAR]: resetStateReducer
});

const addAccruedHolidayAdjustmentsInfo = createReducer(defaultState, {
  [actions.ADD_HOLIDAYS_ADJUSTMENT_LIST]: isLoadingStateReducer,
  [actions.ADD_HOLIDAYS_ADJUSTMENT_LIST_SUCCESS]: successStateReducer,
  [actions.ADD_HOLIDAYS_ADJUSTMENT_LIST_ERROR]: errorStateReducer,
  [actions.ADD_HOLIDAYS_ADJUSTMENT_LIST_CLEAR]: resetStateReducer
});

const addHolidayShiftsAdjustmentsInfo = createReducer(defaultState, {
  [actions.ADD_HOLIDAYS_SHIFT_LIST]: isLoadingStateReducer,
  [actions.ADD_HOLIDAYS_SHIFT_LIST_SUCCESS]: successStateReducer,
  [actions.ADD_HOLIDAYS_SHIFT_LIST_ERROR]: errorStateReducer,
  [actions.ADD_HOLIDAYS_SHIFT_LIST_CLEAR]: resetStateReducer
});

const deleteAccruedHolidayAdjustmentsInfo = createReducer(defaultState, {
  [actions.DELETE_HOLIDAYS_ADJUSTMENT_LIST]: isLoadingStateReducer,
  [actions.DELETE_HOLIDAYS_ADJUSTMENT_LIST_SUCCESS]: successStateReducer,
  [actions.DELETE_HOLIDAYS_ADJUSTMENT_LIST_ERROR]: errorStateReducer,
  [actions.DELETE_HOLIDAYS_ADJUSTMENT_LIST_CLEAR]: resetStateReducer
});

const deleteHolidayShiftsAdjustmentsInfo = createReducer(defaultState, {
  [actions.DELETE_HOLIDAYS_SHIFT_LIST]: isLoadingStateReducer,
  [actions.DELETE_HOLIDAYS_SHIFT_LIST_SUCCESS]: successStateReducer,
  [actions.DELETE_HOLIDAYS_SHIFT_LIST_ERROR]: errorStateReducer,
  [actions.DELETE_HOLIDAYS_SHIFT_LIST_CLEAR]: resetStateReducer
});

const getRtwCheckInfo = createReducer(defaultState, {
  [actions.REQUEST_RTW_CHECK]: isLoadingStateReducer,
  [actions.REQUEST_RTW_CHECK_SUCCESS]: successStateReducer,
  [actions.REQUEST_RTW_CHECK_ERROR]: errorStateReducer,
  [actions.REQUEST_RTW_CHECK_CLEAR]: resetStateReducer
});

const holidayPeriodInfo = createReducer(defaultState, {
  [actions.HOLIDAY_PERIOD]: isLoadingStateReducer,
  [actions.HOLIDAY_PERIOD_SUCCESS]: successStateReducer,
  [actions.HOLIDAY_PERIOD_ERROR]: errorStateReducer,
  [actions.HOLIDAY_PERIOD_CLEAR]: resetStateReducer
});

const detailedBookingsInfo = createReducer(defaultState, {
  [actions.DETAILED_BOOKINGS]: isLoadingStateReducer,
  [actions.DETAILED_BOOKINGS_SUCCESS]: successStateReducer,
  [actions.DETAILED_BOOKINGS_ERROR]: errorStateReducer,
  [actions.DETAILED_BOOKINGS_CLEAR]: resetStateReducer
});

const candidateDefaultExpenseInfo = createReducer(defaultState, {
  [actions.CANDIDATE_DEFAULT_EXPENSE]: isLoadingStateReducer,
  [actions.CANDIDATE_DEFAULT_EXPENSE_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_DEFAULT_EXPENSE_ERROR]: errorStateReducer,
  [actions.CANDIDATE_DEFAULT_EXPENSE_CLEAR]: resetStateReducer
});

const actionCandidateDefaultExpenseInfo = createReducer(defaultState, {
  [actions.ACTION_CANDIDATE_DEFAULT_EXPENSE]: isLoadingStateReducer,
  [actions.ACTION_CANDIDATE_DEFAULT_EXPENSE_SUCCESS]: successStateReducer,
  [actions.ACTION_CANDIDATE_DEFAULT_EXPENSE_ERROR]: errorStateReducer,
  [actions.ACTION_CANDIDATE_DEFAULT_EXPENSE_CLEAR]: resetStateReducer
});

const setPassword = createReducer(defaultState, {
  [actions.PASSWORD_SETUP]: isLoadingStateReducer,
  [actions.PASSWORD_SETUP_SUCCESS]: successStateReducer,
  [actions.PASSWORD_SETUP_ERROR]: errorStateReducer,
  [actions.PASSWORD_SETUP_CLEAR]: resetStateReducer
});

const getMRAppDetails = createReducer(defaultState, {
  [actions.GET_APP_DETAILS]: isLoadingStateReducer,
  [actions.GET_APP_DETAILS_SUCCESS]: successStateReducer,
  [actions.GET_APP_DETAILS_ERROR]: errorStateReducer,
  [actions.GET_APP_DETAILS_CLEAR]: resetStateReducer
});

const clearAppProfile = createReducer(defaultState, {
  [actions.CLEAR_APP_PROFILE]: isLoadingStateReducer,
  [actions.CLEAR_APP_PROFILE_SUCCESS]: successStateReducer,
  [actions.CLEAR_APP_PROFILE_ERROR]: errorStateReducer,
  [actions.CLEAR_APP_PROFILE_CLEAR]: resetStateReducer
});

const ClaimAppId = createReducer(defaultState, {
  [actions.CLEAR_APP_PROFILE]: isLoadingStateReducer,
  [actions.CLEAR_APP_PROFILE_SUCCESS]: successStateReducer,
  [actions.CLEAR_APP_PROFILE_ERROR]: errorStateReducer,
  [actions.CLEAR_APP_PROFILE_CLEAR]: resetStateReducer
});

export default combineReducers({
  getLeadSourceList,
  updateStartEndDate,
  isCandidateSts,
  getInsuranceList,
  getNationalityList,
  addNewCandidateList,
  addAddressList,
  addAdditionalAddressList,
  addMultipleDocumentsList,
  addLicenseInformationList,
  drivingLicenseCapabilityList,
  drivingLicenseEndorsementList,
  drivingLicenseCapabilityTypesList,
  drivingLicenseEndorsementTypesList,
  addInductionsBansList,
  getQualificationTitleList,
  getIdentificationNameList,
  addQualificationList,
  addLicenceDetailsList,
  deleteStartEndDate,
  getPaymentTierList,
  getSupplierList,
  getServiceCompanyList,
  getContactTypeList,
  getExpenseTypeList,
  addPayrollInformation,
  addPreferencesInformation,
  addWorkHistoryInformation,
  getSatisfactionLevelList,
  getReasonsList,
  getNonConversionList,
  addHumanResourceInformation,
  getRatingTypeList,
  getStartEndDates,
  addCandidateRatingsInfo,
  getInfringementsTypeList,
  addCandidateInfringementsInfo,
  holidaysList,
  notesList,
  addNotesActivityInfo,
  statementsList,
  performanceTypeList,
  addPerformanceActivityInfo,
  tasksList,
  addTasksActivityInfo,
  smsHistoryList,
  sendSMSInfo,
  emailHistoryList,
  addSmartBoxInfo,
  candidateRatingList,
  candidateInfringementList,
  candidatePerformanceList,
  candidateSmartBoxList,
  updateCandidateRatings,
  updateCandidateInfringements,
  updateCandidatePerformances,
  updateCandidateNotes,
  updateCandidateSmartBox,
  searchUserList,
  candidateRatingDelete,
  candidateInfringementDelete,
  candidateNoteDelete,
  candidateTicketDelete,
  updateCandidateTicketsInfo,
  candidatePerformanceDelete,
  tagsListInfo,
  tagsListV2Info,
  addTagsListInfo,
  candidateDetailsInfo,
  updateCandidateDetailsInfo,
  contactDetailsInfo,
  updateContactDetailsInfo,
  additionalContactsInfo,
  updateAdditionalContactsInfo,
  workHistoryList,
  deletedAddressList,
  updateWorkHistoryList,
  inductionsBansList,
  updateInductionsBans,
  deleteInductionList,
  deletedBansList,
  timePreferenceList,
  payrollDetails,
  updatePayrollDetails,
  addDrivingLicenseInfo,
  drivingLicenseInfo,
  humanResourceInfo,
  updateHumanResourceInfo,
  addLicenceCapabilitiesInfo,
  addLicenseEndorsementInfo,
  updateLicenseEndorsementInfo,
  deleteLicenseEndorsementInfo,
  deleteWorkHistoryInfo,
  deleteLicenseCapabilities,
  addDigiCardInfo,
  deleteDigiCardInfo,
  updateDigiCardInfo,
  updateLicenceCapabilitiesInfo,
  getDigiCardInfo,
  getQualificationInfo,
  deleteQualificationInfo,
  updateQualificationInfo,
  remindersList,
  deleteReminderInfo,
  getAssignedTicketsInfo,
  payeStatementsList,
  deleteUserTagsInfo,
  listAttachmentsInfo,
  downloadDocumentInfo,
  deleteMultiDocumentInfo,
  deleteSmartBoxMessageInfo,
  refreshMetaCacheInfo,
  sendAppSMSInfo,
  getAppHistoryInfo,
  registerAppPersonInfo,
  detailPersonSearchInfo,
  sendBulkSMSInfo,
  sendSMSToPhoneNumberInfo,
  sendAvailabilityRequestInfo,
  sendCalendarUpdatedInfo,
  sendBookingDetailsInfo,
  sendBookingHoursQueryInfo,
  sendCalendarSummaryInfo,
  sendNoWorkTomorrowInfo,
  getCandidateLinksInfo,
  holidayShiftsAdjustmentsList,
  accruedHolidayAdjustmentsList,
  addAccruedHolidayAdjustmentsInfo,
  addHolidayShiftsAdjustmentsInfo,
  deleteAccruedHolidayAdjustmentsInfo,
  deleteHolidayShiftsAdjustmentsInfo,
  getRtwCheckInfo,
  holidayPeriodInfo,
  detailedBookingsInfo,
  candidateDefaultExpenseInfo,
  actionCandidateDefaultExpenseInfo,
  setPassword,
  getMRAppDetails,
  clearAppProfile,
  ClaimAppId,
  addPersonToLBAPI
});
