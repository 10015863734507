import { call } from "redux-saga/effects";
import { httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes";

export function* getCostAnalysisReport({ data }) {
  const {
    costAnalysis: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?siteObjectId=${data.siteObjectID}&dateRange=${data.DateRange}`,
    apiMethod: method,
    successAction: actions.COST_ANALYSIS_REPORT_SUCCESS,
    errorAction: actions.COST_ANALYSIS_REPORT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getKPIReport() {
  const {
    KPIReport: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.KPI_REPORT_SUCCESS,
    errorAction: actions.KPI_REPORT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getClientInfrigement({ data }) {
  const {
    clientInfringement: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.CLIENT_INFRIGEMENT_SUCCESS,
    errorAction: actions.CLIENT_INFRIGEMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getClientPerformance({ data }) {
  const {
    clientPerformance: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.CLIENT_PERFORMANCE_SUCCESS,
    errorAction: actions.CLIENT_PERFORMANCE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getClientPerformanceClose({ data }) {
  const {
    clientPerformanceClose: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.status}`,
    apiMethod: method,
    apiPayload: data.id,
    successAction: actions.CLIENT_PERFORMANCE_CLOSE_SUCCESS,
    errorAction: actions.CLIENT_PERFORMANCE_CLOSE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getClientInfrigementSign({ data }) {
  const {
    clientInfringementSign: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.status}`,
    apiMethod: method,
    apiPayload: data.id,
    successAction: actions.CLIENT_INFRIGEMENT_SIGN_SUCCESS,
    errorAction: actions.CLIENT_INFRIGEMENT_SIGN_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addClientInfo({ data }) {
  const {
    addClient: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${encodeURIComponent(data)}`,
    apiMethod: method,
    successAction: actions.ADD_CLIENT_INFO_SUCCESS,
    errorAction: actions.ADD_CLIENT_INFO_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateClientInfo({ data }) {
  const {
    updateClient: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${encodeURIComponent(data.clientName)}&clientObjectId=${data.clientObjectId}`,
    apiMethod: method,
    successAction: actions.UPDATE_CLIENT_INFO_SUCCESS,
    errorAction: actions.UPDATE_CLIENT_INFO_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteClientInfo({ data }) {
  const {
    deleteClient: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.DELETE_CLIENT_INFO_SUCCESS,
    errorAction: actions.DELETE_CLIENT_INFO_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
