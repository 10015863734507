import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes";
import {
  sendEmail,
  listEmail,
  sendSMS,
  listPhoneNumer,
  getLocation,
  addBookingRule
} from "../workers/siteModalWorker";

export function* watchSendEmail() {
  yield takeLatest(actions.SITE_LIST_EMAIL, sendEmail);
}

export function* watchListEmail() {
  yield takeLatest(actions.SITE_LIST_EMAIL_LIST, listEmail);
}

export function* watchlistPhoneNumer() {
  yield takeLatest(actions.SITE_LIST_SMS_LIST, listPhoneNumer);
}

export function* watchsendSMS() {
  yield takeLatest(actions.SITE_LIST_SMS, sendSMS);
}

export function* WatchgetLocation() {
  yield takeLatest(actions.VIEW_SITE_LOCATION, getLocation);
}

export function* watchAddBookingRule() {
  yield takeLatest(actions.ADD_BOOKING_RULE, addBookingRule);
}
