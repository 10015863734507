import { combineReducers } from "redux";

import actions from "../actionTypes";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const getGlobalSearch = createReducer(defaultState, {
  [actions.GLOBAL_SEARCH]: isLoadingStateReducer,
  [actions.GLOBAL_SEARCH_SUCCESS]: successStateReducer,
  [actions.GLOBAL_SEARCH_ERROR]: errorStateReducer,
  [actions.GLOBAL_SEARCH_CLEAR]: resetStateReducer
});

const getAddAvailability = createReducer(defaultState, {
  [actions.ADD_AVAILABILITY]: isLoadingStateReducer,
  [actions.ADD_AVAILABILITY_SUCCESS]: successStateReducer,
  [actions.ADD_AVAILABILITY_ERROR]: errorStateReducer,
  [actions.ADD_AVAILABILITY_CLEAR]: resetStateReducer
});

const getAddTimeOff = createReducer(defaultState, {
  [actions.ADD_TIMEOFF]: isLoadingStateReducer,
  [actions.ADD_TIMEOFF_SUCCESS]: successStateReducer,
  [actions.ADD_TIMEOFF_ERROR]: errorStateReducer,
  [actions.ADD_TIMEOFF_CLEAR]: resetStateReducer
});

const getRemoveAvailability = createReducer(defaultState, {
  [actions.REMOVE_AVAILABILITY]: isLoadingStateReducer,
  [actions.REMOVE_AVAILABILITY_SUCCESS]: successStateReducer,
  [actions.REMOVE_AVAILABILITY_ERROR]: errorStateReducer,
  [actions.REMOVE_AVAILABILITY_CLEAR]: resetStateReducer
});

const getDeleteTimeOff = createReducer(defaultState, {
  [actions.DELETE_TIMEOFF]: isLoadingStateReducer,
  [actions.DELETE_TIMEOFF_SUCCESS]: successStateReducer,
  [actions.DELETE_TIMEOFF_ERROR]: errorStateReducer,
  [actions.DELETE_TIMEOFF_CLEAR]: resetStateReducer
});

const getCandidateDuplicates = createReducer(defaultState, {
  [actions.CANDIDATE_DUPLICATES]: isLoadingStateReducer,
  [actions.CANDIDATE_DUPLICATES_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_DUPLICATES_ERROR]: errorStateReducer,
  [actions.CANDIDATE_DUPLICATES_CLEAR]: resetStateReducer
});

const getDeletedCandidates = createReducer(defaultState, {
  [actions.CANDIDATE_DELETE]: isLoadingStateReducer,
  [actions.CANDIDATE_DELETE_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_DELETE_ERROR]: errorStateReducer,
  [actions.CANDIDATE_DELETE_CLEAR]: resetStateReducer
});

const getMergeDeletedCandidates = createReducer(defaultState, {
  [actions.CANDIDATE_MERGE_DELETE]: isLoadingStateReducer,
  [actions.CANDIDATE_MERGE_DELETE_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_MERGE_DELETE_ERROR]: errorStateReducer,
  [actions.CANDIDATE_MERGE_DELETE_CLEAR]: resetStateReducer
});

const getDeletedPerson = createReducer(defaultState, {
  [actions.PERSON_DELETE]: isLoadingStateReducer,
  [actions.PERSON_DELETE_SUCCESS]: successStateReducer,
  [actions.PERSON_DELETE_ERROR]: errorStateReducer,
  [actions.PERSON_DELETE_CLEAR]: resetStateReducer
});

const getMergedPerson = createReducer(defaultState, {
  [actions.PERSON_DELETE_MERGE]: isLoadingStateReducer,
  [actions.PERSON_DELETE_MERGE_SUCCESS]: successStateReducer,
  [actions.PERSON_DELETE_MERGE_ERROR]: errorStateReducer,
  [actions.PERSON_DELETE_MERGE_CLEAR]: resetStateReducer
});

const getAttachNewCandidates = createReducer(defaultState, {
  [actions.ATTACH_NEW_CANDIDATE]: isLoadingStateReducer,
  [actions.ATTACH_NEW_CANDIDATE_SUCCESS]: successStateReducer,
  [actions.ATTACH_NEW_CANDIDATE_ERROR]: errorStateReducer,
  [actions.ATTACH_NEW_CANDIDATE_CLEAR]: resetStateReducer
});

const sendEmail = createReducer(defaultState, {
  [actions.CANDIDATE_EMAIL]: isLoadingStateReducer,
  [actions.CANDIDATE_EMAIL_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_EMAIL_ERROR]: errorStateReducer,
  [actions.CANDIDATE_EMAIL_CLEAR]: resetStateReducer
});

const listEmail = createReducer(defaultState, {
  [actions.CANDIDATE_EMAIL_LIST]: isLoadingStateReducer,
  [actions.CANDIDATE_EMAIL_LIST_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_EMAIL_LIST_ERROR]: errorStateReducer,
  [actions.CANDIDATE_EMAIL_LIST_CLEAR]: resetStateReducer
});

const listEmails = createReducer(defaultState, {
  [actions.EMAIL_LIST]: isLoadingStateReducer,
  [actions.EMAIL_LIST_SUCCESS]: successStateReducer,
  [actions.EMAIL_LIST_ERROR]: errorStateReducer,
  [actions.EMAIL_LIST_CLEAR]: resetStateReducer
});

const bookingCta = createReducer(defaultState, {
  [actions.CALENDAR_BOOKING_OPERATION]: isLoadingStateReducer,
  [actions.CALENDAR_BOOKING_OPERATION_SUCCESS]: successStateReducer,
  [actions.CALENDAR_BOOKING_OPERATION_ERROR]: errorStateReducer,
  [actions.CALENDAR_BOOKING_OPERATION_CLEAR]: resetStateReducer
});

const updateBookingDetailsData = createReducer(defaultState, {
  [actions.UPDATE_BOOKING_DETAILS_DATA]: isLoadingStateReducer,
  [actions.UPDATE_BOOKING_DETAILS_DATA_SUCCESS]: successStateReducer,
  [actions.UPDATE_BOOKING_DETAILS_DATA_ERROR]: errorStateReducer,
  [actions.UPDATE_BOOKING_DETAILS_DATA_CLEAR]: resetStateReducer
});

const confirmAllBookingReducer = createReducer(defaultState, {
  [actions.CONFIRM_ALL_BOOKING_OPERATION]: isLoadingStateReducer,
  [actions.CONFIRM_ALL_BOOKING_OPERATION_SUCCESS]: successStateReducer,
  [actions.CONFIRM_ALL_BOOKING_OPERATION_ERROR]: errorStateReducer,
  [actions.CONFIRM_ALL_BOOKING_OPERATION_CLEAR]: resetStateReducer
});

const getBookingDetails = createReducer(defaultState, {
  [actions.GET_BOOKING_DETAILS]: isLoadingStateReducer,
  [actions.GET_BOOKING_DETAILS_SUCCESS]: successStateReducer,
  [actions.GET_BOOKING_DETAILS_ERROR]: errorStateReducer,
  [actions.GET_BOOKING_DETAILS_CLEAR]: resetStateReducer
});

const getConfirmTimeOff = createReducer(defaultState, {
  [actions.CONFIRM_TIME_OFF]: isLoadingStateReducer,
  [actions.CONFIRM_TIME_OFF_SUCCESS]: successStateReducer,
  [actions.CONFIRM_TIME_OFF_ERROR]: errorStateReducer,
  [actions.CONFIRM_TIME_OFF_CLEAR]: resetStateReducer
});

const addCandidateImport = createReducer(defaultState, {
  [actions.ADD_CANDIDATE_IMPORT]: isLoadingStateReducer,
  [actions.ADD_CANDIDATE_IMPORT_SUCCESS]: successStateReducer,
  [actions.ADD_CANDIDATE_IMPORT_ERROR]: errorStateReducer,
  [actions.ADD_CANDIDATE_IMPORT_CLEAR]: resetStateReducer
});

const hoursWorkedImport = createReducer(defaultState, {
  [actions.HOURS_WORKED_IMPORT]: isLoadingStateReducer,
  [actions.HOURS_WORKED_IMPORT_SUCCESS]: successStateReducer,
  [actions.HOURS_WORKED_IMPORT_ERROR]: errorStateReducer,
  [actions.HOURS_WORKED_IMPORT_CLEAR]: resetStateReducer
});

const updatePersonNote = createReducer(defaultState, {
  [actions.UPDATE_PERSON_NOTE]: isLoadingStateReducer,
  [actions.UPDATE_PERSON_NOTE_SUCCESS]: successStateReducer,
  [actions.UPDATE_PERSON_NOTE_ERROR]: errorStateReducer,
  [actions.UPDATE_PERSON_NOTE_CLEAR]: resetStateReducer
});

export default combineReducers({
  getGlobalSearch,
  updateBookingDetailsData,
  hoursWorkedImport,
  listEmails,
  addCandidateImport,
  updatePersonNote,
  getCandidateDuplicates,
  getDeletedCandidates,
  getMergeDeletedCandidates,
  getDeletedPerson,
  getMergedPerson,
  getAttachNewCandidates,
  getAddAvailability,
  getAddTimeOff,
  sendEmail,
  listEmail,
  bookingCta,
  confirmAllBookingReducer,
  getRemoveAvailability,
  getDeleteTimeOff,
  getBookingDetails,
  getConfirmTimeOff
});
