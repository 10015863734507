import React from "react";
import { BrowserRouter as Router, Switch, Route, withRouter, Redirect } from "react-router-dom";
import Auth from "../config/auth";
import Login from "../modules/login";
import ForgetPassword from "../modules/forgotPassword";
import PrivateRoutes from "./routesGuard";
import NoAuth from "../modules/errors/401";
import NotFound from "../modules/errors/404";
import constants from "../constants/constants";
import RequestPasswordReset from "../modules/resetPassword/RequestPasswordReset";
import CompletePasswordReset from "../modules/resetPassword/CompletePasswordReset";

const {
  PAGE_ROUTES: {
    LOGIN,
    FORGET_PASSWORD,
    NOT_FOUND,
    NOT_AUTH,
    REQUEST_PASSWORD_RESET,
    COMPLETE_PASSWORD_RESET
  }
} = constants;

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={LOGIN} component={Login} />
        <Route exact path={FORGET_PASSWORD} component={ForgetPassword} />

        <Route exact path={REQUEST_PASSWORD_RESET} component={RequestPasswordReset} />
        <Route exact path={COMPLETE_PASSWORD_RESET} component={CompletePasswordReset} />

        {PrivateRoutes}
        {!Auth.isAuthenticated() && <Redirect to={LOGIN} />}
        <Route path={NOT_AUTH} component={NoAuth} />
        <Route path={NOT_FOUND} component={NotFound} />
        <Redirect to={NOT_FOUND} />
      </Switch>
    </Router>
  );
}

export default React.memo(withRouter(App));
