/* eslint-disable no-unused-vars */
import { put, call } from "redux-saga/effects";
import axios from "./axiosSetup";
import constants from "../../constants/constants";
import { apiFailureAlert, apiSuccessAlert } from "../actionCreators";

const {
  HTTP_REQUEST_METHODS: { GET },
  HTTP_MULTIPART_FORM_HEADERS,
  API_ERROR_MSG
} = constants;

export const defaultPayload = {
  url: null,
  apiPayload: {},
  apiMethod: GET,
  successAction: null,
  errorAction: null,
  additionalInfo: {}
};

export const createDispatchObject = (
  actionType,
  result = null,
  additionalInfo = {},
  error = null,
  fetching = false
) => ({
  type: actionType,
  result,
  fetching,
  ...additionalInfo,
  error
});

const axiosApiRequest = ({ url, apiMethod, apiPayload = "" }) => {
  return axios({
    method: apiMethod,
    url,
    data: JSON.stringify(apiPayload)
  });
};

export const axiosApiRequestForMultiPartData = ({ url, apiMethod, apiPayload = "" }) => {
  return axios({
    method: apiMethod,
    url,
    data: apiPayload,
    headers: {
      Accept: "application/x-www-form-urlencoded",
      "content-type": HTTP_MULTIPART_FORM_HEADERS["Content-Type"]
    }
  });
};

export const axiosApiRequestDownload = ({ url, apiMethod, apiPayload = "" }) => {
  return axios({
    method: apiMethod,
    url,
    data: JSON.stringify(apiPayload),
    responseType: "blob"
  });
};

export function* httpRequestForData(payLoad = defaultPayload) {
  const {
    url,
    apiMethod,
    apiPayload,
    successAction,
    errorAction,
    additionalInfo,
    successMessage,
    popUp,
    errorMessage,
    axiosRequest = axiosApiRequest
  } = payLoad;
  try {
    const result = yield call(axiosRequest, {
      url,
      apiMethod,
      apiPayload
    });
    if (popUp) {
      yield put(apiSuccessAlert(successMessage));
    }
    yield put(createDispatchObject(successAction, result.data, additionalInfo));
    return result;
  } catch (error) {
    // console.log("errorroore", error);
    const BookingStatusErrorMsg = Array.isArray(error?.response?.data?.BookingStatus)
      ? error?.response?.data?.BookingStatus?.[0]?.ErrorMessages?.string
      : error?.response?.data?.BookingStatus;
    const verifyBookingErros = Array.isArray(
      error?.response?.data?.Data?.ValidationResponse?.ErrorMessages
    )
      ? undefined
      : error?.response?.data?.Data?.ValidationResponse?.ErrorMessages?.string;
    const errorMsg =
      error?.response?.data?.ErrorMessage?.ErrorMessage ||
      error?.response?.data?.ErrorMessage ||
      error?.response?.data?.Detail ||
      BookingStatusErrorMsg ||
      verifyBookingErros ||
      errorMessage ||
      API_ERROR_MSG;
    yield put(
      createDispatchObject(
        errorAction,
        {
          ...error
        },
        additionalInfo,
        error
      )
    );
    if (
      error?.response?.data?.Data?.ResponseCode !== "UnknownError" &&
      !error?.response?.data?.Data?.RequirmentResults
    ) {
      // condition commented !error?.response?.data?.Data?.ValidationResponses
      yield put(apiFailureAlert(errorMsg));
    }
    return error;
    // throw new Error(error);
  }
}

export default axiosApiRequest;
