import { lazy } from "react";

const ApiMessage = lazy(() => import("../components/apiMessage"));
const Dashboard = lazy(() => import("../modules/dashboard"));
const Calendar = lazy(() => import("../modules/calendar"));
const DriverListing = lazy(() => import("../modules/driverListing"));
const DriverCalendar = lazy(() => import("../modules/driverCalendar"));
const CandidateListing = lazy(() => import("../modules/candidateListing"));
const DailyBooking = lazy(() => import("../modules/dailyBooking"));
const Booking = lazy(() => import("../modules/booking"));
const Payroll = lazy(() => import("../modules/payroll"));
const CandidateDetails = lazy(() => import("../modules/driverNavs"));
const Header = lazy(() => import("../components/header"));
const SubHeader = lazy(() => import("../components/subHeader"));
const Footer = lazy(() => import("../components/footer"));
const SiteListing = lazy(() => import("../modules/siteListing/index"));
const AddSiteDetails = lazy(() => import("../modules/siteNavs"));
const CandidateActivity = lazy(() => import("../modules/candidateActivity"));
const BookingCalendar = lazy(() => import("../modules/bookingCalendar"));
const WeeklyCalendar = lazy(() => import("../modules/bookingCalendar/weeklyBookingCalendar"));
const SiteActivity = lazy(() => import("../modules/siteActivity/siteActivityTab"));
const UserManagement = lazy(() => import("../modules/userManagement/userManagementTabs"));
const PendingDocuments = lazy(() => import("../modules/pendingDocuments/pendingDocuments"));
const GeneratePayrate = lazy(() => import("../modules/generatePayrate/generatePayrate"));
const FinanceHoliday = lazy(() => import("../modules/financeHoliday"));
const Document = lazy(() => import("../modules/documents/document"));
const CostAnalysis = lazy(() => import("../modules/costAnalysis/costAnalysis"));
const Infringement = lazy(() => import("../modules/infringement/infringement"));
const CandidatePerformance = lazy(() =>
  import("../modules/candidatePerformance/candidatePerformance")
);
const BookingSetup = lazy(() => import("../modules/bookingSetup/bookingSetupNav/bookingNav"));
const PayRateListing = lazy(() => import("../modules/managePayRates/payRateListing"));
const PayRateModal = lazy(() => import("../modules/managePayRates/addPayRate/payRateMenu"));
const GeneratePayRate = lazy(() => import("../modules/siteNavs/generalDetailsStepper"));
const VerifyBooking = lazy(() => import("../modules/verifyBooking"));
const SupplierActivityTabs = lazy(() => import("../modules/supplierManagement"));
const ClientManagement = lazy(() => import("../modules/clientManagement"));
const Reports = lazy(() => import("../modules/reports/report"));
const AssignmentSchedules = lazy(() => import("../modules/assignmentSchedules/index.js"));

export {
  ApiMessage,
  Dashboard,
  Calendar,
  Booking,
  Payroll,
  Header,
  SubHeader,
  Footer,
  CandidateListing,
  CandidateDetails,
  DriverListing,
  DriverCalendar,
  SiteListing,
  AddSiteDetails,
  DailyBooking,
  CandidateActivity,
  BookingCalendar,
  WeeklyCalendar,
  SiteActivity,
  UserManagement,
  PendingDocuments,
  GeneratePayrate,
  Document,
  CostAnalysis,
  Infringement,
  CandidatePerformance,
  BookingSetup,
  PayRateListing,
  PayRateModal,
  GeneratePayRate,
  VerifyBooking,
  SupplierActivityTabs,
  ClientManagement,
  Reports,
  FinanceHoliday,
  AssignmentSchedules
};
