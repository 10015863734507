import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes";
import {
  getPendingDocumentList,
  getPayDocumentList,
  holdPendingDocument,
  unHoldDocument,
  deletePendingDocument,
  modifyPayDocument,
  sendToXero,
  viewPayDocument,
  payRateListing,
  addAdjustment,
  updatePayDocument,
  createPayDocument,
  deletePayRate,
  buildPayDocuments,
  viewPayRate,
  updateRateRules,
  updateRateCard,
  addPayRateCards,
  addpayRateRule,
  getBuildProgress,
  viewPayRateHistory,
  copyPayRate,
  getUserClientList,
  getVerfiyBooking,
  deleteRateCard,
  mergeDocuments,
  updateAdjustment,
  deleteAdjustment,
  getHolidayListing,
  deleteMultipleHolidays
} from "../workers/financeWorker";

export function* watchGetPendingDocument() {
  yield takeLatest(actions.PENDING_DOCUMENT_LIST, getPendingDocumentList);
}

export function* watchGetPayDocument() {
  yield takeLatest(actions.PAY_DOCUMENT_LIST, getPayDocumentList);
}

export function* watchHoldDocument() {
  yield takeLatest(actions.HOLD_DOCUMENT, holdPendingDocument);
}
export function* watchUnHoldDocument() {
  yield takeLatest(actions.UNHOLD_DOCUMENT, unHoldDocument);
}
export function* watchDeletePendingDocument() {
  yield takeLatest(actions.DELETE_PENDING_DOCUMENT, deletePendingDocument);
}

export function* watchModifyPayDocument() {
  yield takeLatest(actions.MODIFY_PAY_DOCUMENT, modifyPayDocument);
}

export function* watchSendToXero() {
  yield takeLatest(actions.SEND_TO_XERO, sendToXero);
}

export function* watchViewPayDocument() {
  yield takeLatest(actions.VIEW_DOCUMENT, viewPayDocument);
}

export function* watchPayRateListing() {
  yield takeLatest(actions.PAY_RATE_LISTING, payRateListing);
}

export function* watchGetHolidayListing() {
  yield takeLatest(actions.HOLIDAY_LISTING, getHolidayListing);
}

export function* watchDeleteMultipleHolidays() {
  yield takeLatest(actions.DELETE_MULTIPLE_HOLIDAYS, deleteMultipleHolidays);
}

export function* watchAddAdjustment() {
  yield takeLatest(actions.ADD_ADJUSTMENT, addAdjustment);
}
export function* watchUpdatePayDocument() {
  yield takeLatest(actions.UPDATE_PAY_DOCUMENT, updatePayDocument);
}

export function* watchUpdateAdjustment() {
  yield takeLatest(actions.UPDATE_ADJUSTMENT, updateAdjustment);
}

export function* watchDeleteAdjustment() {
  yield takeLatest(actions.DELETE_ADJUSTMENT, deleteAdjustment);
}

export function* watchCreatePayDocument() {
  yield takeLatest(actions.CREATE_PAY_DOCUMENT, createPayDocument);
}

export function* watchDeletePayRate() {
  yield takeLatest(actions.DELETE_PAY_RATE, deletePayRate);
}

export function* watchBuildPayDocuments() {
  yield takeLatest(actions.BUILD_PAY_DOCUMENTS, buildPayDocuments);
}

export function* watchAddPayRules() {
  yield takeLatest(actions.ADD_PAY_RATE_RULES, addpayRateRule);
}
export function* watchAddPayCard() {
  yield takeLatest(actions.ADD_RATE_CARD, addPayRateCards);
}
export function* watchupdateRateCard() {
  yield takeLatest(actions.UPDATE_RATE_CARD, updateRateCard);
}

export function* watchDeleteRateCard() {
  yield takeLatest(actions.DELETE_RATE_CARD, deleteRateCard);
}

export function* watchupdateRateRules() {
  yield takeLatest(actions.UPDATE_PAY_RATE_RULES, updateRateRules);
}

export function* watchviewPayRate() {
  yield takeLatest(actions.VIEW_PAY_RATE, viewPayRate);
}
export function* watchGetBuildProgress() {
  yield takeLatest(actions.BUILD_PAY_DOCUMENTS_PROGRESS, getBuildProgress);
}

export function* watchViewPayRateHistory() {
  yield takeLatest(actions.VIEW_PAY_RATE_HISTORY, viewPayRateHistory);
}

export function* watchCopyPayRate() {
  yield takeLatest(actions.COPY_PAY_RATE, copyPayRate);
}
export function* watchGetUserClientList() {
  yield takeLatest(actions.GET_USER_CLIENT_LIST, getUserClientList);
}
export function* watchGetVerifyBookingList() {
  yield takeLatest(actions.GET_VERIFY_BOOKING_LIST, getVerfiyBooking);
}

export function* watchMergeDocument() {
  yield takeLatest(actions.MERGE_DOCUMENT, mergeDocuments);
}
