import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes/calendar";
import {
  getDailyBookingList,
  getMonthlyBookingList,
  getWeeklyBookingList,
  getMonthlyBookingsOverview,
  updateNightOut,
  holdFromAppSingle,
  holdFromAppMultiple
} from "../workers/calendarWorker";

export function* watchGetDailyBookingList() {
  yield takeLatest(actions.DAILY_BOOKING_LIST, getDailyBookingList);
}

export function* watchGetMonthlyBookingList() {
  yield takeLatest(actions.MONTHLY_BOOKING_LIST, getMonthlyBookingList);
}

export function* watchGetWeeklyBookingList() {
  yield takeLatest(actions.WEEKLY_BOOKING_LIST, getWeeklyBookingList);
}

export function* watchGetMonthlyBookingsOverview() {
  yield takeLatest(actions.MONTHLY_BOOKING_LIST_OVERVIEW, getMonthlyBookingsOverview);
}

export function* watchUpdateNightOut() {
  yield takeLatest(actions.NIGHT_OUT_UPDATE, updateNightOut);
}

export function* watchHoldFromAppSingle() {
  yield takeLatest(actions.HOLD_FROM_APP_SINGLE, holdFromAppSingle);
}

export function* watchHoldFromAppMultiple() {
  yield takeLatest(actions.HOLD_FROM_APP_MULTIPLE, holdFromAppMultiple);
}
