/* eslint-disable no-console */
import React, { Component } from "react";
import ErrorHandler from "./errorHandler";
import labels from "../../constants/labels";

const {
  ERROR_PAGE: { COMP_ERROR }
} = labels;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo, hasError: true });
  }

  render() {
    const { errorInfo, error, hasError } = this.state;
    const { children, history } = this.props;
    if (error || errorInfo) {
      console.error({ componentError: error?.toString() });
      console.error({ componentErrorInfo: errorInfo?.componentStack });
    }
    if (hasError) {
      return <ErrorHandler errorCase={COMP_ERROR} history={history} />;
    }

    return children;
  }
}

export default ErrorBoundary;
