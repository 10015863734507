import keyMirror from "keymirror";

const actions = {
  GET_USERLIST: null,
  GET_USERLIST_SUCCESS: null,
  GET_USERLIST_ERROR: null,
  GET_USERLIST_CLEAR: null,

  GET_CONTRACT_TYPE_LIST: null,
  GET_CONTRACT_TYPE_LIST_SUCCESS: null,
  GET_CONTRACT_TYPE_LIST_ERROR: null,
  GET_CONTRACT_TYPE_LIST_CLEAR: null,

  ADD_CONTRACT_TYPE: null,
  ADD_CONTRACT_TYPE_SUCCESS: null,
  ADD_CONTRACT_TYPE_ERROR: null,
  ADD_CONTRACT_TYPE_CLEAR: null,

  UPDATE_CONTRACT_TYPE: null,
  UPDATE_CONTRACT_TYPE_SUCCESS: null,
  UPDATE_CONTRACT_TYPE_ERROR: null,
  UPDATE_CONTRACT_TYPE_CLEAR: null,

  DELETE_CONTRACT_TYPE: null,
  DELETE_CONTRACT_TYPE_SUCCESS: null,
  DELETE_CONTRACT_TYPE_ERROR: null,
  DELETE_CONTRACT_TYPE_CLEAR: null,
  // Qualifications
  GET_QUALIFICATION_LIST: null,
  GET_QUALIFICATION_LIST_SUCCESS: null,
  GET_QUALIFICATION_LIST_ERROR: null,
  GET_QUALIFICATION_LIST_CLEAR: null,

  ADD_QUALIFICATION_TYPE: null,
  ADD_QUALIFICATION_TYPE_SUCCESS: null,
  ADD_QUALIFICATION_TYPE_ERROR: null,
  ADD_QUALIFICATION_TYPE_CLEAR: null,

  DELETE_QUALIFICATION_TYPE: null,
  DELETE_QUALIFICATION_TYPE_SUCCESS: null,
  DELETE_QUALIFICATION_TYPE_ERROR: null,
  DELETE_QUALIFICATION_TYPE_CLEAR: null,

  UPDATE_QUALIFICATION_ENTRY: null,
  UPDATE_QUALIFICATION_ENTRY_SUCCESS: null,
  UPDATE_QUALIFICATION_ENTRY_ERROR: null,
  UPDATE_QUALIFICATION_ENTRY_CLEAR: null,

  GET_GROUP_USERS_LIST: null,
  GET_GROUP_USERS_LIST_SUCCESS: null,
  GET_GROUP_USERS_LIST_ERROR: null,
  GET_GROUP_USERS_LIST_CLEAR: null,

  DELETE_USER_GROUP: null,
  DELETE_USER_GROUP_SUCCESS: null,
  DELETE_USER_GROUP_ERROR: null,
  DELETE_USER_GROUP_CLEAR: null,

  ADD_USER_GROUP: null,
  ADD_USER_GROUP_SUCCESS: null,
  ADD_USER_GROUP_ERROR: null,
  ADD_USER_GROUP_CLEAR: null,

  GET_EXPENSE_TYPE_BOOKING: null,
  GET_EXPENSE_TYPE_BOOKING_SUCCESS: null,
  GET_EXPENSE_TYPE_BOOKING_ERROR: null,
  GET_EXPENSE_TYPE_BOOKING_CLEAR: null,

  ADD_NEW_BOOKING_EXPENSE: null,
  ADD_NEW_BOOKING_EXPENSE_SUCCESS: null,
  ADD_NEW_BOOKING_EXPENSE_ERROR: null,
  ADD_NEW_BOOKING_EXPENSE_CLEAR: null,

  DELETE_BOOKING_EXPENSE_TYPE: null,
  DELETE_BOOKING_EXPENSE_TYPE_SUCCESS: null,
  DELETE_BOOKING_EXPENSE_TYPE_ERROR: null,
  DELETE_BOOKING_EXPENSE_TYPE_CLEAR: null,

  GET_TAG_NAME: null,
  GET_TAG_NAME_SUCCESS: null,
  GET_TAG_NAME_ERROR: null,
  GET_TAG_NAME_CLEAR: null,

  DELETE_TAG_NAME: null,
  DELETE_TAG_NAME_SUCCESS: null,
  DELETE_TAG_NAME_ERROR: null,
  DELETE_TAG_NAME_CLEAR: null,

  CREATE_TAG_NAME: null,
  CREATE_TAG_NAME_SUCCESS: null,
  CREATE_TAG_NAME_ERROR: null,
  CREATE_TAG_NAME_CLEAR: null,

  UPDATE_TAG_NAME: null,
  UPDATE_TAG_NAME_SUCCESS: null,
  UPDATE_TAG_NAME_ERROR: null,
  UPDATE_TAG_NAME_CLEAR: null,

  GET_CANDIDATE_RATING_TYPES: null,
  GET_CANDIDATE_RATING_TYPES_SUCCESS: null,
  GET_CANDIDATE_RATING_TYPES_ERROR: null,
  GET_CANDIDATE_RATING_TYPES_CLEAR: null,

  DELETE_CANDIDATE_RATING_TYPES: null,
  DELETE_CANDIDATE_RATING_TYPES_SUCCESS: null,
  DELETE_CANDIDATE_RATING_TYPES_ERROR: null,
  DELETE_CANDIDATE_RATING_TYPES_CLEAR: null,

  ADD_CANDIDATE_RATING_TYPES: null,
  ADD_CANDIDATE_RATING_TYPES_SUCCESS: null,
  ADD_CANDIDATE_RATING_TYPES_ERROR: null,
  ADD_CANDIDATE_RATING_TYPES_CLEAR: null,

  UPDATE_CANDIDATE_RATING_TYPES: null,
  UPDATE_CANDIDATE_RATING_TYPES_SUCCESS: null,
  UPDATE_CANDIDATE_RATING_TYPES_ERROR: null,
  UPDATE_CANDIDATE_RATING_TYPES_CLEAR: null,

  UPDATE_EXPENSE_TYPES: null,
  UPDATE_EXPENSE_TYPES_SUCCESS: null,
  UPDATE_EXPENSE_TYPES_ERROR: null,
  UPDATE_EXPENSE_TYPES_CLEAR: null,

  UPDATE_GROUP_NAME: null,
  UPDATE_GROUP_NAME_SUCCESS: null,
  UPDATE_GROUP_NAME_ERROR: null,
  UPDATE_GROUP_NAME_CLEAR: null,

  GET_INFRINGEMENT_TYPE: null,
  GET_INFRINGEMENT_TYPE_SUCCESS: null,
  GET_INFRINGEMENT_TYPE_ERROR: null,
  GET_INFRINGEMENT_TYPE_CLEAR: null,

  DELETE_INFRINGEMENT_TYPE: null,
  DELETE_INFRINGEMENT_TYPE_SUCCESS: null,
  DELETE_INFRINGEMENT_TYPE_ERROR: null,
  DELETE_INFRINGEMENT_TYPE_CLEAR: null,

  ADD_INFRINGEMENT_TYPE: null,
  ADD_INFRINGEMENT_TYPE_SUCCESS: null,
  ADD_INFRINGEMENT_TYPE_ERROR: null,
  ADD_INFRINGEMENT_TYPE_CLEAR: null,

  UPDATE_INFRINGEMENT_TYPE: null,
  UPDATE_INFRINGEMENT_TYPE_SUCCESS: null,
  UPDATE_INFRINGEMENT_TYPE_ERROR: null,
  UPDATE_INFRINGEMENT_TYPE_CLEAR: null,

  GET_PERFORMANCE_TYPES: null,
  GET_PERFORMANCE_TYPES_SUCCESS: null,
  GET_PERFORMANCE_TYPES_ERROR: null,
  GET_PERFORMANCE_TYPES_CLEAR: null,

  ADD_PERFORMANCE_TYPES: null,
  ADD_PERFORMANCE_TYPES_SUCCESS: null,
  ADD_PERFORMANCE_TYPES_ERROR: null,
  ADD_PERFORMANCE_TYPES_CLEAR: null,

  DELETE_PERFORMANCE_TYPES: null,
  DELETE_PERFORMANCE_TYPES_SUCCESS: null,
  DELETE_PERFORMANCE_TYPES_ERROR: null,
  DELETE_PERFORMANCE_TYPES_CLEAR: null,

  UPDATE_PERFORMANCE_TYPES: null,
  UPDATE_PERFORMANCE_TYPES_SUCCESS: null,
  UPDATE_PERFORMANCE_TYPES_ERROR: null,
  UPDATE_PERFORMANCE_TYPES_CLEAR: null,

  GET_NON_CONVERSION_LIST: null,
  GET_NON_CONVERSION_LIST_SUCCESS: null,
  GET_NON_CONVERSION_LIST_ERROR: null,
  GET_NON_CONVERSION_LIST_CLEAR: null,

  GET_INSURANCE_CATEGORY_LIST: null,
  GET_INSURANCE_CATEGORY_LIST_SUCCESS: null,
  GET_INSURANCE_CATEGORY_LIST_ERROR: null,
  GET_INSURANCE_CATEGORY_LIST_CLEAR: null,

  DELETE_INSURANCE_CATEGORY: null,
  DELETE_INSURANCE_CATEGORY_SUCCESS: null,
  DELETE_INSURANCE_CATEGORY_ERROR: null,
  DELETE_INSURANCE_CATEGORY_CLEAR: null,

  ADD_INSURANCE_CATEGORY: null,
  ADD_INSURANCE_CATEGORY_SUCCESS: null,
  ADD_INSURANCE_CATEGORY_ERROR: null,
  ADD_INSURANCE_CATEGORY_CLEAR: null,

  UPDATE_INSURANCE_CATEGORY: null,
  UPDATE_INSURANCE_CATEGORY_SUCCESS: null,
  UPDATE_INSURANCE_CATEGORY_ERROR: null,
  UPDATE_INSURANCE_CATEGORY_CLEAR: null,

  GET_LEAD_SOURCE_TYPES: null,
  GET_LEAD_SOURCE_TYPES_SUCCESS: null,
  GET_LEAD_SOURCE_TYPES_ERROR: null,
  GET_LEAD_SOURCE_TYPES_CLEAR: null,

  ADD_NON_CONVERSION_TYPE: null,
  ADD_NON_CONVERSION_TYPE_SUCCESS: null,
  ADD_NON_CONVERSION_TYPE_ERROR: null,
  ADD_NON_CONVERSION_TYPE_CLEAR: null,

  UPDATE_NON_CONVERSION_TYPE: null,
  UPDATE_NON_CONVERSION_TYPE_SUCCESS: null,
  UPDATE_NON_CONVERSION_TYPE_ERROR: null,
  UPDATE_NON_CONVERSION_TYPE_CLEAR: null,

  DELETE_NON_CONVERSION_TYPE: null,
  DELETE_NON_CONVERSION_TYPE_SUCCESS: null,
  DELETE_NON_CONVERSION_TYPE_ERROR: null,
  DELETE_NON_CONVERSION_TYPE_CLEAR: null,

  ADD_LEAD_SOURCE_TYPES: null,
  ADD_LEAD_SOURCE_TYPES_SUCCESS: null,
  ADD_LEAD_SOURCE_TYPES_ERROR: null,
  ADD_LEAD_SOURCE_TYPES_CLEAR: null,

  UPDATE_LEAD_SOURCE_TYPE: null,
  UPDATE_LEAD_SOURCE_TYPE_SUCCESS: null,
  UPDATE_LEAD_SOURCE_TYPE_ERROR: null,
  UPDATE_LEAD_SOURCE_TYPE_CLEAR: null,

  DELETE_LEAD_SOURCE_TYPES: null,
  DELETE_LEAD_SOURCE_TYPES_SUCCESS: null,
  DELETE_LEAD_SOURCE_TYPES_ERROR: null,
  DELETE_LEAD_SOURCE_TYPES_CLEAR: null,

  GET_LEAVING_REASON_TYPE: null,
  GET_LEAVING_REASON_TYPE_SUCCESS: null,
  GET_LEAVING_REASON_TYPE_ERROR: null,
  GET_LEAVING_REASON_TYPE_CLEAR: null,

  ADD_LEAVING_REASON_TYPE: null,
  ADD_LEAVING_REASON_TYPE_SUCCESS: null,
  ADD_LEAVING_REASON_TYPE_ERROR: null,
  ADD_LEAVING_REASON_TYPE_CLEAR: null,

  UPDATE_LEAVING_REASON_TYPE: null,
  UPDATE_LEAVING_REASON_TYPE_SUCCESS: null,
  UPDATE_LEAVING_REASON_TYPE_ERROR: null,
  UPDATE_LEAVING_REASON_TYPE_CLEAR: null,

  DELETE_LEAVING_REASON_TYPE: null,
  DELETE_LEAVING_REASON_TYPE_SUCCESS: null,
  DELETE_LEAVING_REASON_TYPE_ERROR: null,
  DELETE_LEAVING_REASON_TYPE_CLEAR: null,

  // BANK HOLIDAYS
  ADD_BANK_HOLIDAY: null,
  ADD_BANK_HOLIDAY_SUCCESS: null,
  ADD_BANK_HOLIDAY_ERROR: null,
  ADD_BANK_HOLIDAY_CLEAR: null,

  UPDATE_BANK_HOLIDAY: null,
  UPDATE_BANK_HOLIDAY_SUCCESS: null,
  UPDATE_BANK_HOLIDAY_ERROR: null,
  UPDATE_BANK_HOLIDAY_CLEAR: null,

  DELETE_BANK_HOLIDAY: null,
  DELETE_BANK_HOLIDAY_SUCCESS: null,
  DELETE_BANK_HOLIDAY_ERROR: null,
  DELETE_BANK_HOLIDAY_CLEAR: null,

  GET_BANK_HOLIDAY: null,
  GET_BANK_HOLIDAY_SUCCESS: null,
  GET_BANK_HOLIDAY_ERROR: null,
  GET_BANK_HOLIDAY_CLEAR: null,

  GET_BOOKING_TYPE: null,
  GET_BOOKING_TYPE_SUCCESS: null,
  GET_BOOKING_TYPE_ERROR: null,
  GET_BOOKING_TYPE_CLEAR: null,

  ADD_BOOKING_TYPE: null,
  ADD_BOOKING_TYPE_SUCCESS: null,
  ADD_BOOKING_TYPE_ERROR: null,
  ADD_BOOKING_TYPE_CLEAR: null,

  UPDATE_BOOKING_TYPE: null,
  UPDATE_BOOKING_TYPE_SUCCESS: null,
  UPDATE_BOOKING_TYPE_ERROR: null,
  UPDATE_BOOKING_TYPE_CLEAR: null,

  GET_SERVICE_COMPANIES: null,
  GET_SERVICE_COMPANIES_SUCCESS: null,
  GET_SERVICE_COMPANIES_ERROR: null,
  GET_SERVICE_COMPANIES_CLEAR: null,

  GET_IDENTIFICATION_NAME_LIST: null,
  GET_IDENTIFICATION_NAME_LIST_SUCCESS: null,
  GET_IDENTIFICATION_NAME_LIST_ERROR: null,
  GET_IDENTIFICATION_NAME_LIST_CLEAR: null,

  ADD_IDENTIFICATION_NAME_LIST: null,
  ADD_IDENTIFICATION_NAME_LIST_SUCCESS: null,
  ADD_IDENTIFICATION_NAME_LIST_ERROR: null,
  ADD_IDENTIFICATION_NAME_LIST_CLEAR: null,

  DELETE_BOOKING_TYPE: null,
  DELETE_BOOKING_TYPE_SUCCESS: null,
  DELETE_BOOKING_TYPE_ERROR: null,
  DELETE_BOOKING_TYPE_CLEAR: null,

  UPDATE_IDENTIFICATION_TYPE: null,
  UPDATE_IDENTIFICATION_TYPE_SUCCESS: null,
  UPDATE_IDENTIFICATION_TYPE_ERROR: null,
  UPDATE_IDENTIFICATION_TYPE_CLEAR: null,

  DELETE_IDENTIFICATION_TYPE: null,
  DELETE_IDENTIFICATION_TYPE_SUCCESS: null,
  DELETE_IDENTIFICATION_TYPE_ERROR: null,
  DELETE_IDENTIFICATION_TYPE_CLEAR: null,

  GET_PHONE_NUMBER_LIST: null,
  GET_PHONE_NUMBER_LIST_SUCCESS: null,
  GET_PHONE_NUMBER_LIST_ERROR: null,
  GET_PHONE_NUMBER_LIST_CLEAR: null,

  GET_CLIENT_DOCUMENT_LIST: null,
  GET_CLIENT_DOCUMENT_LIST_SUCCESS: null,
  GET_CLIENT_DOCUMENT_LIST_ERROR: null,
  GET_CLIENT_DOCUMENT_LIST_CLEAR: null,

  ADD_CLIENT_DOCUMENT: null,
  ADD_CLIENT_DOCUMENT_SUCCESS: null,
  ADD_CLIENT_DOCUMENT_ERROR: null,
  ADD_CLIENT_DOCUMENT_CLEAR: null,

  UPDATE_CLIENT_DOCUMENT: null,
  UPDATE_CLIENT_DOCUMENT_SUCCESS: null,
  UPDATE_CLIENT_DOCUMENT_ERROR: null,
  UPDATE_CLIENT_DOCUMENT_CLEAR: null,

  DELETE_CLIENT_DOCUMENT: null,
  DELETE_CLIENT_DOCUMENT_SUCCESS: null,
  DELETE_CLIENT_DOCUMENT_ERROR: null,
  DELETE_CLIENT_DOCUMENT_CLEAR: null,

  GET_CONTACT: null,
  GET_CONTACT_SUCCESS: null,
  GET_CONTACT_ERROR: null,
  GET_CONTACT_CLEAR: null,

  ADD_SERVICE_COMPANY: null,
  ADD_SERVICE_COMPANY_SUCCESS: null,
  ADD_SERVICE_COMPANY_ERROR: null,
  ADD_SERVICE_COMPANY_CLEAR: null,

  UPDATE_SERVICE_COMPANY: null,
  UPDATE_SERVICE_COMPANY_SUCCESS: null,
  UPDATE_SERVICE_COMPANY_ERROR: null,
  UPDATE_SERVICE_COMPANY_CLEAR: null,

  DELETE_SERVICE_COMPANY: null,
  DELETE_SERVICE_COMPANY_SUCCESS: null,
  DELETE_SERVICE_COMPANY_ERROR: null,
  DELETE_SERVICE_COMPANY_CLEAR: null,

  GET_START_DAY: null,
  GET_START_DAY_SUCCESS: null,
  GET_START_DAY_ERROR: null,
  GET_START_DAY_CLEAR: null,

  SEND_PAY_EMAIL: null,
  SEND_PAY_EMAIL_SUCCESS: null,
  SEND_PAY_EMAIL_ERROR: null,
  SEND_PAY_EMAIL_CLEAR: null,

  GET_ALL_SITES: null,
  GET_ALL_SITES_SUCCESS: null,
  GET_ALL_SITES_ERROR: null,
  GET_ALL_SITES_CLEAR: null,

  GET_ALL_REASON_CODES: null,
  GET_ALL_REASON_CODES_SUCCESS: null,
  GET_ALL_REASON_CODES_ERROR: null,
  GET_ALL_REASON_CODES_CLEAR: null,

  DELETE_REASON_CODE: null,
  DELETE_REASON_CODE_SUCCESS: null,
  DELETE_REASON_CODE_ERROR: null,
  DELETE_REASON_CODE_CLEAR: null,

  ADD_REASON_CODE: null,
  ADD_REASON_CODE_SUCCESS: null,
  ADD_REASON_CODE_ERROR: null,
  ADD_REASON_CODE_CLEAR: null,

  GET_ALL_MOBILE_APP_LINKS: null,
  GET_ALL_MOBILE_APP_LINKS_SUCCESS: null,
  GET_ALL_MOBILE_APP_LINKS_ERROR: null,
  GET_ALL_MOBILE_APP_LINKS_CLEAR: null,

  UPDATE_REASON_CODE: null,
  UPDATE_REASON_CODE_SUCCESS: null,
  UPDATE_REASON_CODE_ERROR: null,
  UPDATE_REASON_CODE_CLEAR: null,

  ADD_MOBILE_APP_LINK: null,
  ADD_MOBILE_APP_LINK_SUCCESS: null,
  ADD_MOBILE_APP_LINK_ERROR: null,
  ADD_MOBILE_APP_LINK_CLEAR: null,

  UPDATE_MOBILE_APP_LINK: null,
  UPDATE_MOBILE_APP_LINK_SUCCESS: null,
  UPDATE_MOBILE_APP_LINK_ERROR: null,
  UPDATE_MOBILE_APP_LINK_CLEAR: null,

  DELETE_MOBILE_APP_LINK: null,
  DELETE_MOBILE_APP_LINK_SUCCESS: null,
  DELETE_MOBILE_APP_LINK_ERROR: null,
  DELETE_MOBILE_APP_LINK_CLEAR: null,

  GET_ALL_SITES_PAGED: null,
  GET_ALL_SITES_PAGED_SUCCESS: null,
  GET_ALL_SITES_PAGED_ERROR: null,
  GET_ALL_SITES_PAGED_CLEAR: null
};

export default keyMirror(actions);
