const ROLES_OBJECT = {
  OPERATOR: "Operator",
  AGENCY_USER: "Planning",
  FINANCE_USER: "Finance",
  AGENCY_ADMIN: "Admin",
  SYSTEM_ADMIN: "System Admin",
  CLIENT_USER: "Client"
};

const ROLES = Object.freeze(ROLES_OBJECT);

export default ROLES;
