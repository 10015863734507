/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import moment from "moment";
import dateTime from "../constants/dateTime";
import Auth from "../config/auth";
import { useMemo } from "react";

const {
  ONLY_MINUTE,
  weekDays: { SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY }
} = dateTime;

const queryStringGenerator = data => {
  const keys = Object.keys(data);
  // eslint-disable-next-line no-unused-vars
  let queryString = "";

  keys.forEach(key => {
    queryString += `&${key}=${data[key]}`;
  });
  return qs;
};

const someOtherFn = () => {
  return "";
};

const weekArrayFunc = day => {
  let weekArray;
  const dayName = JSON.parse(day);
  switch (dayName) {
    case "Sunday":
      weekArray = [
        {
          value: 0,
          label: "Sunday"
        },
        {
          value: 1,
          label: "Monday"
        },
        {
          value: 2,
          label: "Tuesday"
        },
        {
          value: 3,
          label: "Wednesday"
        },
        {
          value: 4,
          label: "Thursday"
        },
        {
          value: 5,
          label: "Friday"
        },
        {
          value: 6,
          label: "Saturday"
        }
      ];
      break;
    case "Monday":
      weekArray = [
        {
          value: 1,
          label: "Monday"
        },
        {
          value: 2,
          label: "Tuesday"
        },
        {
          value: 3,
          label: "Wednesday"
        },
        {
          value: 4,
          label: "Thursday"
        },
        {
          value: 5,
          label: "Friday"
        },
        {
          value: 6,
          label: "Saturday"
        },
        {
          value: 0,
          label: "Sunday"
        }
      ];
      break;
    case "Tuesday":
      weekArray = [
        {
          value: 2,
          label: "Tuesday"
        },
        {
          value: 3,
          label: "Wednesday"
        },
        {
          value: 4,
          label: "Thursday"
        },
        {
          value: 5,
          label: "Friday"
        },
        {
          value: 6,
          label: "Saturday"
        },
        {
          value: 0,
          label: "Sunday"
        },
        {
          value: 1,
          label: "Monday"
        }
      ];
      break;
    case "Wednesday":
      weekArray = [
        {
          value: 3,
          label: "Wednesday"
        },
        {
          value: 4,
          label: "Thursday"
        },
        {
          value: 5,
          label: "Friday"
        },
        {
          value: 6,
          label: "Saturday"
        },
        {
          value: 0,
          label: "Sunday"
        },
        {
          value: 1,
          label: "Monday"
        },

        {
          value: 2,
          label: "Tuesday"
        }
      ];
      break;
    case "Thursday":
      weekArray = [
        {
          value: 4,
          label: "Thursday"
        },
        {
          value: 5,
          label: "Friday"
        },
        {
          value: 6,
          label: "Saturday"
        },
        {
          value: 0,
          label: "Sunday"
        },
        {
          value: 1,
          label: "Monday"
        },

        {
          value: 2,
          label: "Tuesday"
        },
        {
          value: 3,
          label: "Wednesday"
        }
      ];
      break;
    case "Friday":
      weekArray = [
        {
          value: 5,
          label: "Friday"
        },
        {
          value: 6,
          label: "Saturday"
        },
        {
          value: 0,
          label: "Sunday"
        },
        {
          value: 1,
          label: "Monday"
        },

        {
          value: 2,
          label: "Tuesday"
        },
        {
          value: 3,
          label: "Wednesday"
        },
        {
          value: 4,
          label: "Thursday"
        }
      ];
      break;
    case "Saturday":
      weekArray = [
        {
          value: 5,
          label: "Friday"
        },
        {
          value: 6,
          label: "Saturday"
        },
        {
          value: 0,
          label: "Sunday"
        },
        {
          value: 1,
          label: "Monday"
        },

        {
          value: 2,
          label: "Tuesday"
        },
        {
          value: 3,
          label: "Wednesday"
        },
        {
          value: 4,
          label: "Thursday"
        }
      ];
      break;
    default:
      weekArray = [
        {
          value: 0,
          label: "Sunday"
        },
        {
          value: 1,
          label: "Monday"
        },
        {
          value: 2,
          label: "Tuesday"
        },
        {
          value: 3,
          label: "Wednesday"
        },
        {
          value: 4,
          label: "Thursday"
        },
        {
          value: 5,
          label: "Friday"
        },
        {
          value: 6,
          label: "Saturday"
        }
      ];
      break;
  }
  return weekArray;
};

const numberToTimeConversion = num => {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  const momentObject = moment(`${hours}:${minutes}`, ONLY_MINUTE);
  return momentObject;
};

const isClientUser = () => Auth.isClientUser();

const isAgencyUser = () => Auth.isAgencyUser();

const isSystemAdmin = () => Auth.isSystemAdmin();

const isAgencyAdmin = () => Auth.isAgencyAdmin();

const isFinanceAdmin = () => Auth.isFinanceAdmin();

const isClientFinance = () => Auth.isClientFinance();

const isClientTimeSheet = () => Auth.isClientTimeSheet();

const getFirstdayoftheWeek = day => {
  const formatDay = day?.toLocaleUpperCase();
  switch (formatDay) {
    case "SUNDAY":
      return 0;
    case "MONDAY":
      return 1;
    case "TUESDAY":
      return 2;
    case "WEDNESDAY":
      return 3;
    case "THURSDAY":
      return 4;
    case "FRIDAY":
      return 5;
    case "SATURDAY":
      return 6;
    default:
      return 1;
  }
};

const getLastXDayOfTheWeek = (dayOfWeek = SUNDAY) => {
  let day = 0;

  if (dayOfWeek === SUNDAY) {
    day = 0;
  } else if (dayOfWeek === MONDAY) {
    day = 6;
  } else if (dayOfWeek === TUESDAY) {
    day = 5;
  } else if (dayOfWeek === WEDNESDAY) {
    day = 4;
  } else if (dayOfWeek === THURSDAY) {
    day = 3;
  } else if (dayOfWeek === FRIDAY) {
    day = 2;
  } else if (dayOfWeek === SATURDAY) {
    day = 1;
  }

  const d = new Date();
  // Adjust to previous X day
  d.setDate(d.getDate() - (d.getDay() + day));
  return d;
};

const getDefaultDatesBasedOnWeekCommencingDate = (numDaysBeforeStartDate = 7, numDaysAfter = 6) => {
  const startDay = localStorage.getItem("day");
  const firstDayOfweek = getFirstdayoftheWeek(startDay);

  const weekBeforePlatformStartDate = moment()
    .day(firstDayOfweek)
    .subtract(numDaysBeforeStartDate, "d");
  const weekAfterPlatformStartDate = moment(weekBeforePlatformStartDate).add(numDaysAfter, "d");

  return {
    startDate: weekBeforePlatformStartDate,
    endDate: weekAfterPlatformStartDate
  };
};

const weekArray = [
  {
    value: 1,
    label: "Monday"
  },
  {
    value: 2,
    label: "Tuesday"
  },
  {
    value: 3,
    label: "Wednesday"
  },
  {
    value: 4,
    label: "Thursday"
  },
  {
    value: 5,
    label: "Friday"
  },
  {
    value: 6,
    label: "Saturday"
  },
  {
    value: 0,
    label: "Sunday"
  }
];

const convertResToArr = data => {
  if (Array.isArray(data)) {
    return data;
  }
  return [data];
};

const arraysEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};

const getTagNamesArr = (tagList = {}, tag) => {
  return useMemo(() => (tagList && tagList[tag]?.map(item => item.Name)) || [], [tagList]);
};

const getUserDefaultBranches = () => {
  const userDefaultBranches = JSON.parse(localStorage.getItem("loggedInUser"));
  if (userDefaultBranches && userDefaultBranches.user && userDefaultBranches.user.TagsV2) {
    const branches = userDefaultBranches.user.TagsV2.Branches;
    return branches;
  }
  return [];
};

const decodeDataOptions = (arr, value = "Name", type = "obj") => {
  if (!arr || arr.length === 0) {
    return [];
  }
  if (type === "array") {
    return arr.map(item => decodeURIComponent(item[value]));
  }

  if (type === "obj") {
    return arr.map(item => ({ ...item, [value]: decodeURIComponent(item[value]) }));
  }
};

const queryStringToObject = queryString => {
  if (!queryString) return "";
  return queryString
    .substring(1) // Remove the '?' at the start
    .split("&") // Split into key=value pairs
    .reduce((acc, pair) => {
      const [key, value] = pair.split("="); // Split each pair to key and value
      acc[key] = value; // Assign to accumulator object
      return acc;
    }, {}); // Initial value of accumulator is an empty object
};

export {
  getTagNamesArr,
  isClientFinance,
  getUserDefaultBranches,
  queryStringGenerator,
  someOtherFn,
  isClientTimeSheet,
  numberToTimeConversion,
  weekArray,
  isClientUser,
  isAgencyUser,
  isSystemAdmin,
  isAgencyAdmin,
  isFinanceAdmin,
  getFirstdayoftheWeek,
  weekArrayFunc,
  convertResToArr,
  getLastXDayOfTheWeek,
  arraysEqual,
  getDefaultDatesBasedOnWeekCommencingDate,
  decodeDataOptions,
  queryStringToObject
};
