/* eslint-disable no-empty-function */

import { call } from "redux-saga/effects";
import { httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes";

export function* getCandidatesDuplicates({ data: candidateObjectId }) {
  const {
    getCandidateDuplicates: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint + candidateObjectId,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CANDIDATE_DUPLICATES_SUCCESS,
    errorAction: actions.CANDIDATE_DUPLICATES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteCandidates({ data }) {
  const {
    deleteCandidates: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: { DeleteCandidateObjectIDList: data },
    successAction: actions.CANDIDATE_DELETE_SUCCESS,
    errorAction: actions.CANDIDATE_DELETE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deletePerson({ data }) {
  const {
    deletePerson: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint + data,
    apiMethod: method,
    apiPayload: {},
    successAction: actions.PERSON_DELETE_SUCCESS,
    errorAction: actions.PERSON_DELETE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* mergePerson({ data }) {
  const {
    mergePerson: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.PERSON_DELETE_MERGE_SUCCESS,
    errorAction: actions.PERSON_DELETE_MERGE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* attachCandidate({ data }) {
  const {
    attachNewCandidate: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ATTACH_NEW_CANDIDATE_SUCCESS,
    errorAction: actions.ATTACH_NEW_CANDIDATE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* globalSearch({ data }) {
  const {
    GlobalSearch: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint + encodeURIComponent(data),
    apiMethod: method,
    apiPayload: {},
    successAction: actions.GLOBAL_SEARCH_SUCCESS,
    errorAction: actions.GLOBAL_SEARCH_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* mergeAndDeleteCandidates({ data }) {
  const {
    mergeAndDeleteCandidates: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CANDIDATE_MERGE_DELETE_SUCCESS,
    errorAction: actions.CANDIDATE_MERGE_DELETE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendEmail({ payload }) {
  const {
    sendEmail: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.CANDIDATE_EMAIL_SUCCESS,
    errorAction: actions.CANDIDATE_EMAIL_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* listEmail({ payload }) {
  const {
    listEmail: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint + payload,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.CANDIDATE_EMAIL_LIST_SUCCESS,
    errorAction: actions.CANDIDATE_EMAIL_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* listEmails({ payload }) {
  const {
    listEmails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: payload,
    successAction: actions.EMAIL_LIST_SUCCESS,
    errorAction: actions.EMAIL_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addAvailability({ data }) {
  const {
    addCandidateAvailability: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_AVAILABILITY_SUCCESS,
    errorAction: actions.ADD_AVAILABILITY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addTimeOff({ data }) {
  const {
    addCandidateTimeOff: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_TIMEOFF_SUCCESS,
    errorAction: actions.ADD_TIMEOFF_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* removeAvailability({ data }) {
  const {
    removeCandidateAvailability: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.REMOVE_AVAILABILITY_SUCCESS,
    errorAction: actions.REMOVE_AVAILABILITY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteTimeOff({ data }) {
  const {
    deleteCandidateTimeOff: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_TIMEOFF_SUCCESS,
    errorAction: actions.DELETE_TIMEOFF_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* bookingOperation({ data }) {
  const {
    getBookingOperations: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CALENDAR_BOOKING_OPERATION_SUCCESS,
    errorAction: actions.CALENDAR_BOOKING_OPERATION_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateBookingDetailsData({ data }) {
  const {
    updateBookingDetailsData: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_BOOKING_DETAILS_DATA_SUCCESS,
    errorAction: actions.UPDATE_BOOKING_DETAILS_DATA_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* confirmAllBookingOperation({ data }) {
  const {
    getBookingOperations: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CONFIRM_ALL_BOOKING_OPERATION_SUCCESS,
    errorAction: actions.CONFIRM_ALL_BOOKING_OPERATION_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getBookingDetails({ data }) {
  const {
    getBookingDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_BOOKING_DETAILS_SUCCESS,
    errorAction: actions.GET_BOOKING_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* confirmOrUnconfrimTimeOff({ data }) {
  const {
    confirmOrUnconfrimTimeOff: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CONFIRM_TIME_OFF_SUCCESS,
    errorAction: actions.CONFIRM_TIME_OFF_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
