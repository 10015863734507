// import { put, call } from "redux-saga/effects";
import { call } from "redux-saga/effects";
import { httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes";
// import { apiSuccessAlert, apiFailureAlert } from "../actionCreators";

export function* getObjectPermissions({ data }) {
  const {
    getObjectPermissions: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?userName=${data}`,
    apiMethod: method,
    successAction: actions.GET_USER_SITES_SUCCESS,
    errorAction: actions.GET_USER_SITES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getUserList({ data }) {
  const {
    getUserList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    successAction: actions.USER_LIST_SUCCESS,
    errorAction: actions.USER_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getUserListPaged({ data }) {
  const {
    getUserListPaged: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.USER_LIST_PAGED_SUCCESS,
    errorAction: actions.USER_LIST_PAGED_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* massResetPW({ data }) {
  const {
    massResetPW: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.MASS_RESET_PW_SUCCESS,
    errorAction: actions.MASS_RESET_PW_ERROR,
    successMessage,
    errorMessage,
    popUp: true
  };
  yield call(httpRequestForData, requestParameters);
}

export function* editUser({ data }) {
  const {
    updateUserDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.EDIT_USER_SUCCESS,
    errorAction: actions.EDIT_USER_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addNewUser({ data }) {
  const {
    addNewUser: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_NEW_USER_SUCCESS,
    errorAction: actions.ADD_NEW_USER_ERROR,
    successMessage,
    errorMessage
  };
  // const result = yield call(httpRequestForData, requestParameters);
  // localStorage.setItem("resultsWorker", JSON.stringify(result));
  // if (result?.data?.Response === "Username already exists") {
  //   yield put(apiFailureAlert("Username already exists"));
  // } else {
  //   yield put(apiSuccessAlert(successMessage));
  // }
  yield call(httpRequestForData, requestParameters);
}

export function* changePassword({ data }) {
  const {
    changePassword: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.CHANGE_PASSWORD_SUCCESS,
    errorAction: actions.CHANGE_PASSWORD_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteUser({ data }) {
  const {
    deleteUser: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_USER_SUCCESS,
    errorAction: actions.DELETE_USER_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
