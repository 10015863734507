import { combineReducers } from "redux";

import actions from "../actionTypes/booking";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const getExpenseTypeListInfo = createReducer(defaultState, {
  [actions.EXPENSE_TYPE_LIST]: isLoadingStateReducer,
  [actions.EXPENSE_TYPE_LIST_SUCCESS]: successStateReducer,
  [actions.EXPENSE_TYPE_LIST_ERROR]: errorStateReducer,
  [actions.EXPENSE_TYPE_LIST_CLEAR]: resetStateReducer
});

const addBookingExpensesInfo = createReducer(defaultState, {
  [actions.ADD_BOOKING_EXPENSES]: isLoadingStateReducer,
  [actions.ADD_BOOKING_EXPENSES_SUCCESS]: successStateReducer,
  [actions.ADD_BOOKING_EXPENSES_ERROR]: errorStateReducer,
  [actions.ADD_BOOKING_EXPENSES_CLEAR]: resetStateReducer
});

const updateBookingExpensesInfo = createReducer(defaultState, {
  [actions.UPDATE_BOOKING_EXPENSES]: isLoadingStateReducer,
  [actions.UPDATE_BOOKING_EXPENSES_SUCCESS]: successStateReducer,
  [actions.UPDATE_BOOKING_EXPENSES_ERROR]: errorStateReducer,
  [actions.UPDATE_BOOKING_EXPENSES_CLEAR]: resetStateReducer
});

const deleteBookingExpensesInfo = createReducer(defaultState, {
  [actions.DELETE_BOOKING_EXPENSES]: isLoadingStateReducer,
  [actions.DELETE_BOOKING_EXPENSES_SUCCESS]: successStateReducer,
  [actions.DELETE_BOOKING_EXPENSES_ERROR]: errorStateReducer,
  [actions.DELETE_BOOKING_EXPENSES_CLEAR]: resetStateReducer
});

const verifiedBookingsInfo = createReducer(defaultState, {
  [actions.VERIFY_BOOKINGS]: isLoadingStateReducer,
  [actions.VERIFY_BOOKINGS_SUCCESS]: successStateReducer,
  [actions.VERIFY_BOOKINGS_ERROR]: errorStateReducer,
  [actions.VERIFY_BOOKINGS_CLEAR]: resetStateReducer
});

const unverifiedBookingsInfo = createReducer(defaultState, {
  [actions.UNVERIFY_BOOKINGS]: isLoadingStateReducer,
  [actions.UNVERIFY_BOOKINGS_SUCCESS]: successStateReducer,
  [actions.UNVERIFY_BOOKINGS_ERROR]: errorStateReducer,
  [actions.UNVERIFY_BOOKINGS_CLEAR]: resetStateReducer
});

const addBookingInfringementsInfo = createReducer(defaultState, {
  [actions.ADD_BOOKING_INFRINGEMENT]: isLoadingStateReducer,
  [actions.ADD_BOOKING_INFRINGEMENT_SUCCESS]: successStateReducer,
  [actions.ADD_BOOKING_INFRINGEMENT_ERROR]: errorStateReducer,
  [actions.ADD_BOOKING_INFRINGEMENT_CLEAR]: resetStateReducer
});

const updateBookingInfringementsInfo = createReducer(defaultState, {
  [actions.UPDATE_BOOKING_INFRINGEMENT]: isLoadingStateReducer,
  [actions.UPDATE_BOOKING_INFRINGEMENT_SUCCESS]: successStateReducer,
  [actions.UPDATE_BOOKING_INFRINGEMENT_ERROR]: errorStateReducer,
  [actions.UPDATE_BOOKING_INFRINGEMENT_CLEAR]: resetStateReducer
});

const updateBookingDetailsInfo = createReducer(defaultState, {
  [actions.UPDATE_BOOKING_DETAILS]: isLoadingStateReducer,
  [actions.UPDATE_BOOKING_DETAILS_SUCCESS]: successStateReducer,
  [actions.UPDATE_BOOKING_DETAILS_ERROR]: errorStateReducer,
  [actions.UPDATE_BOOKING_DETAILS_CLEAR]: resetStateReducer
});

const updateBookingClientDetailsInfo = createReducer(defaultState, {
  [actions.UPDATE_BOOKING_DETAILS]: isLoadingStateReducer,
  [actions.UPDATE_BOOKING_DETAILS_SUCCESS]: successStateReducer,
  [actions.UPDATE_BOOKING_DETAILS_ERROR]: errorStateReducer,
  [actions.UPDATE_BOOKING_DETAILS_CLEAR]: resetStateReducer
});

const updateBasicBookingDetailsInfo = createReducer(defaultState, {
  [actions.UPDATE_BASIC_BOOKING_DETAILS]: isLoadingStateReducer,
  [actions.UPDATE_BASIC_BOOKING_DETAILS_SUCCESS]: successStateReducer,
  [actions.UPDATE_BASIC_BOOKING_DETAILS_ERROR]: errorStateReducer,
  [actions.UPDATE_BASIC_BOOKING_DETAILS_CLEAR]: resetStateReducer
});

const addBookingImport = createReducer(defaultState, {
  [actions.ADD_BOOKING_IMPORT]: isLoadingStateReducer,
  [actions.ADD_BOOKING_IMPORT_SUCCESS]: successStateReducer,
  [actions.ADD_BOOKING_IMPORT_ERROR]: errorStateReducer,
  [actions.ADD_BOOKING_IMPORT_CLEAR]: resetStateReducer
});

const getWeeklySingleBookingReducer = createReducer(defaultState, {
  [actions.GET_WEEKLY_SINGLE_BOOKING]: isLoadingStateReducer,
  [actions.GET_WEEKLY_SINGLE_BOOKING_SUCCESS]: successStateReducer,
  [actions.GET_WEEKLY_SINGLE_BOOKING_ERROR]: errorStateReducer,
  [actions.GET_WEEKLY_SINGLE_BOOKING_CLEAR]: resetStateReducer
});

const changeBookingAWRScopeInfo = createReducer(defaultState, {
  [actions.CHANGE_BOOKING_AWR]: isLoadingStateReducer,
  [actions.CHANGE_BOOKING_AWR_SUCCESS]: successStateReducer,
  [actions.CHANGE_BOOKING_AWR_ERROR]: errorStateReducer,
  [actions.CHANGE_BOOKING_AWR_CLEAR]: resetStateReducer
});

const updateVerifyBookingDetailsInfo = createReducer(defaultState, {
  [actions.UPDATE_VERIFY_BOOKING_DETAILS]: isLoadingStateReducer,
  [actions.UPDATE_VERIFY_BOOKING_DETAILS_SUCCESS]: successStateReducer,
  [actions.UPDATE_VERIFY_BOOKING_DETAILS_ERROR]: errorStateReducer,
  [actions.UPDATE_VERIFY_BOOKING_DETAILS_CLEAR]: resetStateReducer
});
const changeBookingDetails = createReducer(defaultState, {
  [actions.CHANGE_SITE]: isLoadingStateReducer,
  [actions.CHANGE_SITE_SUCCESS]: successStateReducer,
  [actions.CHANGE_SITE_ERROR]: errorStateReducer,
  [actions.CHANGE_SITE_CLEAR]: resetStateReducer
});
export default combineReducers({
  getExpenseTypeListInfo,
  addBookingImport,
  updateBookingClientDetailsInfo,
  addBookingExpensesInfo,
  updateBookingExpensesInfo,
  deleteBookingExpensesInfo,
  verifiedBookingsInfo,
  unverifiedBookingsInfo,
  addBookingInfringementsInfo,
  updateBookingInfringementsInfo,
  updateBookingDetailsInfo,
  updateBasicBookingDetailsInfo,
  changeBookingAWRScopeInfo,
  updateVerifyBookingDetailsInfo,
  changeBookingDetails,
  getWeeklySingleBookingReducer
});
