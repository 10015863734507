import { combineReducers } from "redux";
import actions from "../actionTypes";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const KPIReport = createReducer(defaultState, {
  [actions.KPI_REPORT]: isLoadingStateReducer,
  [actions.KPI_REPORT_SUCCESS]: successStateReducer,
  [actions.KPI_REPORT_ERROR]: errorStateReducer,
  [actions.KPI_REPORT_CLEAR]: resetStateReducer
});

const costAnalysis = createReducer(defaultState, {
  [actions.COST_ANALYSIS_REPORT]: isLoadingStateReducer,
  [actions.COST_ANALYSIS_REPORT_SUCCESS]: successStateReducer,
  [actions.COST_ANALYSIS_REPORT_ERROR]: errorStateReducer,
  [actions.COST_ANALYSIS_REPORT_CLEAR]: resetStateReducer
});

const clientInfringement = createReducer(defaultState, {
  [actions.CLIENT_INFRIGEMENT]: isLoadingStateReducer,
  [actions.CLIENT_INFRIGEMENT_SUCCESS]: successStateReducer,
  [actions.CLIENT_INFRIGEMENT_ERROR]: errorStateReducer,
  [actions.CLIENT_INFRIGEMENT_CLEAR]: resetStateReducer
});

const clientPerformance = createReducer(defaultState, {
  [actions.CLIENT_PERFORMANCE]: isLoadingStateReducer,
  [actions.CLIENT_PERFORMANCE_SUCCESS]: successStateReducer,
  [actions.CLIENT_PERFORMANCE_ERROR]: errorStateReducer,
  [actions.CLIENT_PERFORMANCE_CLEAR]: resetStateReducer
});

const clientPerformanceClose = createReducer(defaultState, {
  [actions.CLIENT_PERFORMANCE_CLOSE]: isLoadingStateReducer,
  [actions.CLIENT_PERFORMANCE_CLOSE_SUCCESS]: successStateReducer,
  [actions.CLIENT_PERFORMANCE_CLOSE_ERROR]: errorStateReducer,
  [actions.CLIENT_PERFORMANCE_CLOSE_CLEAR]: resetStateReducer
});

const clientInfringementSign = createReducer(defaultState, {
  [actions.CLIENT_INFRIGEMENT_SIGN]: isLoadingStateReducer,
  [actions.CLIENT_INFRIGEMENT_SIGN_SUCCESS]: successStateReducer,
  [actions.CLIENT_INFRIGEMENT_SIGN_ERROR]: errorStateReducer,
  [actions.CLIENT_INFRIGEMENT_SIGN_CLEAR]: resetStateReducer
});

const addClientData = createReducer(defaultState, {
  [actions.ADD_CLIENT_INFO]: isLoadingStateReducer,
  [actions.ADD_CLIENT_INFO_SUCCESS]: successStateReducer,
  [actions.ADD_CLIENT_INFO_ERROR]: errorStateReducer,
  [actions.ADD_CLIENT_INFO_CLEAR]: resetStateReducer
});

const updateClientData = createReducer(defaultState, {
  [actions.UPDATE_CLIENT_INFO]: isLoadingStateReducer,
  [actions.UPDATE_CLIENT_INFO_SUCCESS]: successStateReducer,
  [actions.UPDATE_CLIENT_INFO_ERROR]: errorStateReducer,
  [actions.UPDATE_CLIENT_INFO_CLEAR]: resetStateReducer
});

const deleteClientData = createReducer(defaultState, {
  [actions.DELETE_CLIENT_INFO]: isLoadingStateReducer,
  [actions.DELETE_CLIENT_INFO_SUCCESS]: successStateReducer,
  [actions.DELETE_CLIENT_INFO_ERROR]: errorStateReducer,
  [actions.DELETE_CLIENT_INFO_CLEAR]: resetStateReducer
});

export default combineReducers({
  costAnalysis,
  KPIReport,
  clientInfringement,
  clientPerformance,
  clientPerformanceClose,
  clientInfringementSign,
  addClientData,
  updateClientData,
  deleteClientData
});
