export const EmailRule = {
  required: true,
  message: "Please input valid email!"
};

export const EmailRuleRestirction = {
  required: true,
  type: "email",
  message: "Please input valid email!"
};

export const UserNameValidation = [
  {
    required: true,
    type: "regexp",
    message: "Please input your username"
  },
  {
    pattern: /^([a-zA-Z0-9]{3,30})$/,
    message: "Please input valid username!"
  }
];

export const UsernameOrEmailValidation = [
  {
    required: true,
    message: "Please input your username or email"
  }
];

export const PasswordRule = {
  required: true,
  message: "Please input your password!"
};

export const RequiredRule = {
  required: true,
  message: "This field is required"
};

export const RequiredFeildName = field => ({
  required: true,
  message: `${field} field is required`
});

export const TimeValidation = props => {
  const { getFieldValue } = props;
  return {
    validator({ field }, value) {
      if (!value || getFieldValue(field).toString().includes(":")) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Time value should be in this format: xx:xx"));
    }
  };
};

export const DutyTimeValidation = props => {
  const { getFieldValue } = props;
  return {
    validator({ field }, value) {
      if (!value || !getFieldValue(field).toString().includes(":")) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Format should be decimal eg 10.5"));
    }
  };
};

export const detailedPlaningMaxCharsRule = field => {
  let max = 0;
  if (field === "ref") {
    max = 50;
  } else if (field === "run") {
    max = 150;
  }
  return {
    max: max,
    message: `maximum ${max} characters.`
  };
};

export const RequiredLimitRule = {
  min: 6,
  message: "Username must be minimum 5 characters."
};
