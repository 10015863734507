/* eslint-disable no-empty-function */

import { call } from "redux-saga/effects";
import { axiosApiRequestForMultiPartData, httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes";

export function* getCandidatesList({ data: statusData }) {
  const {
    getCandidateList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: statusData || data,
    successAction: actions.CANDIDATE_LISTING_SUCCESS,
    errorAction: actions.CANDIDATE_LISTING_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidatesListPaged({ data: statusData }) {
  const {
    getCandidateListPaged: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: statusData || data,
    successAction: actions.CANDIDATE_LISTING_PAGED_SUCCESS,
    errorAction: actions.CANDIDATE_LISTING_PAGED_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* inviteESignCandidates({ data: personObjectIds }) {
  const {
    inviteESignCandidates: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: personObjectIds,
    successAction: actions.INVITE_E_SIGN_CANDIDATES_SUCCESS,
    errorAction: actions.INVITE_E_SIGN_CANDIDATES_ERROR,
    successMessage,
    errorMessage,
    popUp: true
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidatesInviteApp({ data: personObjectIds }) {
  const {
    getCandidateInviteApp: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: personObjectIds,
    successAction: actions.CANDIDATE_INVITE_APP_SUCCESS,
    errorAction: actions.CANDIDATE_INVITE_APP_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* changeCandidateStatus({ data }) {
  const {
    changeCandidateStatus: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CHANGE_CANDIDATE_STATUS_SUCCESS,
    errorAction: actions.CHANGE_CANDIDATE_STATUS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAssignCandidateList({ data }) {
  const {
    getAssignCandidateList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CANDIDATE_ASSIGN_LIST_SUCCESS,
    errorAction: actions.CANDIDATE_ASSIGN_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getSingleAssignCandidateList({ data }) {
  const {
    getSingleAssignCandidateList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SINGLE_CANDIDATE_ASSIGN_LIST_SUCCESS,
    errorAction: actions.SINGLE_CANDIDATE_ASSIGN_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addCandidateImport({ data }) {
  const {
    addCandidateImport: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_CANDIDATE_IMPORT_SUCCESS,
    errorAction: actions.ADD_CANDIDATE_IMPORT_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* addHoursWorkedImport({ data }) {
  const {
    addHoursWorkedImport: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}override=${data.override}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.HOURS_WORKED_IMPORT_SUCCESS,
    errorAction: actions.HOURS_WORKED_IMPORT_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* updatePersonNote({ data }) {
  const {
    updatePersonNote: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_PERSON_NOTE_SUCCESS,
    errorAction: actions.UPDATE_PERSON_NOTE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getSupplierCandidateList({ data }) {
  const {
    getListSupplierCandidates: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SUPPLIER_CANDIDATE_LIST_SUCCESS,
    errorAction: actions.SUPPLIER_CANDIDATE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getWorkingTimeSummary({ data }) {
  const {
    getWorkingTimeSummary: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CANDIDATE_WORKING_TIME_SUCCESS,
    errorAction: actions.CANDIDATE_WORKING_TIME_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllCandidatesTypeList() {
  const {
    getCandidateTypesList: { endPoint, method, data }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CANDIDATE_TYPE_SUCCESS,
    errorAction: actions.CANDIDATE_TYPE_ERROR
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getClientList() {
  const {
    getClientList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CLIENT_LIST_SUCCESS,
    errorAction: actions.CLIENT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getSiteList() {
  const {
    getSiteList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SITE_LIST_SUCCESS,
    errorAction: actions.SITE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAddBooking({ data }) {
  const {
    addBooking: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_BOOKING_SUCCESS,
    errorAction: actions.ADD_BOOKING_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getDriverCalendarEvents({ data }) {
  const {
    getDriverCalendarEvents: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: {
      ...data,
      ExcludeBookings: false,
      ShiftTypeObjectIds: data.ShiftTypeObjectIds || [],
      ClientObjectIds: data.ClientObjectIds || [],
      // IncludeTimeOff: true,
      // IncludePersonAvailability: true,
      ClientFilterToPerson: false,
      FilterBookingStatus: data.FilterBookingStatus || [],
      Distinct: false
    },
    successAction: actions.DRIVER_CALENDAR_SUCCESS,
    errorAction: actions.DRIVER_CALENDAR_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getRibbonData({ data }) {
  const {
    getRibbonData: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: {
      ...data,
      ClientObjectIds: [],
      ExcludeBookings: false,
      ShiftTypeObjectIds: "",
      IncludeTimeOff: false,
      IncludePersonAvailability: false,
      ClientFilterToPerson: false,
      FilterBookingStatus: [],
      Distinct: false
    },
    successAction: actions.RIBBON_DATA_SUCCESS,
    errorAction: actions.RIBBON_DATA_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
