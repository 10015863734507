const selectedPageSize = localStorage.getItem("pageSize");
const DEFAULT_PAGE = 500;

const defaultPageSizes = {
  "/candidate-listing": 100,
  "/site-listing": 50,
  "/userManagement": 50,
  "/supplier-management": 50
};

const ALL_CONSTANTS = {
  PAGE_ROUTES: {
    DEFAULT: "/",
    HOME: "/home",
    LOGIN: "/login",
    FORGET_PASSWORD: "/forget-password",
    DASHBOARD: "/dashboard",
    ACCOUNTS: "/accounts",
    BUSINESS: "/business",
    TASKS: "/tasks",
    APPROVALS: "/approvals",
    PENDING_BOOKING: "/pending-booking",
    DRIVER_LISTING: "/driver-listing",
    CANDIDATE_LISTING: "/candidate-listing",
    AGENCY_CANDIDATE_LISTING: "/agency-candidate-listing",
    PLANNING_DAILYBOOKING: "/planning-daily-booking",
    SITE_LISTING: "/site-listing",
    USER_LISTING: "/userManagement",
    DAILY_BOOKING: "/daily-booking",
    ASSIGNMENT_SCHEDULES: "/assignment-schedules",
    DRIVER_CALENDAR: "/driver-calendar",
    MONTHLY_CALENDAR: "/monthly-calendar",
    WEEKLY_CALENDAR: "/weekly-calendar",
    RECRUITER: "/candidate-listing",
    ADD_CANDIDATE: "/add-candidate",
    ADD_CANDIDATE_ACTIVITY: "/add-candidate-activity",
    ADD_SITE_DETAILS: "/addsite",
    SITE_ACTIVITY: "/site-activity",
    MANAGE_PAY_DOCUMENT: "/manage-pay-rates",
    DOCUMENTS: "/document",
    BOOKING_SETUP: "/system-setup",
    SUPPLIER_MANAGEMENT: "/supplier-management",
    PENDING_DOCUMENT: "/pending-documents",
    COST_ANALYSIS: "/cost-analysis",
    INFRINGEMENT: "/infringement",
    CANDIDATE_PERFORMANCE: "/candidate-performance",
    NOT_FOUND: "/404",
    NO_ACCESS: "/403",
    NOT_AUTH: "/401",
    USER_MANAGEMENT: "/userManagement",
    GENERATE_PAYRATE: "/generate-payDocument",
    HOLIDAY_TAB_ROUTE: "/holiday",
    VERIFY_BOOKING: "/Verify-booking",
    CLIENT_MANAGEMENT: "/client-management",
    REPORTS: "/reports",
    REQUEST_PASSWORD_RESET: "/request-password-reset",
    COMPLETE_PASSWORD_RESET: "/completepwreset"
  },
  HTTP_REQ_DEFAULT_HEADERS: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8"
  },
  HTTP_MULTIPART_FORM_HEADERS: {
    "Content-Type": "multipart/form-data"
  },
  DATE_FORMAT: {
    FORMAT_DEFAULT: "DD-MM-YYYY",
    DRS_DATE_FORMAT: "DD/MM/YYYY",
    FORMAT_2: "DD/MM/YYYY",
    FORMAT_3: "MMM/YYYY",
    FORMAT_4: "DD/MM/YY"
  },
  TIME_FORMAT: "HH:mm:ss",
  ONLY_MINUTE: "HH:mm",
  ONLY_HOUR: "HH",
  DATE_FORMAT_STRING: "YYYY-MM-DD",
  API_ERROR_MSG: "Internal Server Error",
  API_TIME_OUT: 300000,
  MODAL_ZINDEX: 1800,
  DEFAULT_MODAL_WIDTH: "75%",
  NULLIFY: null,
  HTTP_REQUEST_METHODS: {
    POST: "POST",
    GET: "GET",
    DELETE: "DELETE",
    PATCH: "PATCH",
    PUT: "PUT"
  },
  API_MSG_TIME: 5,
  API_MSG_PLACEMENT: 85,
  API_SUCCESS_INFO: "API Success Info",
  API_ERROR_INFO: "API Error Info",
  API_SUCCESS: "success",
  API_ERROR: "error",
  HTTP_STATUS: {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    UNAUTHORISED: 401,
    FORBIDDEN: 403,
    METHOD_NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    UNPROCESSABLE: 422,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501
  },
  CANDIDATE_STATUS: [
    { Name: "Unprocessed", ObjectId: "5" },
    { Name: "Processed", ObjectId: "1" },
    { Name: "DoNotUse", ObjectId: "2" },
    { Name: "Inactive", ObjectId: "3" },
    { Name: "Active", ObjectId: "4" }
  ],

  PAYMENT_TIER: [
    { Name: "PAYE", ObjectId: "1" },
    { Name: "LTD", ObjectId: "2" },
    { Name: "Second Tier", ObjectId: "3" }
  ],

  SITE_STATUS: [
    { Name: "Processed", ObjectId: "1" },
    { Name: "DoNotUse", ObjectId: "2" },
    { Name: "Inactive", ObjectId: "3" },
    { Name: "Active", ObjectId: "4" },
    { Name: "Unprocessed", ObjectId: "5" },
    { Name: "Deleted", ObjectId: "6" }
  ],
  USER_STATUS: [
    // { Name: "Processed", ObjectId: "1" },
    // { Name: "DoNotUse", ObjectId: "2" },
    { Name: "Inactive", ObjectId: "1" },
    { Name: "Active", ObjectId: "2" }
  ],
  BOOKING_CANCEL_REASON: [
    { label: "No Cover", value: "NoCover" },
    { label: "Input Error", value: "InputError" },
    { label: "Candidate Sick", value: "CandidateSick" },
    { label: "Candidate No Show", value: "NoShow" },
    { label: "Candidate Cancelled", value: "Candidate" },
    { label: "Client Covered", value: "ClientCovered" },
    { label: "Client Cancelled", value: "Client" },
    { label: "Incorrect Forecast", value: "IncorrectForecast" }
  ],
  CLIENT_BOOKING_CANCEL_REASON: [{ label: "Client Cancelled", value: "Client" }],
  SESSION_EXPIRED: "Your Session has Expired. Please log back in.",
  DEFAULT_PAGINATION_OBJECT: {
    position: ["bottomCenter"],
    defaultPageSize: selectedPageSize || DEFAULT_PAGE,
    pageSizeOptions: [10, 20, 50, 100, 500],
    hideOnSinglePage: true,
    onShowSizeChange: (current, size, pathname = null) => {
      if (pathname) {
        let pageSize = localStorage.getItem("pageSizeProps") || "{}";

        const newPageSize = JSON.stringify({ ...JSON.parse(pageSize), [pathname]: size });

        localStorage.setItem("pageSizeProps", newPageSize);
      }
    },
    getDefaultPageSize(pathname) {
      if (pathname) {
        const pageSize = localStorage.getItem("pageSizeProps");

        if (pageSize) {
          return JSON.parse(pageSize)[pathname] || defaultPageSizes[pathname];
        }
        return defaultPageSizes[pathname];
      }

      return defaultPageSizes[pathname];
    }
  },
  DEFAULT_WEEKLY_BOOKING_PAGINATION_OBJECT: {
    position: ["bottomCenter"],
    defaultPageSize: 500,
    pageSizeOptions: [10, 20, 50, 100, 500],
    hideOnSinglePage: true
  }
};

const CONSTANTS = Object.freeze(ALL_CONSTANTS);

export default CONSTANTS;
