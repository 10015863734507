/* eslint-disable consistent-return */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Result, Button } from "antd";

import Auth from "../../config/auth";
import Logo from "../../assets/images/png/drsNewLogo.png";
import labels from "../../constants/labels";
import getDefaultRoute from "../../utils/getDefaultRoute";

import "./errors.scss";

const {
  ERROR_PAGE: { NOT_FOUND, NO_ACCESS, NO_AUTH, SERVER_ERROR, COMP_ERROR, LOGIN },
  GO_TO_HOME
  // LOGIN_BACK
} = labels;

const ErrorHandler = ({ history, errorCase }) => {
  const goToHome = useCallback(() => {
    // const { currentUserRole } = Auth.getSessionDetails() || "";
    const currentUserRole = localStorage.getItem("role");
    const navigateTo = getDefaultRoute(currentUserRole);
    history.push(navigateTo);
  }, []);
  const reLogin = useCallback(() => {
    setTimeout(() => {
      history.push(LOGIN);
    }, 3000);
    Auth.removeSessionKey();
  }, []);

  const chooseErrorHandler = error => {
    switch (error) {
      case NOT_FOUND:
        return (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button className="error-page-action-btn" type="primary" onClick={goToHome}>
                {GO_TO_HOME}
              </Button>
            }
          />
        );

      case NO_AUTH:
        reLogin();
        return (
          <Result
            status="403"
            title="401"
            subTitle="Sorry, session key has expired, Please Login back to continue."
            // extra={
            //   <Button className="error-page-action-btn" type="primary" onClick={reLogin}>
            //     {LOGIN_BACK}
            //   </Button>
            // }
          />
        );

      case NO_ACCESS:
        return (
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you don't have access to this page."
            extra={
              <Button className="error-page-action-btn" type="primary" onClick={goToHome}>
                {GO_TO_HOME}
              </Button>
            }
          />
        );

      case COMP_ERROR:
        return (
          <Result
            status="500"
            title="Something went wrong"
            subTitle="Sorry, there are some problems with your operation."
            extra={
              <>
                <Button
                  type="primary"
                  className="error-page-action-btn"
                  key="console"
                  onClick={() => window.location.reload()}
                >
                  Reload
                </Button>
                <Button type="primary" className="error-page-action-btn" onClick={goToHome}>
                  {GO_TO_HOME}
                </Button>
              </>
            }
          />
        );

      case SERVER_ERROR:
        return (
          <Result
            status="500"
            title="500"
            subTitle="Sorry, the server is reporting an error."
            extra={
              <Button className="error-page-action-btn" type="primary">
                {GO_TO_HOME}
              </Button>
            }
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="error-page-main">
      <div className="drs-main-logo">
        <img src={Logo} alt="drs-error-logo" />
      </div>
      {chooseErrorHandler(errorCase)}
    </div>
  );
};

ErrorHandler.propTypes = {
  history: PropTypes.object,
  errorCase: PropTypes.string
};

ErrorHandler.defaultProps = {
  history: {},
  errorCase: ""
};

export default React.memo(withRouter(ErrorHandler));
