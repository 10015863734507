import React from "react";
import { withRouter } from "react-router-dom";
import ErrorHandler from "./errorHandler";

import labels from "../../constants/labels";

const {
  ERROR_PAGE: { NOT_FOUND }
} = labels;

const NotFound = () => {
  return <ErrorHandler errorCase={NOT_FOUND} />;
};

export default React.memo(withRouter(NotFound));
