import React from "react";
import {
  DriverListing,
  CandidateDetails,
  Calendar,
  CandidateListing,
  DriverCalendar,
  SiteListing,
  AddSiteDetails,
  DailyBooking,
  CandidateActivity,
  BookingCalendar,
  WeeklyCalendar,
  SiteActivity,
  UserManagement,
  PendingDocuments,
  Document,
  CostAnalysis,
  Infringement,
  CandidatePerformance,
  BookingSetup,
  PayRateListing,
  GeneratePayrate,
  FinanceHoliday,
  VerifyBooking,
  SupplierActivityTabs,
  ClientManagement,
  Reports,
  AssignmentSchedules
} from "./modulesPath";
import SecuredRoute from "./securedRoutes";
import roles from "../constants/roles";
import routingConstants from "../constants/constants";

const {
  PAGE_ROUTES: {
    DEFAULT,
    HOME,
    ACCOUNTS,
    BUSINESS,
    APPROVALS,
    PENDING_BOOKING,
    DRIVER_LISTING,
    TASKS,
    CANDIDATE_LISTING,
    AGENCY_CANDIDATE_LISTING,
    PLANNING_DAILYBOOKING,
    DRIVER_CALENDAR,
    SITE_LISTING,
    DAILY_BOOKING,
    ASSIGNMENT_SCHEDULES,
    MONTHLY_CALENDAR,
    WEEKLY_CALENDAR,
    ADD_SITE_DETAILS,
    ADD_CANDIDATE,
    ADD_CANDIDATE_ACTIVITY,
    SITE_ACTIVITY,
    USER_MANAGEMENT,
    PENDING_DOCUMENT,
    GENERATE_PAYRATE,
    HOLIDAY_TAB_ROUTE,
    DOCUMENTS,
    COST_ANALYSIS,
    CANDIDATE_PERFORMANCE,
    INFRINGEMENT,
    BOOKING_SETUP,
    MANAGE_PAY_DOCUMENT,
    VERIFY_BOOKING,
    SUPPLIER_MANAGEMENT,
    CLIENT_MANAGEMENT,
    REPORTS
  }
} = routingConstants;

const { OPERATOR, AGENCY_ADMIN, AGENCY_USER, CLIENT_USER, FINANCE_USER, SYSTEM_ADMIN } = roles;

const routeArray = [
  {
    path: DEFAULT,
    exact: true,
    roles: [OPERATOR, CLIENT_USER, AGENCY_ADMIN, AGENCY_USER, FINANCE_USER, SYSTEM_ADMIN],
    component: DailyBooking
  },
  {
    exact: true,
    path: HOME,
    roles: [OPERATOR, CLIENT_USER, AGENCY_ADMIN, AGENCY_USER, FINANCE_USER, SYSTEM_ADMIN],
    component: DailyBooking
  },
  {
    exact: true,
    path: ACCOUNTS,
    roles: [AGENCY_ADMIN, AGENCY_USER, FINANCE_USER],
    component: DailyBooking
  },
  {
    exact: true,
    path: BUSINESS,
    roles: [FINANCE_USER, CLIENT_USER, SYSTEM_ADMIN],
    component: DailyBooking
  },
  {
    exact: true,
    path: TASKS,
    roles: [AGENCY_ADMIN, OPERATOR],
    component: Calendar
  },
  {
    exact: true,
    path: APPROVALS,
    roles: [AGENCY_ADMIN],
    // component: Dashboard
    component: DailyBooking
  },
  {
    exact: true,
    path: PENDING_BOOKING,
    roles: [AGENCY_ADMIN, AGENCY_USER],
    // component: Dashboard
    component: DailyBooking
  },
  {
    exact: true,
    path: GENERATE_PAYRATE,
    roles: [FINANCE_USER],
    component: GeneratePayrate
  },
  {
    exact: true,
    path: HOLIDAY_TAB_ROUTE,
    roles: [FINANCE_USER],
    component: FinanceHoliday
  },
  {
    exact: true,
    path: DRIVER_LISTING,
    roles: [AGENCY_ADMIN, AGENCY_USER],
    component: DriverListing
  },
  {
    exact: true,
    path: CANDIDATE_LISTING,
    roles: [AGENCY_ADMIN],
    component: CandidateListing
  },
  {
    exact: true,
    path: AGENCY_CANDIDATE_LISTING,
    roles: [AGENCY_USER],
    component: CandidateListing
  },
  {
    exact: true,
    path: PLANNING_DAILYBOOKING,
    roles: [AGENCY_USER],
    component: DailyBooking
  },
  {
    exact: true,
    path: DRIVER_CALENDAR,
    roles: [AGENCY_ADMIN, AGENCY_USER],
    component: DriverCalendar
  },
  {
    exact: true,
    path: MONTHLY_CALENDAR,
    roles: [AGENCY_ADMIN, AGENCY_USER, CLIENT_USER],
    component: BookingCalendar
  },
  {
    exact: true,
    path: WEEKLY_CALENDAR,
    roles: [AGENCY_ADMIN, AGENCY_USER, CLIENT_USER],
    component: WeeklyCalendar
  },
  {
    exact: true,
    path: ADD_CANDIDATE,
    roles: [AGENCY_ADMIN, AGENCY_USER, CLIENT_USER],
    component: CandidateDetails
  },
  {
    exact: true,
    path: ADD_CANDIDATE_ACTIVITY,
    roles: [AGENCY_ADMIN, AGENCY_USER],
    component: CandidateActivity
  },
  {
    exact: true,
    path: SITE_LISTING,
    roles: [AGENCY_ADMIN, AGENCY_USER],
    component: SiteListing
  },
  {
    exact: true,
    path: ADD_SITE_DETAILS,
    roles: [AGENCY_ADMIN, AGENCY_USER],
    component: AddSiteDetails
  },
  {
    exact: true,
    path: DAILY_BOOKING,
    roles: [AGENCY_ADMIN, AGENCY_USER, CLIENT_USER],
    component: DailyBooking
  },
  {
    exact: true,
    path: ASSIGNMENT_SCHEDULES,
    roles: [AGENCY_ADMIN, AGENCY_USER, CLIENT_USER],
    component: AssignmentSchedules
  },
  { exact: true, path: SITE_LISTING, roles: [AGENCY_ADMIN, AGENCY_USER], component: SiteListing },
  { exact: true, path: SITE_ACTIVITY, roles: [AGENCY_ADMIN, AGENCY_USER], component: SiteActivity },
  {
    exact: true,
    path: USER_MANAGEMENT,
    roles: [AGENCY_ADMIN, SYSTEM_ADMIN],
    component: UserManagement
  },
  { exact: true, path: PENDING_DOCUMENT, roles: [FINANCE_USER], component: PendingDocuments },
  { exact: true, path: DOCUMENTS, roles: [FINANCE_USER], component: Document },
  { exact: true, path: COST_ANALYSIS, roles: [CLIENT_USER], component: CostAnalysis },
  {
    exact: true,
    path: CANDIDATE_PERFORMANCE,
    roles: [CLIENT_USER],
    component: CandidatePerformance
  },
  { exact: true, path: INFRINGEMENT, roles: [CLIENT_USER], component: Infringement },
  {
    exact: true,
    path: BOOKING_SETUP,
    roles: [AGENCY_ADMIN, SYSTEM_ADMIN],
    component: BookingSetup
  },
  { exact: true, path: MANAGE_PAY_DOCUMENT, roles: [FINANCE_USER], component: PayRateListing },
  { exact: true, path: VERIFY_BOOKING, roles: [FINANCE_USER], component: VerifyBooking },
  {
    exact: true,
    path: SUPPLIER_MANAGEMENT,
    roles: [AGENCY_ADMIN],
    component: SupplierActivityTabs
  },
  {
    exact: true,
    path: CLIENT_MANAGEMENT,
    roles: [AGENCY_ADMIN],
    component: ClientManagement
  },
  {
    exact: true,
    path: REPORTS,
    roles: [AGENCY_ADMIN],
    component: Reports
  }
];

const getSecuredRoutes = () => {
  const privateRoutes = routeArray.map(propsObject => (
    <SecuredRoute {...propsObject} key={propsObject.path + Math.random()} />
  ));
  return privateRoutes;
};

const securedRoutes = getSecuredRoutes();

export default securedRoutes;
