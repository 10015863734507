import action from "../actionTypes/filterTypes";
import LABELS from "../../constants/labels";

const {
  CANDIDATE_FILTER: { ACTIVE }
} = LABELS;

const initialState = {
  selectedClient: [],
  selectedSite: [],
  selectedSiteStatus: [ACTIVE],
  selectedStatus: [],
  candidateSelectedStatus: [ACTIVE],
  searchByKeyword: null,
  selectedCandidates: [],
  selectedDate: null,
  selectedTags: [],
  assignSelectedTags: [],
  selectedBranches: [],
  assignSelectedBranches: [],
  selectedShifts: [],
  dailyBookingFilters: null,
  verifyBookingFilters: null,
  weeklyBookingFilters: null,
  monthlyBookingFilters: null
};
const candidateFilterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case action.CANDIDATE_SELECTED_CLIENT:
      return { ...state, selectedClient: [...payload] };
    case action.CANDIDATE_SELECTED_SITE:
      return { ...state, selectedSite: [...payload] };
    case action.CANDIDATE_SELECTED_STATUS:
      return { ...state, selectedStatus: [...payload] };
    case action.SITE_SELECTED_STATUS:
      return { ...state, selectedSiteStatus: [...payload] };
    case action.CANDIDATE_STATUS:
      return { ...state, candidateSelectedStatus: [...payload] };
    case action.CANDIDATE_SEARCH_KEYWORD:
      return { ...state, searchByKeyword: payload };
    case action.CANDIDATE_SELECTED:
      return { ...state, selectedCandidates: [...payload] };
    case action.CANDIDATE_SELECTED_CLEAR:
      return { ...state, selectedCandidates: [] };
    case action.SELECTED_DATE:
      return { ...state, selectedDate: payload };
    case action.CANDIDATE_SELECTED_TAGS:
      return { ...state, selectedTags: [...payload] };
    case action.ASSIGN_CANDIDATE_SELECTED_TAGS:
      return { ...state, assignSelectedTags: [...payload] };
    case action.CANDIDATE_SELECTED_BRANCHES:
      return { ...state, selectedBranches: [...payload] };
    case action.ASSIGN_CANDIDATE_SELECTED_BRANCHES:
      return { ...state, assignSelectedBranches: [...payload] };
    case action.CANDIDATE_SELECTED_SHIFTS:
      return { ...state, selectedShifts: [...payload] };
    case action.CANDIDATE_SELECTED_CLIENT_CLEAR:
      return { ...state, selectedClient: [] };
    case action.CANDIDATE_DAILY_BOOKING_FILTER:
      return { ...state, dailyBookingFilters: payload };
    case action.CANDIDATE_DAILY_BOOKING_CLEAR:
      return { ...initialState, dailyBookingFilters: null };
    case action.CANDIDATE_VERIFY_BOOKING_FILTER:
      return { ...state, verifyBookingFilters: payload };
    case action.CANDIDATE_VERIFY_BOOKING_CLEAR:
      return { ...initialState, verifyBookingFilters: null };
    case action.WEEKLY_BOOKING_FILTER:
      return { ...state, weeklyBookingFilters: payload };
    case action.WEEKLY_BOOKING_FILTER_CLEAR:
      return { ...initialState, weeklyBookingFilters: null };
    case action.MONTHLY_BOOKING_FILTER:
      return { ...state, monthlyBookingFilters: payload };
    case action.MONTHLY_BOOKING_FILTER_CLEAR:
      return { ...initialState, monthlyBookingFilters: null };
    case action.CANDIDATE_FILTER_CLEAR:
      return {
        ...initialState,
        verifyBookingFilters: state.verifyBookingFilters
      };
    default:
      return state;
  }
};

export default candidateFilterReducer;
