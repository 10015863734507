import actions from "../actionTypes";
import filterAction from "../actionTypes/filterTypes";

export const menuClick = () => ({
  type: actions.MENU_CLICK
});

export const login = payload => ({
  type: actions.LOGIN,
  payload
});

export const apiSuccessAlert = payload => ({
  type: actions.API_SUCCESS_MSG,
  payload
});

export const apiFailureAlert = payload => ({
  type: actions.API_FAILURE_MSG,
  payload
});

export const sendEmail = payload => ({
  type: actions.CANDIDATE_EMAIL,
  payload
});

export const listEmail = payload => ({
  type: actions.CANDIDATE_EMAIL_LIST,
  payload
});

export const clearListEmail = () => ({
  type: actions.CANDIDATE_EMAIL_LIST_CLEAR
});

export const clearSendEmail = () => ({
  type: actions.CANDIDATE_EMAIL_CLEAR
});

export const clearApiMsg = () => ({
  type: actions.API_MSG_CLEAR
});

export const getLoginData = payload => ({
  type: actions.LOGIN_IN,
  payload
});

export const getCandidateListData = data => ({
  type: actions.CANDIDATE_LISTING,
  data
});

export const getCandidateListDataPaged = data => ({
  type: actions.CANDIDATE_LISTING_PAGED,
  data
});

export const changeCandidateStatus = data => ({
  type: actions.CHANGE_CANDIDATE_STATUS,
  data
});

export const getCandidatesInviteApp = data => ({
  type: actions.CANDIDATE_INVITE_APP,
  data
});

export const inviteESignCandidates = data => ({
  type: actions.INVITE_E_SIGN_CANDIDATES,
  data
});

export const getCandidateAssignList = data => ({
  type: actions.CANDIDATE_ASSIGN_LIST,
  data
});

export const getSingleCandidateAssignList = data => ({
  type: actions.SINGLE_CANDIDATE_ASSIGN_LIST,
  data
});

export const getWorkingTimeSummary = data => ({
  type: actions.CANDIDATE_WORKING_TIME,
  data
});

export const getCandidateDuplicates = data => ({
  type: actions.CANDIDATE_DUPLICATES,
  data
});

export const deleteCandidates = data => ({
  type: actions.CANDIDATE_DELETE,
  data
});

export const deleteUser = data => ({
  type: actions.DELETE_USER,
  data
});

export const clearDeleteUser = () => ({
  type: actions.DELETE_USER_CLEAR
});

export const editUser = data => ({
  type: actions.EDIT_USER,
  data
});

export const clearEditUser = () => ({
  type: actions.EDIT_USER_CLEAR
});

export const deletePerson = data => ({
  type: actions.PERSON_DELETE,
  data
});

export const attachNewCandidates = data => ({
  type: actions.ATTACH_NEW_CANDIDATE,
  data
});

export const mergePerson = data => ({
  type: actions.PERSON_DELETE_MERGE,
  data
});

export const getGlobalSearch = data => ({
  type: actions.GLOBAL_SEARCH,
  data
});

export const mergeAndDeleteCandidates = data => ({
  type: actions.CANDIDATE_MERGE_DELETE,
  data
});

export const resetCandidateListData = () => ({
  type: actions.CANDIDATE_LISTING_CLEAR
});

export const getSiteListData = () => ({
  type: actions.SITE_LIST
});

export const getUserListData = data => ({
  type: actions.USER_LIST,
  data
});

export const getUserListDataPaged = data => ({
  type: actions.USER_LIST_PAGED,
  data
});

export const massResetPW = data => ({
  type: actions.MASS_RESET_PW,
  data
});

export const addBookingData = data => ({
  type: actions.ADD_BOOKING,
  data
});

export const clearBookingData = () => ({
  type: actions.ADD_BOOKING_CLEAR
});

export const resetSiteListData = () => ({
  type: actions.SITE_LIST_CLEAR
});

export const resetUserListData = () => ({
  type: actions.USER_LIST_CLEAR
});
export const getClientListData = () => ({
  type: actions.CLIENT_LIST
});

export const getCandidateTypeData = () => ({
  type: actions.CANDIDATE_TYPE
});

export const getLeadSourceData = () => ({
  type: actions.LEAD_SOURCE_LIST
});

export const getInsuranceData = () => ({
  type: actions.INSURANCE_LIST
});

export const getNationalityData = () => ({
  type: actions.NATIONALITY_LIST
});

export const clearSelectedCandidates = () => ({
  type: filterAction.CANDIDATE_SELECTED_CLEAR
});

export const clearCandidateSearchFilter = () => ({
  type: filterAction.CANDIDATE_FILTER_CLEAR
});

export const addNewCandidate = data => ({
  type: actions.ADD_NEW_CANDIDATE,
  data
});

export const addNewAddress = data => ({
  type: actions.ADD_NEW_ADDRESS,
  data
});

export const addNewAdditionalAddress = data => ({
  type: actions.ADD_NEW_ADDITIONAL_ADDRESS,
  data
});

export const addMultipleDocuments = data => ({
  type: actions.ADD_MULTIPLE_DOCUMENTS,
  data
});

export const addLicenseInformation = data => ({
  type: actions.ADD_LICENSE_INFORMATION,
  data
});

export const getDrivingLicenseCapabilityList = data => ({
  type: actions.DRIVING_LICENSE_CAPABILITY_LIST,
  data
});

export const getDrivingLicenseEndorsementList = data => ({
  type: actions.DRIVING_LICENSE_ENDORSEMENT_LIST,
  data
});

export const getDrivingLicenseCapabilityTypesList = data => ({
  type: actions.DRIVING_LICENSE_CAPABILITY_TYPES_LIST,
  data
});

export const getDrivingLicenseEndorsementTypesList = data => ({
  type: actions.DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST,
  data
});

export const addInductionsBans = data => ({
  type: actions.ADD_INDUCTIONS_BANS_DETAILS,
  data
});

export const qualificationTitleList = () => ({
  type: actions.QUALIFICATION_TITLE_LIST
});

export const clearQualificationTitleList = () => ({
  type: actions.QUALIFICATION_TITLE_LIST_CLEAR
});

export const identificationNameList = () => ({
  type: actions.QUALIFICATION_NAME_LIST
});

export const clearIdentificationNameList = () => ({
  type: actions.QUALIFICATION_NAME_LIST_CLEAR
});

export const addQualificationDetails = data => ({
  type: actions.ADD_QUALIFICATION_DETAILS,
  data
});

export const addLicenceAdditionalDetails = data => ({
  type: actions.ADD_LICENCE_ADDITIONAL_DETAILS,
  data
});

export const getDriverBookings = data => ({
  type: actions.DRIVER_CALENDAR,
  data
});

export const getRibbonData = data => ({
  type: actions.RIBBON_DATA,
  data
});

export const getPaymentTierListData = () => ({
  type: actions.PAYMENT_TIER_LIST
});

export const getSupplierListData = () => ({
  type: actions.SUPPLIER_LIST
});

export const getServiceCompanyListData = () => ({
  type: actions.SERVICE_COMPANY_LIST
});

export const getContactTypeListData = () => ({
  type: actions.CONTACT_TYPE_LIST
});

export const getExpenseListData = () => ({
  type: actions.EXPENSE_TYPE_LIST
});

export const addPayrollDetails = data => ({
  type: actions.ADD_PAYROLL_DETAILS,
  data
});

export const addPreferenceDetails = data => ({
  type: actions.ADD_PREFERENCE_DETAILS,
  data
});

export const addWorkHistory = data => ({
  type: actions.ADD_WORK_HISTORY,
  data
});

export const getSatisfactionLevel = () => ({
  type: actions.SATISFACTION_LEVEL_LIST
});

export const getReasonsList = () => ({
  type: actions.REASONS_LIST
});

export const getNonConversionList = () => ({
  type: actions.NON_CONVERSION_LIST
});

export const addHumanResource = data => ({
  type: actions.ADD_HUMAN_RESOURCE,
  data
});

export const getRatingTypeLists = () => ({
  type: actions.RATING_TYPE_LIST
});

export const addCandidateRatings = data => ({
  type: actions.ADD_CANDIDATE_RATINGS,
  data
});

export const addNewUser = data => ({
  type: actions.ADD_NEW_USER,
  data
});

export const clearAddNewUser = () => ({
  type: actions.ADD_NEW_USER_CLEAR
});

export const getObjectPermissions = data => ({
  type: actions.GET_USER_SITES,
  data
});

export const clearObjectPermissions = data => ({
  type: actions.GET_USER_SITES_CLEAR,
  data
});

export const changePassword = data => ({
  type: actions.CHANGE_PASSWORD,
  data
});

export const getInfringementsTypeLists = () => ({
  type: actions.INFRINGEMENTS_TYPE_LIST
});

export const addCandidateInfringements = data => ({
  type: actions.ADD_CANDIDATE_INFRINGEMENTS,
  data
});

export const getHolidaysLists = data => ({
  type: actions.HOLIDAYS_LIST,
  data
});

export const getNotesLists = data => ({
  type: actions.NOTES_LIST,
  data
});

export const addNotesActivity = data => ({
  type: actions.ADD_NOTES_ACTIVITY,
  data
});

export const getStatementOptions = () => ({
  type: actions.STATEMENT_OPTIONS_LIST
});

export const getCandidatePerformanceTypesList = () => ({
  type: actions.PERFORMANCE_TYPE_LIST
});

export const addPerformanceActivityDetails = data => ({
  type: actions.ADD_PERFORMANCE_ACTIVITY,
  data
});

export const getTasksLists = data => ({
  type: actions.TASKS_LIST,
  data
});

export const addTasksActivity = data => ({
  type: actions.ADD_TASKS_ACTIVITY,
  data
});

export const getSmsHistory = data => ({
  type: actions.SMS_HISTORY_LIST,
  data
});

export const sendSMS = data => ({
  type: actions.SEND_SMS,
  data
});

export const getEmailHistory = data => ({
  type: actions.EMAIL_HISTORY_LIST,
  data
});

export const addSmartBoxActivity = data => ({
  type: actions.ADD_SMART_BOX_ACTIVITY,
  data
});

export const getRatingHistory = data => ({
  type: actions.RATING_DETAILS_LIST,
  data
});

export const getInfringementsHistory = data => ({
  type: actions.INFRINGEMENTS_DETAILS_LIST,
  data
});

export const getPerformanceHistory = data => ({
  type: actions.PERFORMANCE_DETAILS_LIST,
  data
});

export const getSmartBoxHistory = data => ({
  type: actions.SMART_BOX_DETAILS_LIST,
  data
});

export const updateCandidateRating = data => ({
  type: actions.UPDATE_CANDIDATE_RATING,
  data
});

export const updateCandidateInfringement = data => ({
  type: actions.UPDATE_CANDIDATE_INFRINGEMENT,
  data
});

export const updateCandidatePerformance = data => ({
  type: actions.UPDATE_CANDIDATE_PERFORMANCE,
  data
});

export const updateCandidateNotesData = data => ({
  type: actions.UPDATE_CANDIDATE_NOTES,
  data
});

export const updateSmartBox = data => ({
  type: actions.UPDATE_CANDIDATE_SMART_BOX,
  data
});

export const getListSupplierCandidates = data => ({
  type: actions.SUPPLIER_CANDIDATE_LIST,
  data
});

export const clearCandidateAssignList = data => ({
  type: actions.CANDIDATE_ASSIGN_LIST_CLEAR,
  data
});

export const clearSupplierCandidateList = () => ({
  type: actions.SUPPLIER_CANDIDATE_LIST_CLEAR
});

export const getSearchUser = data => ({
  type: actions.SEARCH_USER_LIST,
  data
});

export const clearWorkingTimeSummary = data => ({
  type: actions.CANDIDATE_WORKING_TIME_CLEAR,
  data
});

export const deleteCandidateRating = data => ({
  type: actions.DELETE_CANDIDATE_RATING,
  data
});

export const deleteCandidateInfringements = data => ({
  type: actions.DELETE_CANDIDATE_INFRINGEMENTS,
  data
});

export const deleteCandidateNotes = data => ({
  type: actions.DELETE_CANDIDATE_NOTES,
  data
});

export const deleteCandidateTickets = data => ({
  type: actions.DELETE_CANDIDATE_TICKETS,
  data
});

export const deleteCandidatePerformance = data => ({
  type: actions.DELETE_CANDIDATE_PERFORMANCE,
  data
});

export const updateCandidateTickets = data => ({
  type: actions.UPDATE_CANDIDATE_TICKETS,
  data
});

export const getBookingDetails = data => ({
  type: actions.GET_BOOKING_DETAILS,
  data
});

export const clearBookingDetails = data => ({
  type: actions.GET_BOOKING_DETAILS_CLEAR,
  data
});

export const getTagsList = () => ({
  type: actions.TAGS_LIST
});

export const getTagsListV2 = () => ({
  type: actions.TAGS_LIST_V2
});

export const addTagsList = data => ({
  type: actions.ADD_TAGS_LIST,
  data
});

export const getCandidateDetails = data => ({
  type: actions.CANDIDATE_DETAILS,
  data
});

export const updateCandidateDetails = data => ({
  type: actions.UPDATE_CANDIDATE_DETAILS,
  data
});

export const getContactDetails = data => ({
  type: actions.CONTACT_DETAILS,
  data
});

export const updateContactDetails = data => ({
  type: actions.UPDATE_CONTACT_DETAILS,
  data
});

export const getAdditionalContacts = data => ({
  type: actions.ADDITIONAL_CONTACT_DETAILS,
  data
});

export const updateAdditionalContacts = data => ({
  type: actions.UPDATE_ADDITIONAL_CONTACT,
  data
});

export const deleteAddress = data => ({
  type: actions.DELETE_ADDRESS_DETAILS,
  data
});

export const getWorkHistoryList = data => ({
  type: actions.GET_WORK_HISTORY_DETAILS,
  data
});

export const getStartEndDates = data => ({
  type: actions.GET_START_END_DATES,
  data
});

export const updateWorkHistory = data => ({
  type: actions.UPDATE_WORK_HISTORY,
  data
});

export const deleteWorkHistory = data => ({
  type: actions.DELETE_WORK_HISTORY,
  data
});

export const deleteStartEndDate = data => ({
  type: actions.DELETE_START_END_DATE,
  data
});

export const updateStartEndDate = data => ({
  type: actions.UPDATE_START_END_DATE,
  data
});

export const getInductionsBansList = data => ({
  type: actions.GET_INDUCTIONS_BANS_LIST,
  data
});

export const updateInductionsBansList = data => ({
  type: actions.UPDATE_INDUCTIONS_BANS,
  data
});

export const deleteInductionDetails = data => ({
  type: actions.DELETE_INDUCTIONS_DETAILS,
  data
});

export const deleteBanDetails = data => ({
  type: actions.DELETE_BANS_DETAILS,
  data
});

export const getPersonTimePreference = data => ({
  type: actions.GET_TIME_PREFERENCE,
  data
});

export const getPayrollInformation = data => ({
  type: actions.GET_PAYROLL_DETAILS,
  data
});

export const isCandidateSts = data => ({
  type: actions.GET_IS_CANDIDATE_STS,
  data
});

export const updatePayrollInformation = data => ({
  type: actions.UPDATE_PAYROLL_DETAILS,
  data
});

export const addDrivingLicense = data => ({
  type: actions.ADD_DRIVING_LICENSE,
  data
});

export const getDrivingLicense = data => ({
  type: actions.GET_DRIVING_LICENSE,
  data
});

export const getHumanResourceList = data => ({
  type: actions.GET_HUMAN_RESOURCE,
  data
});

export const updateHumanResource = data => ({
  type: actions.UPDATE_HUMAN_RESOURCE,
  data
});

export const addLicenceCapabilities = data => ({
  type: actions.ADD_LICENCE_CAPABILITIES,
  data
});

export const updateLicenceCapabilities = data => ({
  type: actions.UPDATE_LICENCE_CAPABILITIES,
  data
});

export const addDrivingLicenseEndorsement = data => ({
  type: actions.ADD_LICENCE_ENDORSEMENTS,
  data
});

export const updateDrivingLicenseEndorsement = data => ({
  type: actions.UPDATE_LICENCE_ENDORSEMENTS,
  data
});

export const deleteDrivingLicenseEndorsement = data => ({
  type: actions.DELETE_LICENCE_ENDORSEMENTS,
  data
});

export const deleteLicenceCapabilities = data => ({
  type: actions.DELETE_LICENCE_CAPABILITIES,
  data
});

export const addDigiCard = data => ({
  type: actions.ADD_DIGI_CARD,
  data
});

export const deleteDigiCard = data => ({
  type: actions.DELETE_DIGI_CARD,
  data
});

export const updateDigiCard = data => ({
  type: actions.UPDATE_DIGI_CARD,
  data
});

export const getDigiCard = data => ({
  type: actions.GET_DIGI_CARD,
  data
});

export const clearDigiCard = data => ({
  type: actions.GET_DIGI_CARD_CLEAR,
  data
});

export const getQualificationIdentification = data => ({
  type: actions.GET_QUALIFICATION_DETAILS,
  data
});

export const clearQualificationIdentification = data => ({
  type: actions.GET_QUALIFICATION_DETAILS_CLEAR,
  data
});

export const deleteQualificationIdentification = data => ({
  type: actions.DELETE_QUALIFICATION_DETAILS,
  data
});

export const updateQualificationIdentification = data => ({
  type: actions.UPDATE_QUALIFICATION_DETAILS,
  data
});

export const getReminderLists = data => ({
  type: actions.REMINDERS_LIST,
  data
});

export const deleteReminder = data => ({
  type: actions.DELETE_REMINDER,
  data
});

export const getAssignedTickets = data => ({
  type: actions.GET_ASSIGNED_TICKETS,
  data
});

export const getPayeStatementList = data => ({
  type: actions.GET_PAYE_LIST,
  data
});

export const deleteUserTags = data => ({
  type: actions.DELETE_USER_TAGS,
  data
});

export const listAttachments = data => ({
  type: actions.LIST_ATTACHMENTS,
  data
});

export const downloadDocument = data => ({
  type: actions.DOWNLOAD_DOCUMENT,
  data
});

export const clearDownloadDocument = data => ({
  type: actions.DOWNLOAD_DOCUMENT_CLEAR,
  data
});

export const deleteDocument = data => ({
  type: actions.DELETE_DOCUMENT,
  data
});

export const clearDeleteDocument = data => ({
  type: actions.DELETE_DOCUMENT_CLEAR,
  data
});

export const deleteSmartBox = data => ({
  type: actions.DELETE_SMART_BOX,
  data
});

export const refreshMetaCache = () => ({
  type: actions.REFRESH_META_CACHE
});

export const addClient = data => ({
  type: actions.ADD_CLIENT_INFO,
  data
});

export const updateClient = data => ({
  type: actions.UPDATE_CLIENT_INFO,
  data
});

export const deleteClient = data => ({
  type: actions.DELETE_CLIENT_INFO,
  data
});

export const addClientClear = () => ({
  type: actions.ADD_CLIENT_INFO_CLEAR
});

export const updateClientClear = () => ({
  type: actions.UPDATE_CLIENT_INFO_CLEAR
});

export const listAttachmentsClear = () => ({
  type: actions.LIST_ATTACHMENTS_CLEAR
});

export const multipleDocsClear = () => ({
  type: actions.ADD_MULTIPLE_DOCUMENTS_CLEAR
});

export const clearContactDetails = () => ({
  type: actions.CONTACT_DETAILS_CLEAR
});

export const clearAdditionalContacts = () => ({
  type: actions.ADDITIONAL_CONTACT_DETAILS_CLEAR
});

export const addNewCandidateClear = () => ({
  type: actions.ADD_NEW_CANDIDATE_CLEAR
});

export const sendAppSMS = data => ({
  type: actions.SEND_APP_SMS,
  data
});

export const getAppHistory = data => ({
  type: actions.APP_HISTORY_LIST,
  data
});

export const registerAppPerson = data => ({
  type: actions.REGISTER_APP_PERSON,
  data
});

export const detailPersonSearch = data => ({
  type: actions.DETAIL_PERSON_SEARCH,
  data
});

export const detailPersonSearchClear = () => ({
  type: actions.DETAIL_PERSON_SEARCH_CLEAR
});

export const sendBulkSMS = data => ({
  type: actions.SEND_BULK_SMS,
  data
});

export const clearSendBulkSMS = data => ({
  type: actions.SEND_BULK_SMS_CLEAR,
  data
});

export const sendSMSToPhoneNumber = data => ({
  type: actions.SEND_SMS_TO_PHONE_NUMBER,
  data
});

export const sendAvailabilityRequest = data => ({
  type: actions.SEND_AVAILABILITY_REQUEST,
  data
});

export const sendCalendarUpdated = data => ({
  type: actions.SEND_CALENDAR_UPDATED,
  data
});

export const sendNoWorkTomorrow = data => ({
  type: actions.SEND_NO_WORK_TOMORROW,
  data
});

export const sendCalendarSummary = data => ({
  type: actions.SEND_CALENDAR_SUMMARY,
  data
});

export const sendBookingDetails = data => ({
  type: actions.SEND_BOOKING_DETAILS,
  data
});

export const sendBookingHoursQuery = data => ({
  type: actions.SEND_BOOKING_HRS,
  data
});

export const getCandidateLinks = data => ({
  type: actions.GET_CANDIDATE_LINKS,
  data
});

export const getHolidayShiftsAdjustmentsList = data => ({
  type: actions.HOLIDAYS_SHIFT_LIST,
  data
});

export const getAccruedHolidayAdjustmentsList = data => ({
  type: actions.HOLIDAYS_ADJUSTMENT_LIST,
  data
});

export const addAccruedHolidayAdjustments = data => ({
  type: actions.ADD_HOLIDAYS_ADJUSTMENT_LIST,
  data
});

export const addShiftsAdjustmentsList = data => ({
  type: actions.ADD_HOLIDAYS_SHIFT_LIST,
  data
});

export const deleteShiftsAdjustmentsList = data => ({
  type: actions.DELETE_HOLIDAYS_SHIFT_LIST,
  data
});

export const deleteAccruedHolidayAdjustments = data => ({
  type: actions.DELETE_HOLIDAYS_ADJUSTMENT_LIST,
  data
});

export const requestRtwCheck = data => ({
  type: actions.REQUEST_RTW_CHECK,
  data
});

export const getHolidayPeriod = data => ({
  type: actions.HOLIDAY_PERIOD,
  data
});

export const createDetailedBookings = data => ({
  type: actions.DETAILED_BOOKINGS,
  data
});

export const clearDetailedBookings = () => ({
  type: actions.DETAILED_BOOKINGS_CLEAR
});

export const getCandidateDefaultExpense = data => ({
  type: actions.CANDIDATE_DEFAULT_EXPENSE,
  data
});

export const candidateClientDefaultExpense = data => ({
  type: actions.ACTION_CANDIDATE_DEFAULT_EXPENSE,
  data
});

export const clearDrivingLicense = () => ({
  type: actions.GET_DRIVING_LICENSE_CLEAR
});

export const setPassword = payload => ({
  type: actions.PASSWORD_SETUP,
  payload
});

export const getAPPDetails = payload => ({
  type: actions.GET_APP_DETAILS,
  payload
});

export const claimAPPID = payload => ({
  type: actions.CLAIM_APP_ID,
  payload
});

export const clearAppProfile = payload => ({
  type: actions.CLEAR_APP_PROFILE,
  payload
});
