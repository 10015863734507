import keyMirror from "keymirror";

const actions = {
  GET_SUPPLIER_LIST: null,
  GET_SUPPLIER_LIST_SUCCESS: null,
  GET_SUPPLIER_LIST_ERROR: null,
  GET_SUPPLIER_LIST_CLEAR: null,

  ADD_SUPPLIER: null,
  ADD_SUPPLIER_SUCCESS: null,
  ADD_SUPPLIER_ERROR: null,
  ADD_SUPPLIER_CLEAR: null,

  UPDATE_SUPPLIER: null,
  UPDATE_SUPPLIER_SUCCESS: null,
  UPDATE_SUPPLIER_ERROR: null,
  UPDATE_SUPPLIER_CLEAR: null,

  GET_SUPPLIER_EMAIL_TEMPLATE: null,
  GET_SUPPLIER_EMAIL_TEMPLATE_SUCCESS: null,
  GET_SUPPLIER_EMAIL_TEMPLATE_ERROR: null,
  GET_SUPPLIER_EMAIL_TEMPLATE_CLEAR: null,

  SEND_SUPPLIER_EMAIL: null,
  SEND_SUPPLIER_EMAIL_SUCCESS: null,
  SEND_SUPPLIER_EMAIL_ERROR: null,
  SEND_SUPPLIER_EMAIL_CLEAR: null,

  SUPPLIER_CLIENT_APPROVAL: null,
  SUPPLIER_CLIENT_APPROVAL_SUCCESS: null,
  SUPPLIER_CLIENT_APPROVAL_ERROR: null,
  SUPPLIER_CLIENT_APPROVAL_CLEAR: null,

  DELETE_SUPPLIER: null,
  DELETE_SUPPLIER_SUCCESS: null,
  DELETE_SUPPLIER_ERROR: null,
  DELETE_SUPPLIER_CLEAR: null,

  GET_LIST_SUPPLIER_CANDIDATES_PAGED: null,
  GET_LIST_SUPPLIER_CANDIDATES_PAGED_SUCCESS: null,
  GET_LIST_SUPPLIER_CANDIDATES_PAGED_ERROR: null,
  GET_LIST_SUPPLIER_CANDIDATES_PAGED_CLEAR: null,

  ADD_STS_CANDIDATE: null,
  ADD_STS_CANDIDATE_SUCCESS: null,
  ADD_STS_CANDIDATE_ERROR: null,
  ADD_STS_CANDIDATE_CLEAR: null
};

export default keyMirror(actions);
