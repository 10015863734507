import { combineReducers } from "redux";
import actions from "../actionTypes/bookingSetup";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

// CONTRACT_TYPES
const addContractTypes = createReducer(defaultState, {
  [actions.ADD_CONTRACT_TYPE]: isLoadingStateReducer,
  [actions.ADD_CONTRACT_TYPE_SUCCESS]: successStateReducer,
  [actions.ADD_CONTRACT_TYPE_ERROR]: errorStateReducer,
  [actions.ADD_CONTRACT_TYPE_CLEAR]: resetStateReducer
});
const updateContractTypeInfo = createReducer(defaultState, {
  [actions.UPDATE_CONTRACT_TYPE]: isLoadingStateReducer,
  [actions.UPDATE_CONTRACT_TYPE_SUCCESS]: successStateReducer,
  [actions.UPDATE_CONTRACT_TYPE_ERROR]: errorStateReducer,
  [actions.UPDATE_CONTRACT_TYPE_CLEAR]: resetStateReducer
});
const deleteContractTypes = createReducer(defaultState, {
  [actions.DELETE_CONTRACT_TYPE]: isLoadingStateReducer,
  [actions.DELETE_CONTRACT_TYPE_SUCCESS]: successStateReducer,
  [actions.DELETE_CONTRACT_TYPE_ERROR]: errorStateReducer,
  [actions.DELETE_CONTRACT_TYPE_CLEAR]: resetStateReducer
});

// QUALIFICATIONS
const getQualificationTitleList = createReducer(defaultState, {
  [actions.GET_QUALIFICATION_LIST]: isLoadingStateReducer,
  [actions.GET_QUALIFICATION_LIST_SUCCESS]: successStateReducer,
  [actions.GET_QUALIFICATION_LIST_ERROR]: errorStateReducer,
  [actions.GET_QUALIFICATION_LIST_CLEAR]: resetStateReducer
});
const addQualifications = createReducer(defaultState, {
  [actions.ADD_QUALIFICATION_TYPE]: isLoadingStateReducer,
  [actions.ADD_QUALIFICATION_TYPE_SUCCESS]: successStateReducer,
  [actions.ADD_QUALIFICATION_TYPE_ERROR]: errorStateReducer,
  [actions.ADD_QUALIFICATION_TYPE_CLEAR]: resetStateReducer
});

const deleteQualification = createReducer(defaultState, {
  [actions.DELETE_QUALIFICATION_TYPE]: isLoadingStateReducer,
  [actions.DELETE_QUALIFICATION_TYPE_SUCCESS]: successStateReducer,
  [actions.DELETE_QUALIFICATION_TYPE_ERROR]: errorStateReducer,
  [actions.DELETE_QUALIFICATION_TYPE_CLEAR]: resetStateReducer
});

const updateQualification = createReducer(defaultState, {
  [actions.UPDATE_QUALIFICATION_ENTRY]: isLoadingStateReducer,
  [actions.UPDATE_QUALIFICATION_ENTRY_SUCCESS]: successStateReducer,
  [actions.UPDATE_QUALIFICATION_ENTRY_ERROR]: errorStateReducer,
  [actions.UPDATE_QUALIFICATION_ENTRY_CLEAR]: resetStateReducer
});

const getListTypeInfo = createReducer(defaultState, {
  [actions.GET_USERLIST]: isLoadingStateReducer,
  [actions.GET_USERLIST_SUCCESS]: successStateReducer,
  [actions.GET_USERLIST_ERROR]: errorStateReducer,
  [actions.GET_USERLIST_CLEAR]: resetStateReducer
});

const getContractTypeList = createReducer(defaultState, {
  [actions.GET_CONTRACT_TYPE_LIST]: isLoadingStateReducer,
  [actions.GET_CONTRACT_TYPE_LIST_SUCCESS]: successStateReducer,
  [actions.GET_CONTRACT_TYPE_LIST_ERROR]: errorStateReducer,
  [actions.GET_CONTRACT_TYPE_LIST_CLEAR]: resetStateReducer
});

const getUserGroupList = createReducer(defaultState, {
  [actions.GET_GROUP_USERS_LIST]: isLoadingStateReducer,
  [actions.GET_GROUP_USERS_LIST_SUCCESS]: successStateReducer,
  [actions.GET_GROUP_USERS_LIST_ERROR]: errorStateReducer,
  [actions.GET_GROUP_USERS_LIST]: resetStateReducer
});

const deleteUserGroup = createReducer(defaultState, {
  [actions.DELETE_USER_GROUP]: isLoadingStateReducer,
  [actions.DELETE_USER_GROUP_SUCCESS]: successStateReducer,
  [actions.DELETE_USER_GROUP_ERROR]: errorStateReducer,
  [actions.DELETE_USER_GROUP_CLEAR]: resetStateReducer
});

const addNewUserGroup = createReducer(defaultState, {
  [actions.ADD_USER_GROUP]: isLoadingStateReducer,
  [actions.ADD_USER_GROUP_SUCCESS]: successStateReducer,
  [actions.ADD_USER_GROUP_ERROR]: errorStateReducer,
  [actions.ADD_USER_GROUP_CLEAR]: resetStateReducer
});

const getBookingExpenseTypes = createReducer(defaultState, {
  [actions.GET_EXPENSE_TYPE_BOOKING]: isLoadingStateReducer,
  [actions.GET_EXPENSE_TYPE_BOOKING_SUCCESS]: successStateReducer,
  [actions.GET_EXPENSE_TYPE_BOOKING_ERROR]: errorStateReducer,
  [actions.GET_EXPENSE_TYPE_BOOKING_CLEAR]: resetStateReducer
});

const addBookingExpenseType = createReducer(defaultState, {
  [actions.ADD_NEW_BOOKING_EXPENSE]: isLoadingStateReducer,
  [actions.ADD_NEW_BOOKING_EXPENSE_SUCCESS]: successStateReducer,
  [actions.ADD_NEW_BOOKING_EXPENSE_ERROR]: errorStateReducer,
  [actions.ADD_NEW_BOOKING_EXPENSE_CLEAR]: resetStateReducer
});

const deleteBookingExpenseType = createReducer(defaultState, {
  [actions.DELETE_BOOKING_EXPENSE_TYPE]: isLoadingStateReducer,
  [actions.DELETE_BOOKING_EXPENSE_TYPE_SUCCESS]: successStateReducer,
  [actions.DELETE_BOOKING_EXPENSE_TYPE_ERROR]: errorStateReducer,
  [actions.DELETE_BOOKING_EXPENSE_TYPE_CLEAR]: resetStateReducer
});

const getTags = createReducer(defaultState, {
  [actions.GET_TAG_NAME]: isLoadingStateReducer,
  [actions.GET_TAG_NAME_SUCCESS]: successStateReducer,
  [actions.GET_TAG_NAME_ERROR]: errorStateReducer,
  [actions.GET_TAG_NAME_CLEAR]: resetStateReducer
});

const deleteTag = createReducer(defaultState, {
  [actions.DELETE_TAG_NAME]: isLoadingStateReducer,
  [actions.DELETE_TAG_NAME_SUCCESS]: successStateReducer,
  [actions.DELETE_TAG_NAME_ERROR]: errorStateReducer,
  [actions.DELETE_TAG_NAME_CLEAR]: resetStateReducer
});

const createTag = createReducer(defaultState, {
  [actions.CREATE_TAG_NAME]: isLoadingStateReducer,
  [actions.CREATE_TAG_NAME_SUCCESS]: successStateReducer,
  [actions.CREATE_TAG_NAME_ERROR]: errorStateReducer,
  [actions.CREATE_TAG_NAME_CLEAR]: resetStateReducer
});

const updateTag = createReducer(defaultState, {
  [actions.UPDATE_TAG_NAME]: isLoadingStateReducer,
  [actions.UPDATE_TAG_NAME_SUCCESS]: successStateReducer,
  [actions.UPDATE_TAG_NAME_ERROR]: errorStateReducer,
  [actions.UPDATE_TAG_NAME_CLEAR]: resetStateReducer
});

const getCandidateRatingTypeList = createReducer(defaultState, {
  [actions.GET_CANDIDATE_RATING_TYPES]: isLoadingStateReducer,
  [actions.GET_CANDIDATE_RATING_TYPES_SUCCESS]: successStateReducer,
  [actions.GET_CANDIDATE_RATING_TYPES_ERROR]: errorStateReducer,
  [actions.GET_CANDIDATE_RATING_TYPES_CLEAR]: resetStateReducer
});

const deleteCandidateRatingType = createReducer(defaultState, {
  [actions.DELETE_CANDIDATE_RATING_TYPES]: isLoadingStateReducer,
  [actions.DELETE_CANDIDATE_RATING_TYPES_SUCCESS]: successStateReducer,
  [actions.DELETE_CANDIDATE_RATING_TYPES_ERROR]: errorStateReducer,
  [actions.DELETE_CANDIDATE_RATING_TYPES_CLEAR]: resetStateReducer
});

const addCandidateRatingType = createReducer(defaultState, {
  [actions.ADD_CANDIDATE_RATING_TYPES]: isLoadingStateReducer,
  [actions.ADD_CANDIDATE_RATING_TYPES_SUCCESS]: successStateReducer,
  [actions.ADD_CANDIDATE_RATING_TYPES_ERROR]: errorStateReducer,
  [actions.ADD_CANDIDATE_RATING_TYPES_CLEAR]: resetStateReducer
});

const updateCandidateRatingTypeInfo = createReducer(defaultState, {
  [actions.UPDATE_CANDIDATE_RATING_TYPES]: isLoadingStateReducer,
  [actions.UPDATE_CANDIDATE_RATING_TYPES_SUCCESS]: successStateReducer,
  [actions.UPDATE_CANDIDATE_RATING_TYPES_ERROR]: errorStateReducer,
  [actions.UPDATE_CANDIDATE_RATING_TYPES_CLEAR]: resetStateReducer
});

const updateBookingExpenseType = createReducer(defaultState, {
  [actions.UPDATE_EXPENSE_TYPES]: isLoadingStateReducer,
  [actions.UPDATE_EXPENSE_TYPES_SUCCESS]: successStateReducer,
  [actions.UPDATE_EXPENSE_TYPES_ERROR]: errorStateReducer,
  [actions.UPDATE_EXPENSE_TYPES_CLEAR]: resetStateReducer
});

const updateUserGroup = createReducer(defaultState, {
  [actions.UPDATE_GROUP_NAME]: isLoadingStateReducer,
  [actions.UPDATE_GROUP_NAME_SUCCESS]: successStateReducer,
  [actions.UPDATE_GROUP_NAME_ERROR]: errorStateReducer,
  [actions.UPDATE_GROUP_NAME_CLEAR]: resetStateReducer
});

const getCandidateInfringementTypeList = createReducer(defaultState, {
  [actions.GET_INFRINGEMENT_TYPE]: isLoadingStateReducer,
  [actions.GET_INFRINGEMENT_TYPE_SUCCESS]: successStateReducer,
  [actions.GET_INFRINGEMENT_TYPE_ERROR]: errorStateReducer,
  [actions.GET_INFRINGEMENT_TYPE_CLEAR]: resetStateReducer
});

const deleteCandidateInfringementType = createReducer(defaultState, {
  [actions.DELETE_INFRINGEMENT_TYPE]: isLoadingStateReducer,
  [actions.DELETE_INFRINGEMENT_TYPE_SUCCESS]: successStateReducer,
  [actions.DELETE_INFRINGEMENT_TYPE_ERROR]: errorStateReducer,
  [actions.DELETE_INFRINGEMENT_TYPE_CLEAR]: resetStateReducer
});

const addNewCandidateInfringementType = createReducer(defaultState, {
  [actions.ADD_INFRINGEMENT_TYPE]: isLoadingStateReducer,
  [actions.ADD_INFRINGEMENT_TYPE_SUCCESS]: successStateReducer,
  [actions.ADD_INFRINGEMENT_TYPE_ERROR]: errorStateReducer,
  [actions.ADD_INFRINGEMENT_TYPE_CLEAR]: resetStateReducer
});

const updateCandidateInfringementType = createReducer(defaultState, {
  [actions.UPDATE_INFRINGEMENT_TYPE]: isLoadingStateReducer,
  [actions.UPDATE_INFRINGEMENT_TYPE_SUCCESS]: successStateReducer,
  [actions.UPDATE_INFRINGEMENT_TYPE_ERROR]: errorStateReducer,
  [actions.UPDATE_INFRINGEMENT_TYPE_CLEAR]: resetStateReducer
});

const getCandidatePerformanceTypeList = createReducer(defaultState, {
  [actions.GET_PERFORMANCE_TYPES]: isLoadingStateReducer,
  [actions.GET_PERFORMANCE_TYPES_SUCCESS]: successStateReducer,
  [actions.GET_PERFORMANCE_TYPES_ERROR]: errorStateReducer,
  [actions.GET_PERFORMANCE_TYPES_CLEAR]: resetStateReducer
});

const deleteCandidatePerformanceType = createReducer(defaultState, {
  [actions.DELETE_PERFORMANCE_TYPES]: isLoadingStateReducer,
  [actions.DELETE_PERFORMANCE_TYPES_SUCCESS]: successStateReducer,
  [actions.DELETE_PERFORMANCE_TYPES_ERROR]: errorStateReducer,
  [actions.DELETE_PERFORMANCE_TYPES_CLEAR]: resetStateReducer
});

const addCandidatePerformanceType = createReducer(defaultState, {
  [actions.ADD_PERFORMANCE_TYPES]: isLoadingStateReducer,
  [actions.ADD_PERFORMANCE_TYPES_SUCCESS]: successStateReducer,
  [actions.ADD_PERFORMANCE_TYPES_ERROR]: errorStateReducer,
  [actions.ADD_PERFORMANCE_TYPES_CLEAR]: resetStateReducer
});

const updateCandidatePerformanceType = createReducer(defaultState, {
  [actions.UPDATE_PERFORMANCE_TYPES]: isLoadingStateReducer,
  [actions.UPDATE_PERFORMANCE_TYPES_SUCCESS]: successStateReducer,
  [actions.UPDATE_PERFORMANCE_TYPES_ERROR]: errorStateReducer,
  [actions.UPDATE_PERFORMANCE_TYPES_CLEAR]: resetStateReducer
});

const getNonConversionReasonList = createReducer(defaultState, {
  [actions.GET_NON_CONVERSION_LIST]: isLoadingStateReducer,
  [actions.GET_NON_CONVERSION_LIST_SUCCESS]: successStateReducer,
  [actions.GET_NON_CONVERSION_LIST_ERROR]: errorStateReducer,
  [actions.GET_NON_CONVERSION_LIST_CLEAR]: resetStateReducer
});

const getInsuranceCategoryList = createReducer(defaultState, {
  [actions.GET_INSURANCE_CATEGORY_LIST]: isLoadingStateReducer,
  [actions.GET_INSURANCE_CATEGORY_LIST_SUCCESS]: successStateReducer,
  [actions.GET_INSURANCE_CATEGORY_LIST_ERROR]: errorStateReducer,
  [actions.GET_INSURANCE_CATEGORY_LIST_CLEAR]: resetStateReducer
});

const deleteInsuranceCategoryType = createReducer(defaultState, {
  [actions.DELETE_INSURANCE_CATEGORY]: isLoadingStateReducer,
  [actions.DELETE_INSURANCE_CATEGORY_SUCCESS]: successStateReducer,
  [actions.DELETE_INSURANCE_CATEGORY_ERROR]: errorStateReducer,
  [actions.DELETE_INSURANCE_CATEGORY_CLEAR]: resetStateReducer
});

const addInsuranceCategoryType = createReducer(defaultState, {
  [actions.ADD_INSURANCE_CATEGORY]: isLoadingStateReducer,
  [actions.ADD_INSURANCE_CATEGORY_SUCCESS]: successStateReducer,
  [actions.ADD_INSURANCE_CATEGORY_ERROR]: errorStateReducer,
  [actions.ADD_INSURANCE_CATEGORY_CLEAR]: resetStateReducer
});

const updateInsuranceCategoryType = createReducer(defaultState, {
  [actions.UPDATE_INSURANCE_CATEGORY]: isLoadingStateReducer,
  [actions.UPDATE_INSURANCE_CATEGORY_SUCCESS]: successStateReducer,
  [actions.UPDATE_INSURANCE_CATEGORY_ERROR]: errorStateReducer,
  [actions.UPDATE_INSURANCE_CATEGORY_CLEAR]: resetStateReducer
});

const getLeadSourceList = createReducer(defaultState, {
  [actions.GET_LEAD_SOURCE_TYPES]: isLoadingStateReducer,
  [actions.GET_LEAD_SOURCE_TYPES_SUCCESS]: successStateReducer,
  [actions.GET_LEAD_SOURCE_TYPES_ERROR]: errorStateReducer,
  [actions.GET_LEAD_SOURCE_TYPES_CLEAR]: resetStateReducer
});

const addNonConversionReasonType = createReducer(defaultState, {
  [actions.ADD_NON_CONVERSION_TYPE]: isLoadingStateReducer,
  [actions.ADD_NON_CONVERSION_TYPE_SUCCESS]: successStateReducer,
  [actions.ADD_NON_CONVERSION_TYPE_ERROR]: errorStateReducer,
  [actions.ADD_NON_CONVERSION_TYPE_CLEAR]: resetStateReducer
});

const updateNonConversionReasonType = createReducer(defaultState, {
  [actions.UPDATE_NON_CONVERSION_TYPE]: isLoadingStateReducer,
  [actions.UPDATE_NON_CONVERSION_TYPE_SUCCESS]: successStateReducer,
  [actions.UPDATE_NON_CONVERSION_TYPE_ERROR]: errorStateReducer,
  [actions.UPDATE_NON_CONVERSION_TYPE_CLEAR]: resetStateReducer
});

const deleteNonConversionReasonType = createReducer(defaultState, {
  [actions.DELETE_NON_CONVERSION_TYPE]: isLoadingStateReducer,
  [actions.DELETE_NON_CONVERSION_TYPE_SUCCESS]: successStateReducer,
  [actions.DELETE_NON_CONVERSION_TYPE_ERROR]: errorStateReducer,
  [actions.DELETE_NON_CONVERSION_TYPE_CLEAR]: resetStateReducer
});

const addLeadSourceType = createReducer(defaultState, {
  [actions.ADD_LEAD_SOURCE_TYPES]: isLoadingStateReducer,
  [actions.ADD_LEAD_SOURCE_TYPES_SUCCESS]: successStateReducer,
  [actions.ADD_LEAD_SOURCE_TYPES_ERROR]: errorStateReducer,
  [actions.ADD_LEAD_SOURCE_TYPES_CLEAR]: resetStateReducer
});

const updateLeadSourceType = createReducer(defaultState, {
  [actions.UPDATE_LEAD_SOURCE_TYPE]: isLoadingStateReducer,
  [actions.UPDATE_LEAD_SOURCE_TYPE_SUCCESS]: successStateReducer,
  [actions.UPDATE_LEAD_SOURCE_TYPE_ERROR]: errorStateReducer,
  [actions.UPDATE_LEAD_SOURCE_TYPE_CLEAR]: resetStateReducer
});

const deleteLeadSourceType = createReducer(defaultState, {
  [actions.DELETE_LEAD_SOURCE_TYPES]: isLoadingStateReducer,
  [actions.DELETE_LEAD_SOURCE_TYPES_SUCCESS]: successStateReducer,
  [actions.DELETE_LEAD_SOURCE_TYPES_ERROR]: errorStateReducer,
  [actions.DELETE_LEAD_SOURCE_TYPES_CLEAR]: resetStateReducer
});

const getReasonForLeavingList = createReducer(defaultState, {
  [actions.GET_LEAVING_REASON_TYPE]: isLoadingStateReducer,
  [actions.GET_LEAVING_REASON_TYPE_SUCCESS]: successStateReducer,
  [actions.GET_LEAVING_REASON_TYPE_ERROR]: errorStateReducer,
  [actions.GET_LEAVING_REASON_TYPE_CLEAR]: resetStateReducer
});

const addLeavingReasonType = createReducer(defaultState, {
  [actions.ADD_LEAVING_REASON_TYPE]: isLoadingStateReducer,
  [actions.ADD_LEAVING_REASON_TYPE_SUCCESS]: successStateReducer,
  [actions.ADD_LEAVING_REASON_TYPE_ERROR]: errorStateReducer,
  [actions.ADD_LEAVING_REASON_TYPE_CLEAR]: resetStateReducer
});

const updateLeavingReasonType = createReducer(defaultState, {
  [actions.UPDATE_LEAVING_REASON_TYPE]: isLoadingStateReducer,
  [actions.UPDATE_LEAVING_REASON_TYPE_SUCCESS]: successStateReducer,
  [actions.UPDATE_LEAVING_REASON_TYPE_ERROR]: errorStateReducer,
  [actions.UPDATE_LEAVING_REASON_TYPE_CLEAR]: resetStateReducer
});

const deleteLeavingReasonType = createReducer(defaultState, {
  [actions.DELETE_LEAVING_REASON_TYPE]: isLoadingStateReducer,
  [actions.DELETE_LEAVING_REASON_TYPE_SUCCESS]: successStateReducer,
  [actions.DELETE_LEAVING_REASON_TYPE_ERROR]: errorStateReducer,
  [actions.DELETE_LEAVING_REASON_TYPE_CLEAR]: resetStateReducer
});

const addBankHoliday = createReducer(defaultState, {
  [actions.ADD_BANK_HOLIDAY]: isLoadingStateReducer,
  [actions.ADD_BANK_HOLIDAY_SUCCESS]: successStateReducer,
  [actions.ADD_BANK_HOLIDAY_ERROR]: errorStateReducer,
  [actions.ADD_BANK_HOLIDAY_CLEAR]: resetStateReducer
});

const updateBankHolidayInfo = createReducer(defaultState, {
  [actions.UPDATE_BANK_HOLIDAY]: isLoadingStateReducer,
  [actions.UPDATE_BANK_HOLIDAY_SUCCESS]: successStateReducer,
  [actions.UPDATE_BANK_HOLIDAY_ERROR]: errorStateReducer,
  [actions.UPDATE_BANK_HOLIDAY_CLEAR]: resetStateReducer
});

const deleteBankHoliday = createReducer(defaultState, {
  [actions.DELETE_BANK_HOLIDAY]: isLoadingStateReducer,
  [actions.DELETE_BANK_HOLIDAY_SUCCESS]: successStateReducer,
  [actions.DELETE_BANK_HOLIDAY_ERROR]: errorStateReducer,
  [actions.DELETE_BANK_HOLIDAY_CLEAR]: resetStateReducer
});

const getBankHolidayList = createReducer(defaultState, {
  [actions.GET_BANK_HOLIDAY]: isLoadingStateReducer,
  [actions.GET_BANK_HOLIDAY_SUCCESS]: successStateReducer,
  [actions.GET_BANK_HOLIDAY_ERROR]: errorStateReducer,
  [actions.GET_BANK_HOLIDAY_CLEAR]: resetStateReducer
});

const addBookingTypeData = createReducer(defaultState, {
  [actions.ADD_BOOKING_TYPE]: isLoadingStateReducer,
  [actions.ADD_BOOKING_TYPE_SUCCESS]: successStateReducer,
  [actions.ADD_BOOKING_TYPE_ERROR]: errorStateReducer,
  [actions.ADD_BOOKING_TYPE_CLEAR]: resetStateReducer
});

const getBookingType = createReducer(defaultState, {
  [actions.GET_BOOKING_TYPE]: isLoadingStateReducer,
  [actions.GET_BOOKING_TYPE_SUCCESS]: successStateReducer,
  [actions.GET_BOOKING_TYPE_ERROR]: errorStateReducer,
  [actions.GET_BOOKING_TYPE_CLEAR]: resetStateReducer
});

const updateBookingTypeData = createReducer(defaultState, {
  [actions.UPDATE_BOOKING_TYPE]: isLoadingStateReducer,
  [actions.UPDATE_BOOKING_TYPE_SUCCESS]: successStateReducer,
  [actions.UPDATE_BOOKING_TYPE_ERROR]: errorStateReducer,
  [actions.UPDATE_BOOKING_TYPE_CLEAR]: resetStateReducer
});

const getServiceCompanyList = createReducer(defaultState, {
  [actions.GET_SERVICE_COMPANIES]: isLoadingStateReducer,
  [actions.GET_SERVICE_COMPANIES_SUCCESS]: successStateReducer,
  [actions.GET_SERVICE_COMPANIES_ERROR]: errorStateReducer,
  [actions.GET_SERVICE_COMPANIES_CLEAR]: resetStateReducer
});

const getIdentificationNameList = createReducer(defaultState, {
  [actions.GET_IDENTIFICATION_NAME_LIST]: isLoadingStateReducer,
  [actions.GET_IDENTIFICATION_NAME_LIST_SUCCESS]: successStateReducer,
  [actions.GET_IDENTIFICATION_NAME_LIST_ERROR]: errorStateReducer,
  [actions.GET_IDENTIFICATION_NAME_LIST_CLEAR]: resetStateReducer
});

const deleteBookingTypeData = createReducer(defaultState, {
  [actions.DELETE_BOOKING_TYPE]: isLoadingStateReducer,
  [actions.DELETE_BOOKING_TYPE_SUCCESS]: successStateReducer,
  [actions.DELETE_BOOKING_TYPE_ERROR]: errorStateReducer,
  [actions.DELETE_BOOKING_TYPE_CLEAR]: resetStateReducer
});

const addNewIdentification = createReducer(defaultState, {
  [actions.ADD_IDENTIFICATION_NAME_LIST]: isLoadingStateReducer,
  [actions.ADD_IDENTIFICATION_NAME_LIST_SUCCESS]: successStateReducer,
  [actions.ADD_IDENTIFICATION_NAME_LIST_ERROR]: errorStateReducer,
  [actions.ADD_IDENTIFICATION_NAME_LIST_CLEAR]: resetStateReducer
});

const updateIdentification = createReducer(defaultState, {
  [actions.UPDATE_IDENTIFICATION_TYPE]: isLoadingStateReducer,
  [actions.UPDATE_IDENTIFICATION_TYPE_SUCCESS]: successStateReducer,
  [actions.UPDATE_IDENTIFICATION_TYPE_ERROR]: errorStateReducer,
  [actions.UPDATE_IDENTIFICATION_TYPE_CLEAR]: resetStateReducer
});

const deleteIdentification = createReducer(defaultState, {
  [actions.DELETE_IDENTIFICATION_TYPE]: isLoadingStateReducer,
  [actions.DELETE_IDENTIFICATION_TYPE_SUCCESS]: successStateReducer,
  [actions.DELETE_IDENTIFICATION_TYPE_ERROR]: errorStateReducer,
  [actions.DELETE_IDENTIFICATION_TYPE_CLEAR]: resetStateReducer
});

const getPhoneNumbersList = createReducer(defaultState, {
  [actions.GET_PHONE_NUMBER_LIST]: isLoadingStateReducer,
  [actions.GET_PHONE_NUMBER_LIST_SUCCESS]: successStateReducer,
  [actions.GET_PHONE_NUMBER_LIST_ERROR]: errorStateReducer,
  [actions.GET_PHONE_NUMBER_LIST_CLEAR]: resetStateReducer
});

const getClientDocumentTypeList = createReducer(defaultState, {
  [actions.GET_CLIENT_DOCUMENT_LIST]: isLoadingStateReducer,
  [actions.GET_CLIENT_DOCUMENT_LIST_SUCCESS]: successStateReducer,
  [actions.GET_CLIENT_DOCUMENT_LIST_ERROR]: errorStateReducer,
  [actions.GET_CLIENT_DOCUMENT_LIST_CLEAR]: resetStateReducer
});

const addClientDocumentType = createReducer(defaultState, {
  [actions.ADD_CLIENT_DOCUMENT]: isLoadingStateReducer,
  [actions.ADD_CLIENT_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.ADD_CLIENT_DOCUMENT_ERROR]: errorStateReducer,
  [actions.ADD_CLIENT_DOCUMENT_CLEAR]: resetStateReducer
});

const updateClientDocumentType = createReducer(defaultState, {
  [actions.UPDATE_CLIENT_DOCUMENT]: isLoadingStateReducer,
  [actions.UPDATE_CLIENT_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.UPDATE_CLIENT_DOCUMENT_ERROR]: errorStateReducer,
  [actions.UPDATE_CLIENT_DOCUMENT_CLEAR]: resetStateReducer
});

const deleteClientDocumentType = createReducer(defaultState, {
  [actions.DELETE_CLIENT_DOCUMENT]: isLoadingStateReducer,
  [actions.DELETE_CLIENT_DOCUMENT_SUCCESS]: successStateReducer,
  [actions.DELETE_CLIENT_DOCUMENT_ERROR]: errorStateReducer,
  [actions.DELETE_CLIENT_DOCUMENT_CLEAR]: resetStateReducer
});

const getContactDetails = createReducer(defaultState, {
  [actions.GET_CONTACT]: isLoadingStateReducer,
  [actions.GET_CONTACT_SUCCESS]: successStateReducer,
  [actions.GET_CONTACT_ERROR]: errorStateReducer,
  [actions.GET_CONTACT_CLEAR]: resetStateReducer
});

const addServiceCompany = createReducer(defaultState, {
  [actions.ADD_SERVICE_COMPANY]: isLoadingStateReducer,
  [actions.ADD_SERVICE_COMPANY_SUCCESS]: successStateReducer,
  [actions.ADD_SERVICE_COMPANY_ERROR]: errorStateReducer,
  [actions.ADD_SERVICE_COMPANY_CLEAR]: resetStateReducer
});

const updateServiceCompany = createReducer(defaultState, {
  [actions.UPDATE_SERVICE_COMPANY]: isLoadingStateReducer,
  [actions.UPDATE_SERVICE_COMPANY_SUCCESS]: successStateReducer,
  [actions.UPDATE_SERVICE_COMPANY_ERROR]: errorStateReducer,
  [actions.UPDATE_SERVICE_COMPANY_CLEAR]: resetStateReducer
});

const deleteServiceCompany = createReducer(defaultState, {
  [actions.DELETE_SERVICE_COMPANY]: isLoadingStateReducer,
  [actions.DELETE_SERVICE_COMPANY_SUCCESS]: successStateReducer,
  [actions.DELETE_SERVICE_COMPANY_ERROR]: errorStateReducer,
  [actions.DELETE_SERVICE_COMPANY_CLEAR]: resetStateReducer
});

const getStartDay = createReducer(defaultState, {
  [actions.GET_START_DAY]: isLoadingStateReducer,
  [actions.GET_START_DAY_SUCCESS]: successStateReducer,
  [actions.GET_START_DAY_ERROR]: errorStateReducer,
  [actions.GET_START_DAY_CLEAR]: resetStateReducer
});

const sendPayDocuments = createReducer(defaultState, {
  [actions.SEND_PAY_EMAIL]: isLoadingStateReducer,
  [actions.SEND_PAY_EMAIL_SUCCESS]: successStateReducer,
  [actions.SEND_PAY_EMAIL_ERROR]: errorStateReducer,
  [actions.SEND_PAY_EMAIL_CLEAR]: resetStateReducer
});

const getAllSiteList = createReducer(defaultState, {
  [actions.GET_ALL_SITES]: isLoadingStateReducer,
  [actions.GET_ALL_SITES_SUCCESS]: successStateReducer,
  [actions.GET_ALL_SITES_ERROR]: errorStateReducer,
  [actions.GET_ALL_SITES_CLEAR]: resetStateReducer
});

const getAllMobileAppLinks = createReducer(defaultState, {
  [actions.GET_ALL_MOBILE_APP_LINKS]: isLoadingStateReducer,
  [actions.GET_ALL_MOBILE_APP_LINKS_SUCCESS]: successStateReducer,
  [actions.GET_ALL_MOBILE_APP_LINKS_ERROR]: errorStateReducer,
  [actions.GET_ALL_MOBILE_APP_LINKS_CLEAR]: resetStateReducer
});

const getAllReasonCodes = createReducer(defaultState, {
  [actions.GET_ALL_REASON_CODES]: isLoadingStateReducer,
  [actions.GET_ALL_REASON_CODES_SUCCESS]: successStateReducer,
  [actions.GET_ALL_REASON_CODES_ERROR]: errorStateReducer,
  [actions.GET_ALL_REASON_CODES_CLEAR]: resetStateReducer
});

const addReasonCode = createReducer(defaultState, {
  [actions.ADD_REASON_CODE]: isLoadingStateReducer,
  [actions.ADD_REASON_CODE_SUCCESS]: successStateReducer,
  [actions.ADD_REASON_CODE_ERROR]: errorStateReducer,
  [actions.ADD_REASON_CODE_CLEAR]: resetStateReducer
});

const deleteReasonCode = createReducer(defaultState, {
  [actions.DELETE_REASON_CODE]: isLoadingStateReducer,
  [actions.DELETE_REASON_CODE_SUCCESS]: successStateReducer,
  [actions.DELETE_REASON_CODE_ERROR]: errorStateReducer,
  [actions.DELETE_REASON_CODE_CLEAR]: resetStateReducer
});

const updateReasonCode = createReducer(defaultState, {
  [actions.UPDATE_REASON_CODE]: isLoadingStateReducer,
  [actions.UPDATE_REASON_CODE_SUCCESS]: successStateReducer,
  [actions.UPDATE_REASON_CODE_ERROR]: errorStateReducer,
  [actions.UPDATE_REASON_CODE_CLEAR]: resetStateReducer
});

const addMobileAppLink = createReducer(defaultState, {
  [actions.ADD_MOBILE_APP_LINK]: isLoadingStateReducer,
  [actions.ADD_MOBILE_APP_LINK_SUCCESS]: successStateReducer,
  [actions.ADD_MOBILE_APP_LINK_ERROR]: errorStateReducer,
  [actions.ADD_MOBILE_APP_LINK_CLEAR]: resetStateReducer
});

const updateMobileAppLink = createReducer(defaultState, {
  [actions.UPDATE_MOBILE_APP_LINK]: isLoadingStateReducer,
  [actions.UPDATE_MOBILE_APP_LINK_SUCCESS]: successStateReducer,
  [actions.UPDATE_MOBILE_APP_LINK_ERROR]: errorStateReducer,
  [actions.UPDATE_MOBILE_APP_LINK_CLEAR]: resetStateReducer
});

const deleteMobileAppLink = createReducer(defaultState, {
  [actions.DELETE_MOBILE_APP_LINK]: isLoadingStateReducer,
  [actions.DELETE_MOBILE_APP_LINK_SUCCESS]: successStateReducer,
  [actions.DELETE_MOBILE_APP_LINK_ERROR]: errorStateReducer,
  [actions.DELETE_MOBILE_APP_LINK_CLEAR]: resetStateReducer
});

const getAllSiteListPaged = createReducer(defaultState, {
  [actions.GET_ALL_SITES_PAGED]: isLoadingStateReducer,
  [actions.GET_ALL_SITES_PAGED_SUCCESS]: successStateReducer,
  [actions.GET_ALL_SITES_PAGED_ERROR]: errorStateReducer,
  [actions.GET_ALL_SITES_PAGED_CLEAR]: resetStateReducer
});

export default combineReducers({
  getAllSiteList,
  updateReasonCode,
  deleteReasonCode,
  getAllReasonCodes,
  addReasonCode,
  deleteMobileAppLink,
  updateMobileAppLink,
  addMobileAppLink,
  getAllMobileAppLinks,
  getAllSiteListPaged,
  sendPayDocuments,
  getStartDay,
  deleteServiceCompany,
  updateServiceCompany,
  addServiceCompany,
  getContactDetails,
  deleteClientDocumentType,
  updateClientDocumentType,
  getClientDocumentTypeList,
  addClientDocumentType,
  deleteIdentification,
  updateIdentification,
  addNewIdentification,
  getBankHolidayList,
  addBankHoliday,
  deleteBankHoliday,
  updateBankHolidayInfo,
  deleteLeavingReasonType,
  updateLeavingReasonType,
  addLeavingReasonType,
  deleteLeadSourceType,
  getReasonForLeavingList,
  updateLeadSourceType,
  addLeadSourceType,
  addContractTypes,
  deleteContractTypes,
  updateContractTypeInfo,
  updateQualification,
  deleteQualification,
  getListTypeInfo,
  getContractTypeList,
  getQualificationTitleList,
  getUserGroupList,
  deleteUserGroup,
  addNewUserGroup,
  getBookingExpenseTypes,
  addBookingExpenseType,
  deleteBookingExpenseType,
  getTags,
  deleteTag,
  createTag,
  updateTag,
  getCandidateRatingTypeList,
  deleteCandidateRatingType,
  addCandidateRatingType,
  updateCandidateRatingTypeInfo,
  updateBookingExpenseType,
  updateUserGroup,
  getCandidateInfringementTypeList,
  deleteCandidateInfringementType,
  addNewCandidateInfringementType,
  updateCandidateInfringementType,
  getCandidatePerformanceTypeList,
  deleteCandidatePerformanceType,
  addCandidatePerformanceType,
  updateCandidatePerformanceType,
  getNonConversionReasonList,
  getInsuranceCategoryList,
  deleteInsuranceCategoryType,
  addInsuranceCategoryType,
  updateInsuranceCategoryType,
  getLeadSourceList,
  addNonConversionReasonType,
  updateNonConversionReasonType,
  deleteNonConversionReasonType,
  addQualifications,
  updateBookingTypeData,
  getBookingType,
  addBookingTypeData,
  getServiceCompanyList,
  getIdentificationNameList,
  deleteBookingTypeData,
  getPhoneNumbersList
});
