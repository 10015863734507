/* eslint-disable no-prototype-builtins */

// Returns a higher order reducer that takes a baseReducer
export const createReducer = (initialState, handlers) => {
  return function baseReducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
};

// sets the redux state to show the async action is underway
export const isLoadingStateReducer = state => ({
  ...state,
  isLoading: true,
  resultData: null,
  errorData: null
});

// sets the redux state to show the async action was successful
export const successStateReducer = (state, { result }) => ({
  ...state,
  isLoading: false,
  resultData: result.result || result,
  errorData: null
});

// sets the redux state to show the async action failed
export const errorStateReducer = (state, { error }) => ({
  ...state,
  isLoading: false,
  resultData: null,
  errorData: error
});

// resets redux state to initial state
export const resetStateReducer = state => ({
  ...state,
  isLoading: false,
  resultData: null,
  errorData: null
});
