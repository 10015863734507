/* eslint-disable max-len */
import { call } from "redux-saga/effects";
import { httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes/bookingSetup";

export function* addContractTypes({ data }) {
  const {
    addContractTypes: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?contractTypeName=${data.contractTypeName}&applyPayParity=${data.applyPayParity}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_CONTRACT_TYPE_SUCCESS,
    errorAction: actions.ADD_CONTRACT_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* updateContractType({ data }) {
  const {
    updateContractType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?contractTypeObjectId=${data.contractTypeObjectId}
    &contractTypeName=${data.contractTypeName}
    &applyPayParity=${data.applyPayParity}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_CONTRACT_TYPE_SUCCESS,
    errorAction: actions.UPDATE_CONTRACT_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* deleteContractType({ data }) {
  const {
    deleteContractType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?contractTypeObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_CONTRACT_TYPE_SUCCESS,
    errorAction: actions.DELETE_CONTRACT_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addQualifications({ data }) {
  const {
    addQualifications: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?qualificationTitle=${data.qualificationTitle}&validity=${data.validity}`,
    apiMethod: method,
    successAction: actions.ADD_QUALIFICATION_TYPE_SUCCESS,
    errorAction: actions.ADD_QUALIFICATION_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateQualification({ data }) {
  const {
    updateQualification: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?qualificationObjectId=${data.qualificationObjectId}
    &qualificationTitle=${data.qualificationTitle}
    &validity=${data.validity}`,
    apiMethod: method,
    successAction: actions.UPDATE_QUALIFICATION_ENTRY_SUCCESS,
    errorAction: actions.UPDATE_QUALIFICATION_ENTRY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteQualification({ data }) {
  const {
    deleteQualification: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?qualificationObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_QUALIFICATION_TYPE_SUCCESS,
    errorAction: actions.DELETE_QUALIFICATION_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getUserList({ data }) {
  const {
    getUserList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?statuses=${data}`,
    apiMethod: method,
    apiPayload: { data },
    successAction: actions.GET_USERLIST_SUCCESS,
    errorAction: actions.GET_USERLIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getContractTypeList() {
  const {
    getContractTypeList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    successAction: actions.GET_CONTRACT_TYPE_LIST_SUCCESS,
    errorAction: actions.GET_CONTRACT_TYPE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getQualificationTitleList() {
  const {
    getQualificationTitleList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_QUALIFICATION_LIST_SUCCESS,
    errorAction: actions.GET_QUALIFICATION_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getUserGroupList() {
  const {
    getUserGroupList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_GROUP_USERS_LIST_SUCCESS,
    errorAction: actions.GET_GROUP_USERS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteUserGroup({ data }) {
  const {
    deleteUserGroup: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?userGroupObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_USER_GROUP_SUCCESS,
    errorAction: actions.DELETE_USER_GROUP_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addNewUserGroup({ data }) {
  const {
    addNewUserGroup: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?userGroupName=${data.userGroupName}`,
    apiMethod: method,
    successAction: actions.ADD_USER_GROUP_SUCCESS,
    errorAction: actions.ADD_USER_GROUP_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getBookingExpenseTypes() {
  const {
    getBookingExpenseTypes: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    successAction: actions.GET_EXPENSE_TYPE_BOOKING_SUCCESS,
    errorAction: actions.GET_EXPENSE_TYPE_BOOKING_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addBookingExpenseType({ data }) {
  const {
    addBookingExpenseType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_NEW_BOOKING_EXPENSE_SUCCESS,
    errorAction: actions.ADD_NEW_BOOKING_EXPENSE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteBookingExpenseType({ data }) {
  const {
    deleteBookingExpenseType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?bookingExpenseTypeObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_BOOKING_EXPENSE_TYPE_SUCCESS,
    errorAction: actions.DELETE_BOOKING_EXPENSE_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getTags() {
  const {
    getTags: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    successAction: actions.GET_TAG_NAME_SUCCESS,
    errorAction: actions.GET_TAG_NAME_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteTag({ data }) {
  const {
    deleteTag: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?tagObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_TAG_NAME_SUCCESS,
    errorAction: actions.DELETE_TAG_NAME_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* createTag({ data }) {
  const {
    createTag: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CREATE_TAG_NAME_SUCCESS,
    errorAction: actions.CREATE_TAG_NAME_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateTag({ data }) {
  const {
    updateTag: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?tagObjectId=${data.tagObjectId}&tagName=${data.tagName}&tagType=${data.tagType}`,
    apiMethod: method,
    successAction: actions.UPDATE_TAG_NAME_SUCCESS,
    errorAction: actions.UPDATE_TAG_NAME_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidateRatingTypeList() {
  const {
    getCandidateRatingTypeList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    successAction: actions.GET_CANDIDATE_RATING_TYPES_SUCCESS,
    errorAction: actions.GET_CANDIDATE_RATING_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteCandidateRatingType({ data }) {
  const {
    deleteCandidateRatingType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?ratingTypeObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_CANDIDATE_RATING_TYPES_SUCCESS,
    errorAction: actions.DELETE_CANDIDATE_RATING_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addCandidateRatingType({ data }) {
  const {
    addCandidateRatingType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_CANDIDATE_RATING_TYPES_SUCCESS,
    errorAction: actions.ADD_CANDIDATE_RATING_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidateRatingType({ data }) {
  const {
    updateCandidateRatingType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_CANDIDATE_RATING_TYPES_SUCCESS,
    errorAction: actions.UPDATE_CANDIDATE_RATING_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateBookingExpenseType({ data }) {
  const {
    updateBookingExpenseType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_EXPENSE_TYPES_SUCCESS,
    errorAction: actions.UPDATE_EXPENSE_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateUserGroup({ data }) {
  const {
    updateUserGroup: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?userGroupObjectId=${data.userGroupObjectId}
    &userGroupName=${data.userGroupName}`,
    apiMethod: method,
    successAction: actions.UPDATE_GROUP_NAME_SUCCESS,
    errorAction: actions.UPDATE_GROUP_NAME_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidateInfringementTypeList() {
  const {
    getCandidateInfringementTypeList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    successAction: actions.GET_INFRINGEMENT_TYPE_SUCCESS,
    errorAction: actions.GET_INFRINGEMENT_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteCandidateInfringementType({ data }) {
  const {
    deleteCandidateInfringementType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_INFRINGEMENT_TYPE_SUCCESS,
    errorAction: actions.DELETE_INFRINGEMENT_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addNewCandidateInfringementType({ data }) {
  const {
    addNewCandidateInfringementType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_INFRINGEMENT_TYPE_SUCCESS,
    errorAction: actions.ADD_INFRINGEMENT_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidateInfringementType({ data }) {
  const {
    updateCandidateInfringementType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_INFRINGEMENT_TYPE_SUCCESS,
    errorAction: actions.UPDATE_INFRINGEMENT_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidatePerformanceTypeList() {
  const {
    getCandidatePerformanceTypeList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    successAction: actions.GET_PERFORMANCE_TYPES_SUCCESS,
    errorAction: actions.GET_PERFORMANCE_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteCandidatePerformanceType({ data }) {
  const {
    deleteCandidatePerformanceType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?candidatePerformanceTypeObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_PERFORMANCE_TYPES_SUCCESS,
    errorAction: actions.DELETE_PERFORMANCE_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addCandidatePerformanceType({ data }) {
  const {
    addCandidatePerformanceType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?candidatePerformanceTypeName=${data.Name}`,
    apiMethod: method,
    successAction: actions.ADD_PERFORMANCE_TYPES_SUCCESS,
    errorAction: actions.ADD_PERFORMANCE_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidatePerformanceType({ data }) {
  const {
    updateCandidatePerformanceType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?candidatePerformanceTypeObjectId=${data.candidatePerformanceTypeObjectId}
    &candidatePerformanceTypeName=${data.candidatePerformanceTypeName}`,
    apiMethod: method,
    successAction: actions.UPDATE_PERFORMANCE_TYPES_SUCCESS,
    errorAction: actions.UPDATE_PERFORMANCE_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getNonConversionReasonList() {
  const {
    getNonConversionReasonList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    successAction: actions.GET_NON_CONVERSION_LIST_SUCCESS,
    errorAction: actions.GET_NON_CONVERSION_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getInsuranceCategoryList() {
  const {
    getInsuranceCategoryList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    successAction: actions.GET_INSURANCE_CATEGORY_LIST_SUCCESS,
    errorAction: actions.GET_INSURANCE_CATEGORY_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteInsuranceCategoryType({ data }) {
  const {
    deleteInsuranceCategoryType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?insuranceCategoryTypeObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_INSURANCE_CATEGORY_SUCCESS,
    errorAction: actions.DELETE_INSURANCE_CATEGORY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addInsuranceCategoryType({ data }) {
  const {
    addInsuranceCategoryType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?insuranceCategoryTypeName=${data.Name}`,
    apiMethod: method,
    successAction: actions.ADD_INSURANCE_CATEGORY_SUCCESS,
    errorAction: actions.ADD_INSURANCE_CATEGORY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateInsuranceCategoryType({ data }) {
  const {
    updateInsuranceCategoryType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?insuranceCategoryTypeObjectId=${data.insuranceCategoryTypeObjectId}
    &insuranceCategoryTypeName=${data.insuranceCategoryTypeName}`,
    apiMethod: method,
    successAction: actions.UPDATE_INSURANCE_CATEGORY_SUCCESS,
    errorAction: actions.UPDATE_INSURANCE_CATEGORY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addNonConversionReasonType({ data }) {
  const {
    addNonConversionReasonType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?nonConversionReasonName=${data.nonConversionReasonName}`,
    apiMethod: method,
    successAction: actions.ADD_NON_CONVERSION_TYPE_SUCCESS,
    errorAction: actions.ADD_NON_CONVERSION_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateNonConversionReasonType({ data }) {
  const {
    updateNonConversionReasonType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_NON_CONVERSION_TYPE_SUCCESS,
    errorAction: actions.UPDATE_NON_CONVERSION_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteNonConversionReasonType({ data }) {
  const {
    deleteNonConversionReasonType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?nonConversionReasonObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_NON_CONVERSION_TYPE_SUCCESS,
    errorAction: actions.DELETE_NON_CONVERSION_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getLeadSourceList() {
  const {
    getLeadSourceList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_LEAD_SOURCE_TYPES_SUCCESS,
    errorAction: actions.GET_LEAD_SOURCE_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addLeadSourceType({ data }) {
  const {
    addLeadSourceType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?leadSourceName=${data.Name}`,
    apiMethod: method,
    successAction: actions.ADD_LEAD_SOURCE_TYPES_SUCCESS,
    errorAction: actions.ADD_LEAD_SOURCE_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateLeadSourceType({ data }) {
  const {
    updateLeadSourceType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?leadSourceName=${data.Name}&leadSourceObjectId=${data.ObjectId}`,
    apiMethod: method,
    successAction: actions.UPDATE_LEAD_SOURCE_TYPE_SUCCESS,
    errorAction: actions.UPDATE_LEAD_SOURCE_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteLeadSourceType({ data }) {
  const {
    deleteLeadSourceType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?leadSourceObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_LEAD_SOURCE_TYPES_SUCCESS,
    errorAction: actions.DELETE_LEAD_SOURCE_TYPES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getReasonForLeavingList() {
  const {
    getReasonForLeavingList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_LEAVING_REASON_TYPE_SUCCESS,
    errorAction: actions.GET_LEAVING_REASON_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addLeavingReasonType({ data }) {
  const {
    addLeavingReasonType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?leavingReasonTypeName=${data.Name}`,
    apiMethod: method,
    successAction: actions.ADD_LEAVING_REASON_TYPE_SUCCESS,
    errorAction: actions.ADD_LEAVING_REASON_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateLeavingReasonType({ data }) {
  const {
    updateLeavingReasonType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?leavingReasonTypeObjectId=${data.ObjectId}
    &leavingReasonTypeName=${data.Name}`,
    apiMethod: method,
    successAction: actions.UPDATE_LEAVING_REASON_TYPE_SUCCESS,
    errorAction: actions.UPDATE_LEAVING_REASON_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteLeavingReasonType({ data }) {
  const {
    deleteLeavingReasonType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?leavingReasonTypeObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_LEAVING_REASON_TYPE_SUCCESS,
    errorAction: actions.DELETE_LEAVING_REASON_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteBankHoliday({ data }) {
  const {
    deleteBankHoliday: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?bankHolidayObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_BANK_HOLIDAY_SUCCESS,
    errorAction: actions.DELETE_BANK_HOLIDAY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateBankHoliday({ data }) {
  const {
    updateBankHoliday: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?bankHolidayObjectId=${data.bankHolidayObjectId}
    &bankHolidayDate=${data.bankHolidayDate}`,
    apiMethod: method,
    successAction: actions.UPDATE_BANK_HOLIDAY_SUCCESS,
    errorAction: actions.UPDATE_BANK_HOLIDAY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addBankHoliday({ data }) {
  const {
    addBankHoliday: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?bankHolidayDate=${data.bankHolidayDate}`,
    apiMethod: method,
    successAction: actions.ADD_BANK_HOLIDAY_SUCCESS,
    errorAction: actions.ADD_BANK_HOLIDAY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getBankHolidayList() {
  const {
    getBankHolidayList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_BANK_HOLIDAY_SUCCESS,
    errorAction: actions.GET_BANK_HOLIDAY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getBookingType() {
  const {
    getBookingType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_BOOKING_TYPE_SUCCESS,
    errorAction: actions.GET_BOOKING_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addBookingType({ data }) {
  const {
    addBookingType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?requestObject=${data}`,
    apiMethod: method,
    successAction: actions.ADD_BOOKING_TYPE_SUCCESS,
    errorAction: actions.ADD_BOOKING_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateBookingType({ data }) {
  const {
    updateBookingType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?requestObject=${data}`,
    apiMethod: method,
    successAction: actions.UPDATE_BOOKING_TYPE_SUCCESS,
    errorAction: actions.UPDATE_BOOKING_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getServiceCompanyList() {
  const {
    getServiceCompanyList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_SERVICE_COMPANIES_SUCCESS,
    errorAction: actions.GET_SERVICE_COMPANIES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getIdentificationNameList() {
  const {
    getIdentificationNameList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_IDENTIFICATION_NAME_LIST_SUCCESS,
    errorAction: actions.GET_IDENTIFICATION_NAME_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteBookingType({ data }) {
  const {
    deleteBookingType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}bookingTypeObjectId=${data.bookingTypeObjectID}&candidateTypeObjectId=${data.candidateTypeObjectID}`,
    apiMethod: method,
    successAction: actions.DELETE_BOOKING_TYPE_SUCCESS,
    errorAction: actions.DELETE_BOOKING_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addNewIdentification({ data }) {
  const {
    addNewIdentification: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?identificationName=${data.Name}&validity=${data.Validity}`,
    apiMethod: method,
    successAction: actions.ADD_IDENTIFICATION_NAME_LIST_SUCCESS,
    errorAction: actions.ADD_IDENTIFICATION_NAME_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateIdentification({ data }) {
  const {
    updateIdentification: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?objectId=${data.objectId}
    &identificationName=${data.Name}&validity=${data.Validity}`,
    apiMethod: method,
    successAction: actions.UPDATE_IDENTIFICATION_TYPE_SUCCESS,
    errorAction: actions.UPDATE_IDENTIFICATION_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteIdentification({ data }) {
  const {
    deleteIdentification: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}objectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_IDENTIFICATION_TYPE_SUCCESS,
    errorAction: actions.DELETE_IDENTIFICATION_TYPE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getPhoneNumbersList({ data }) {
  const {
    getPhoneNumbersList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint + data,
    apiMethod: method,
    successAction: actions.GET_PHONE_NUMBER_LIST_SUCCESS,
    errorAction: actions.GET_PHONE_NUMBER_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addClientDocumentType({ data }) {
  const {
    addClientDocumentType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_CLIENT_DOCUMENT_SUCCESS,
    errorAction: actions.ADD_CLIENT_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateClientDocumentType({ data }) {
  const {
    updateClientDocumentType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_CLIENT_DOCUMENT_SUCCESS,
    errorAction: actions.UPDATE_CLIENT_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteClientDocumentType({ data }) {
  const {
    deleteClientDocumentType: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?clientDocumentTypeObjectId=${data}`,
    apiMethod: method,
    successAction: actions.DELETE_CLIENT_DOCUMENT_SUCCESS,
    errorAction: actions.DELETE_CLIENT_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getClientDocumentTypeList() {
  const {
    getClientDocumentTypeList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_CLIENT_DOCUMENT_LIST_SUCCESS,
    errorAction: actions.GET_CLIENT_DOCUMENT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addServiceCompany({ payload }) {
  const {
    addServiceCompany: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?serviceCompanyName=${payload.serviceCompanyName}&serviceCompanyNumber=${payload.serviceCompanyNumber}`,
    apiMethod: method,
    successAction: actions.ADD_SERVICE_COMPANY_SUCCESS,
    errorAction: actions.ADD_SERVICE_COMPANY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateServiceCompany({ payload }) {
  const {
    updateServiceCompany: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}?serviceCompanyName=${payload.Name}&serviceCompanyNumber=${payload.serviceCompanyNumber}&serviceCompanyObjectId=${payload.ObjectId}`,
    apiMethod: method,
    successAction: actions.UPDATE_SERVICE_COMPANY_SUCCESS,
    errorAction: actions.UPDATE_SERVICE_COMPANY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteServiceCompany({ payload }) {
  const {
    deleteServiceCompany: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;

  const requestParameters = {
    url: `${endPoint}?serviceCompanyObjectId=${payload}`,
    apiMethod: method,
    successAction: actions.DELETE_SERVICE_COMPANY_SUCCESS,
    errorAction: actions.DELETE_SERVICE_COMPANY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getStartDay() {
  const {
    getStartDay: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    successAction: actions.GET_START_DAY_SUCCESS,
    errorAction: actions.GET_START_DAY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendPayDocuments({ data }) {
  const {
    sendPayDocuments: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SEND_PAY_EMAIL_SUCCESS,
    errorAction: actions.SEND_PAY_EMAIL_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllSiteList() {
  const {
    getAllSiteList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_ALL_SITES_SUCCESS,
    errorAction: actions.GET_ALL_SITES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllSiteListPaged({ data }) {
  const {
    getAllSiteListPaged: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_ALL_SITES_PAGED_SUCCESS,
    errorAction: actions.GET_ALL_SITES_PAGED_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllReasonCodes({ data }) {
  const {
    getAllReasonCodes: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_ALL_REASON_CODES_SUCCESS,
    errorAction: actions.GET_ALL_REASON_CODES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllMobileAppLinks({ data }) {
  const {
    getAllMobileAppLinks: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_ALL_MOBILE_APP_LINKS_SUCCESS,
    errorAction: actions.GET_ALL_MOBILE_APP_LINKS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteReasonCode({ data }) {
  const {
    deleteReasonCode: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_REASON_CODE_SUCCESS,
    errorAction: actions.DELETE_REASON_CODE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addReasonCode({ data }) {
  const {
    addReasonCode: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_REASON_CODE_SUCCESS,
    errorAction: actions.ADD_REASON_CODE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateReasonCode({ data }) {
  const {
    updateReasonCode: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_REASON_CODE_SUCCESS,
    errorAction: actions.UPDATE_REASON_CODE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addMobileAppLink({ data }) {
  const {
    addMobileAppLink: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_MOBILE_APP_LINK_SUCCESS,
    errorAction: actions.ADD_MOBILE_APP_LINK_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateMobileAppLink({ data }) {
  const {
    updateMobileAppLink: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_MOBILE_APP_LINK_SUCCESS,
    errorAction: actions.UPDATE_MOBILE_APP_LINK_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteMobileAppLink({ data }) {
  const {
    deleteMobileAppLink: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_MOBILE_APP_LINK_SUCCESS,
    errorAction: actions.DELETE_MOBILE_APP_LINK_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
