import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes";
import {
  getUserList,
  addNewUser,
  deleteUser,
  changePassword,
  editUser,
  getObjectPermissions,
  getUserListPaged,
  massResetPW
} from "../workers/userWorker";

export function* watchGetObjectPermissions() {
  yield takeLatest(actions.GET_USER_SITES, getObjectPermissions);
}

export function* getUserListdetails() {
  yield takeLatest(actions.USER_LIST, getUserList);
}

export function* watchGetUserListDetailsPaged() {
  yield takeLatest(actions.USER_LIST_PAGED, getUserListPaged);
}

export function* watchUserMassResetPW() {
  yield takeLatest(actions.MASS_RESET_PW, massResetPW);
}

export function* watchAddNewUserInfo() {
  yield takeLatest(actions.ADD_NEW_USER, addNewUser);
}

export function* watchDeleteUserInfo() {
  yield takeLatest(actions.DELETE_USER, deleteUser);
}

export function* watchChangePasswordInfo() {
  yield takeLatest(actions.CHANGE_PASSWORD, changePassword);
}

export function* watchEditUserInfo() {
  yield takeLatest(actions.EDIT_USER, editUser);
}
