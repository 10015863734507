import action from "../actionTypes";

const initialState = { visible: false };

const authReducer = (state = initialState, { type }) => {
  switch (type) {
    case action.MENU_CLICK:
      return { visible: !state.visible };
    default:
      return { ...state };
  }
};

export default authReducer;
