import { call } from "redux-saga/effects";
import { httpRequestForData } from "../api/api";
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes/calendar";

export function* getDailyBookingList({ data }) {
  const {
    getDailyBookingList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DAILY_BOOKING_LIST_SUCCESS,
    errorAction: actions.DAILY_BOOKING_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getMonthlyBookingList({ data }) {
  const {
    getMonthlyBookingList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.MONTHLY_BOOKING_LIST_SUCCESS,
    errorAction: actions.MONTHLY_BOOKING_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getWeeklyBookingList({ data }) {
  const {
    getWeeklyBookingList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.WEEKLY_BOOKING_LIST_SUCCESS,
    errorAction: actions.WEEKLY_BOOKING_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getMonthlyBookingsOverview({ data }) {
  const {
    getMonthlyBookingsOverview: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.MONTHLY_BOOKING_LIST_OVERVIEW_SUCCESS,
    errorAction: actions.MONTHLY_BOOKING_LIST_OVERVIEW_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateNightOut({ payload }) {
  const {
    updateNightOut: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?bookingObjectId=${payload?.bookingObjectId}&overnight=${payload?.overnight}`,
    apiMethod: method,
    successAction: actions.NIGHT_OUT_UPDATE_SUCCESS,
    errorAction: actions.NIGHT_OUT_UPDATE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* holdFromAppSingle({ data }) {
  const {
    holdFromApp: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.HOLD_FROM_APP_SINGLE_SUCCESS,
    errorAction: actions.HOLD_FROM_APP_SINGLE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* holdFromAppMultiple({ data }) {
  const {
    holdFromApp: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.HOLD_FROM_APP_MULTIPLE_SUCCESS,
    errorAction: actions.HOLD_FROM_APP_MULTIPLE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
