import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

import { Row, Col, Form, Input, Button, Spin } from "antd";
import AlertMessage from "../../components/authBg/alert";
import { UsernameOrEmailValidation } from "../../constants/promptRules";
import URL from "../../config/envUrls";
import _labels from "../../constants/labels";
import _constants from "../../constants/constants";
import _messages from "../../constants/messages";

import LoaderIcon from "../../components/loader";
import AuthLogo from "../../assets/images/png/drsNewPlain.png";
import "./reset-password.scss";

const {
  LOGIN: { COMPLETE_RESET_PASSWORD_END_POINT }
} = _labels;

const { REQUEST_RESET_PASSWORD_ERROR } = _messages;

const LoginHeader = () => (
  <div className="auth-logo-wrapper">
    <div className="auth-logo-img">
      <img src={AuthLogo} alt="auth-logo-img" />
    </div>
    <h2 className="login-header-text">Complete Reset Password</h2>
  </div>
);

const CompletePasswordReset = ({ history }) => {
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState(null);

  console.log(history.location.search.split("=")[1]);

  const onFormSubmit = values => {
    setLoading(true);

    const passwordResetKey = history.location.search.split("=")[1];
    const { newPassword } = values;
    // const passwordResetKey = "https://dev-api.drsplatform.net/completepwreset?id=";
    axios
      .post(`${URL.API.baseURL}${COMPLETE_RESET_PASSWORD_END_POINT}`, {
        newPassword,
        passwordResetKey
      })
      .then(responseData => {
        console.log(responseData);
        if (responseData.status === 200) {
          if (responseData.data.Response === "Success") {
            setAlertType("success");
            setMessage(responseData.data.Message);
          }
          if (responseData.data.Response === "Failure") {
            setAlertType("error");
            setMessage(responseData.data.ErrorMessage);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setMessage(REQUEST_RESET_PASSWORD_ERROR);
      });
  };

  return (
    <div className="reset-password-bg">
      <Spin
        indicator={<LoaderIcon />}
        spinning={loading}
        className="loaderGifPosition reset-password-wrapper"
      >
        <Row className="login-wrapper">
          <Col>
            <LoginHeader />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={11} className="login-auth-area">
            <Col className="login-auth-wrapper">
              <AlertMessage type={alertType} msg={message} />
              <Form
                name="drs-complete-reset-pw"
                className="complete-reset-pw-form"
                autoComplete="off"
                ref={formRef}
                onFinish={onFormSubmit}
              >
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="New Password"
                  name="newPassword"
                  fieldKey="newPassword"
                  className="emailInput"
                  rules={[
                    { min: 6, message: "Password must be minimum 6 characters." },
                    {
                      required: true,
                      message: "Please input your password!"
                    }
                  ]}
                >
                  <Input placeholder="Password" type="password" />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 24 }}
                  className="emailInput"
                  label="Confirm Password"
                  name="confirmPassword"
                  fieldKey="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!"
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error("Passwords do not match"));
                      }
                    })
                  ]}
                >
                  <Input placeholder="Password" type="password" />
                </Form.Item>

                <Row justify="center" align="middle">
                  <Col span={3}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      Submit
                    </Button>
                  </Col>
                  <Col span={2}>
                    <Link type="primary" to="/login" className="login-form-button">
                      Home
                    </Link>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default React.memo(withRouter(CompletePasswordReset));
