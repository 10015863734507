import Cookies from "universal-cookie";

const cookies = new Cookies();

const date = `=;expires=${new Date().toUTCString()};path=/`;

const Auth = {
  set(name, value, options = { path: "/" }) {
    return cookies.set(name, value, options);
  },

  get(value) {
    const token = cookies.get(value);
    return token;
  },

  remove(value) {
    return cookies.remove(value);
  },

  getSessionKey() {
    const token = cookies.get("sessionKey");
    return token;
  },

  setCandidateDetail(obj) {
    return cookies.set("candidateCalendarState", obj);
  },

  getCandidateDetail() {
    return cookies.get("candidateCalendarState");
  },

  removeCandidateDetail() {
    return cookies.remove("candidateCalendarState", { path: "/" });
  },

  getSessionDetails() {
    const details = cookies.get("sessionDetails");
    return details;
  },

  isClientUser() {
    const userObject = cookies.get("sessionDetails") || "";
    const { currentUserRole = "" } = userObject || "";
    return currentUserRole.toLowerCase().includes("client");
  },

  isClientTimeSheet() {
    const userObject = cookies.get("sessionDetails") || "";
    const { currentUserRole = "", userRoles = [] } = userObject || "";
    return userRoles.some(({ RoleName }) => RoleName.toLowerCase().includes("clienttimesheet"));
  },

  isClientFinance() {
    const userObject = cookies.get("sessionDetails") || "";
    const { currentUserRole = "" } = userObject || "";
    return currentUserRole.toLowerCase().includes("clientfinance");
  },

  isAgencyUser() {
    const userObject = cookies.get("sessionDetails") || "";
    const { currentUserRole } = userObject || "";
    return currentUserRole === "Planning";
  },

  isAgencyAdmin() {
    const userObject = cookies.get("sessionDetails") || "";
    const { currentUserRole } = userObject || "";
    return currentUserRole === "Admin";
  },

  isSystemAdmin() {
    const userObject = cookies.get("sessionDetails") || "";
    const { currentUserRole } = userObject || "";
    return currentUserRole === "System Admin";
  },

  isFinanceAdmin() {
    const userObject = cookies.get("sessionDetails") || "";
    const { currentUserRole } = userObject || "";
    return currentUserRole === "Finance";
  },

  setSessionKey(sessionKey) {
    this.set("sessionKey", sessionKey, {
      path: "/",
      expires: 0
    });
  },

  storeSessionDetails(obj) {
    this.set("sessionDetails", obj);
  },

  isAuthenticated() {
    const sessionKey = cookies.get("sessionKey");
    const isSessionValid = sessionKey !== null && sessionKey !== "" && sessionKey !== undefined;
    return isSessionValid;
  },

  removeSessionKey() {
    deleteAllCookies();
    cookies.remove("sessionKey", { path: "/" });
  }
};

const deleteAllCookies = () => {
  document.cookie.split(";").forEach(ck => {
    document.cookie = ck?.replace(/^ +/, "")?.replace(/=.*/, date);
  });
};

export default Auth;
