import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes";
import { getReportList, getReportInfo } from "../workers/reportWorker";

export function* watchGetReportList() {
  yield takeLatest(actions.LIST_REPORTS, getReportList);
}

export function* watchGetReportInfo() {
  yield takeLatest(actions.GET_REPORTS_INFO, getReportInfo);
}
