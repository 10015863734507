/* eslint-disable no-unused-vars */
import { take, takeLatest } from "redux-saga/effects";
import actions from "../actionTypes/bookingSetup";
import {
  addContractTypes,
  updateContractType,
  deleteContractType,
  addQualifications,
  updateQualification,
  deleteQualification,
  getUserList,
  getContractTypeList,
  getQualificationTitleList,
  getUserGroupList,
  deleteUserGroup,
  addNewUserGroup,
  getBookingExpenseTypes,
  addBookingExpenseType,
  deleteBookingExpenseType,
  getTags,
  deleteTag,
  createTag,
  updateTag,
  getCandidateRatingTypeList,
  deleteCandidateRatingType,
  updateCandidateRatingType,
  addCandidateRatingType,
  updateBookingExpenseType,
  updateUserGroup,
  getCandidateInfringementTypeList,
  deleteCandidateInfringementType,
  addNewCandidateInfringementType,
  updateCandidateInfringementType,
  getCandidatePerformanceTypeList,
  deleteCandidatePerformanceType,
  addCandidatePerformanceType,
  updateCandidatePerformanceType,
  getNonConversionReasonList,
  deleteInsuranceCategoryType,
  getInsuranceCategoryList,
  addInsuranceCategoryType,
  updateInsuranceCategoryType,
  addNonConversionReasonType,
  deleteNonConversionReasonType,
  getLeadSourceList,
  addLeadSourceType,
  updateLeadSourceType,
  deleteLeadSourceType,
  getReasonForLeavingList,
  addLeavingReasonType,
  updateLeavingReasonType,
  deleteLeavingReasonType,
  deleteBankHoliday,
  updateBankHoliday,
  getBankHolidayList,
  getServiceCompanyList,
  getIdentificationNameList,
  addBankHoliday,
  getBookingType,
  addBookingType,
  updateBookingType,
  deleteBookingType,
  addNewIdentification,
  updateIdentification,
  deleteIdentification,
  updateNonConversionReasonType,
  getPhoneNumbersList,
  getClientDocumentTypeList,
  addClientDocumentType,
  updateClientDocumentType,
  deleteClientDocumentType,
  addServiceCompany,
  updateServiceCompany,
  deleteServiceCompany,
  getStartDay,
  sendPayDocuments,
  getAllSiteList,
  getAllSiteListPaged,
  getAllMobileAppLinks,
  addMobileAppLink,
  updateMobileAppLink,
  deleteMobileAppLink,
  getAllReasonCodes,
  addReasonCode,
  updateReasonCode,
  deleteReasonCode
} from "../workers/bookingSetupWorker";

export function* watchAddContractType() {
  yield takeLatest(actions.ADD_CONTRACT_TYPE, addContractTypes);
}
export function* watchUpdateContractType() {
  yield takeLatest(actions.UPDATE_CONTRACT_TYPE, updateContractType);
}
export function* watchDeleteContractType() {
  yield takeLatest(actions.DELETE_CONTRACT_TYPE, deleteContractType);
}
// QUALIFICATIONS
export function* watchGetQualificationTitleList() {
  yield takeLatest(actions.GET_QUALIFICATION_LIST, getQualificationTitleList);
}
export function* watchAddQualification() {
  yield takeLatest(actions.ADD_QUALIFICATION_TYPE, addQualifications);
}

export function* watchUpdateQualification() {
  yield takeLatest(actions.UPDATE_QUALIFICATION_ENTRY, updateQualification);
}

export function* watchGetUserList() {
  yield takeLatest(actions.GET_USERLIST, getUserList);
}

export function* watchGetContractTypeList() {
  yield takeLatest(actions.GET_CONTRACT_TYPE_LIST, getContractTypeList);
}

export function* watchDeleteQualification() {
  yield takeLatest(actions.DELETE_QUALIFICATION_TYPE, deleteQualification);
}

export function* watchGetUserGroupList() {
  yield takeLatest(actions.GET_GROUP_USERS_LIST, getUserGroupList);
}

export function* watchDeleteUserGroup() {
  yield takeLatest(actions.DELETE_USER_GROUP, deleteUserGroup);
}

export function* watchAddUserGroup() {
  yield takeLatest(actions.ADD_USER_GROUP, addNewUserGroup);
}

export function* watchGetBookingExpenseTypes() {
  yield takeLatest(actions.GET_EXPENSE_TYPE_BOOKING, getBookingExpenseTypes);
}

export function* watchAddBookingExpenseType() {
  yield takeLatest(actions.ADD_NEW_BOOKING_EXPENSE, addBookingExpenseType);
}

export function* watchDeleteExpenseType() {
  yield takeLatest(actions.DELETE_BOOKING_EXPENSE_TYPE, deleteBookingExpenseType);
}

export function* watchGetTags() {
  yield takeLatest(actions.GET_TAG_NAME, getTags);
}

export function* watchDeleteTag() {
  yield takeLatest(actions.DELETE_TAG_NAME, deleteTag);
}

export function* watchCreateTag() {
  yield takeLatest(actions.CREATE_TAG_NAME, createTag);
}

export function* watchUpdateTag() {
  yield takeLatest(actions.UPDATE_TAG_NAME, updateTag);
}

export function* watchGetCandidateRatingTypeList() {
  yield takeLatest(actions.GET_CANDIDATE_RATING_TYPES, getCandidateRatingTypeList);
}

export function* watchDeleteCandidateRatingType() {
  yield takeLatest(actions.DELETE_CANDIDATE_RATING_TYPES, deleteCandidateRatingType);
}

export function* watchAddCandidateRatingType() {
  yield takeLatest(actions.ADD_CANDIDATE_RATING_TYPES, addCandidateRatingType);
}

export function* watchUpdateCandidateRatingType() {
  yield takeLatest(actions.UPDATE_CANDIDATE_RATING_TYPES, updateCandidateRatingType);
}

export function* watchUpdateBookingExpenseType() {
  yield takeLatest(actions.UPDATE_EXPENSE_TYPES, updateBookingExpenseType);
}

export function* watchUpdateUserGroup() {
  yield takeLatest(actions.UPDATE_GROUP_NAME, updateUserGroup);
}

export function* watchGetCandidateInfringementTypeList() {
  yield takeLatest(actions.GET_INFRINGEMENT_TYPE, getCandidateInfringementTypeList);
}

export function* watchDeleteCandidateInfringementType() {
  yield takeLatest(actions.DELETE_INFRINGEMENT_TYPE, deleteCandidateInfringementType);
}

export function* watchAddNewCandidateInfringementType() {
  yield takeLatest(actions.ADD_INFRINGEMENT_TYPE, addNewCandidateInfringementType);
}

export function* watchUpdateCandidateInfringementType() {
  yield takeLatest(actions.UPDATE_INFRINGEMENT_TYPE, updateCandidateInfringementType);
}

export function* watchGetCandidatePerformanceTypeList() {
  yield takeLatest(actions.GET_PERFORMANCE_TYPES, getCandidatePerformanceTypeList);
}

export function* watchDeleteCandidatePerformanceType() {
  yield takeLatest(actions.DELETE_PERFORMANCE_TYPES, deleteCandidatePerformanceType);
}

export function* watchAddCandidatePerformanceType() {
  yield takeLatest(actions.ADD_PERFORMANCE_TYPES, addCandidatePerformanceType);
}

export function* watchUpdateCandidatePerformanceType() {
  yield takeLatest(actions.UPDATE_PERFORMANCE_TYPES, updateCandidatePerformanceType);
}

export function* watchGetNonConversionReasonList() {
  yield takeLatest(actions.GET_NON_CONVERSION_LIST, getNonConversionReasonList);
}

export function* watchGetInsuranceCategoryList() {
  yield takeLatest(actions.GET_INSURANCE_CATEGORY_LIST, getInsuranceCategoryList);
}

export function* watchDeleteInsuranceCategoryType() {
  yield takeLatest(actions.DELETE_INSURANCE_CATEGORY, deleteInsuranceCategoryType);
}

export function* watchAddInsuranceCategoryType() {
  yield takeLatest(actions.ADD_INSURANCE_CATEGORY, addInsuranceCategoryType);
}

export function* watchUpdateInsuranceCategoryType() {
  yield takeLatest(actions.UPDATE_INSURANCE_CATEGORY, updateInsuranceCategoryType);
}

export function* watchAddNonConversionReasonType() {
  yield takeLatest(actions.ADD_NON_CONVERSION_TYPE, addNonConversionReasonType);
}

export function* watchUpdateNonConversionReasonType() {
  yield takeLatest(actions.UPDATE_NON_CONVERSION_TYPE, updateNonConversionReasonType);
}

export function* watchDeleteNonConversionReasonType() {
  yield takeLatest(actions.DELETE_NON_CONVERSION_TYPE, deleteNonConversionReasonType);
}

export function* watchGetLeadSourceList() {
  yield takeLatest(actions.GET_LEAD_SOURCE_TYPES, getLeadSourceList);
}

export function* watchAddLeadSourceType() {
  yield takeLatest(actions.ADD_LEAD_SOURCE_TYPES, addLeadSourceType);
}

export function* watchUpdateLeadSourceType() {
  yield takeLatest(actions.UPDATE_LEAD_SOURCE_TYPE, updateLeadSourceType);
}

export function* watchDeleteLeadSourceType() {
  yield takeLatest(actions.DELETE_LEAD_SOURCE_TYPES, deleteLeadSourceType);
}

export function* watchGetReasonForLeavingList() {
  yield takeLatest(actions.GET_LEAVING_REASON_TYPE, getReasonForLeavingList);
}

export function* watchAddLeavingReasonType() {
  yield takeLatest(actions.ADD_LEAVING_REASON_TYPE, addLeavingReasonType);
}

export function* watchUpdateLeavingReasonType() {
  yield takeLatest(actions.UPDATE_LEAVING_REASON_TYPE, updateLeavingReasonType);
}

export function* watchDeleteLeavingReasonType() {
  yield takeLatest(actions.DELETE_LEAVING_REASON_TYPE, deleteLeavingReasonType);
}

export function* watchAddBankHoliday() {
  yield takeLatest(actions.ADD_BANK_HOLIDAY, addBankHoliday);
}

export function* watchUpdateBankHoliday() {
  yield takeLatest(actions.UPDATE_BANK_HOLIDAY, updateBankHoliday);
}

export function* watchDeleteBankHoliday() {
  yield takeLatest(actions.DELETE_BANK_HOLIDAY, deleteBankHoliday);
}

export function* watchGetBankHolidayList() {
  yield takeLatest(actions.GET_BANK_HOLIDAY, getBankHolidayList);
}

export function* watchGetServiceCompanyList() {
  yield takeLatest(actions.GET_SERVICE_COMPANIES, getServiceCompanyList);
}

export function* watchGetIdentificationNameList() {
  yield takeLatest(actions.GET_IDENTIFICATION_NAME_LIST, getIdentificationNameList);
}

export function* watchGetBookingType() {
  yield takeLatest(actions.GET_BOOKING_TYPE, getBookingType);
}
export function* watchAddBookingType() {
  yield takeLatest(actions.ADD_BOOKING_TYPE, addBookingType);
}
export function* watchUpdateBookingType() {
  yield takeLatest(actions.UPDATE_BOOKING_TYPE, updateBookingType);
}
export function* watchDeleteBookingType() {
  yield takeLatest(actions.DELETE_BOOKING_TYPE, deleteBookingType);
}
export function* watchAddNewIdentification() {
  yield takeLatest(actions.ADD_IDENTIFICATION_NAME_LIST, addNewIdentification);
}

export function* watchUpdateIdentification() {
  yield takeLatest(actions.UPDATE_IDENTIFICATION_TYPE, updateIdentification);
}

export function* watchDeleteIdentification() {
  yield takeLatest(actions.DELETE_IDENTIFICATION_TYPE, deleteIdentification);
}

export function* watchGetPhoneNumbersList() {
  yield takeLatest(actions.GET_PHONE_NUMBER_LIST, getPhoneNumbersList);
}

export function* watchGetClientDocumentTypeList() {
  yield takeLatest(actions.GET_CLIENT_DOCUMENT_LIST, getClientDocumentTypeList);
}

export function* watchAddClientDocumentType() {
  yield takeLatest(actions.ADD_CLIENT_DOCUMENT, addClientDocumentType);
}

export function* watchUpdateClientDocumentType() {
  yield takeLatest(actions.UPDATE_CLIENT_DOCUMENT, updateClientDocumentType);
}

export function* watchDeleteClientDocumentType() {
  yield takeLatest(actions.DELETE_CLIENT_DOCUMENT, deleteClientDocumentType);
}

export function* watchAddServiceCompany() {
  yield takeLatest(actions.ADD_SERVICE_COMPANY, addServiceCompany);
}

export function* watchUpdateServiceCompany() {
  yield takeLatest(actions.UPDATE_SERVICE_COMPANY, updateServiceCompany);
}

export function* watchDeleteServiceCompany() {
  yield takeLatest(actions.DELETE_SERVICE_COMPANY, deleteServiceCompany);
}

export function* watchGetStartDay() {
  yield takeLatest(actions.GET_START_DAY, getStartDay);
}

export function* watchSendPayDocuments() {
  yield takeLatest(actions.SEND_PAY_EMAIL, sendPayDocuments);
}

export function* watchGetAllSiteList() {
  yield takeLatest(actions.GET_ALL_SITES, getAllSiteList);
}

export function* watchGetAllSiteListPaged() {
  yield takeLatest(actions.GET_ALL_SITES_PAGED, getAllSiteListPaged);
}

export function* watchGetAllMobileAppLinks() {
  yield takeLatest(actions.GET_ALL_MOBILE_APP_LINKS, getAllMobileAppLinks);
}

export function* watchGetAllReasonCodes() {
  yield takeLatest(actions.GET_ALL_REASON_CODES, getAllReasonCodes);
}

export function* watchAddReasonCode() {
  yield takeLatest(actions.ADD_REASON_CODE, addReasonCode);
}

export function* watchUpdateReasonCode() {
  yield takeLatest(actions.UPDATE_REASON_CODE, updateReasonCode);
}

export function* watchAddMobileAppLink() {
  yield takeLatest(actions.ADD_MOBILE_APP_LINK, addMobileAppLink);
}

export function* watchUpdateMobileAppLink() {
  yield takeLatest(actions.UPDATE_MOBILE_APP_LINK, updateMobileAppLink);
}

export function* watchDeleteMobileAppLink() {
  yield takeLatest(actions.DELETE_MOBILE_APP_LINK, deleteMobileAppLink);
}

export function* watchDeleteReasonCode() {
  yield takeLatest(actions.DELETE_REASON_CODE, deleteReasonCode);
}
