import React, { Component } from "react";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <h1>Forgot Password</h1>;
  }
}

export default ForgotPassword;
